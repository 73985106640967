// import React, { useState } from 'react';
// import cross from '../Assets/images2/radix-icons_cross-2.svg';
// import tokensData from './ChainAddresses.json';

// const SelectToken = ({ onSelectToken }) => {
//     const [searchQuery, setSearchQuery] = useState('');
//     const tokens43113 = tokensData[0]['13113'].tokens;
//     const [selected, setSelected] = useState('');

//     const filteredTokens = tokens43113.filter(
//         (token) =>
//             token.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
//             token.ticker.toLowerCase().includes(searchQuery.toLowerCase())
//     );

//     const handleTokenSelect = (token) => {
//         setSelected(onSelectToken(token.img, token.ticker));
//         alert(token.ticker);

//     };
//     console.log(selected);
//     return (
//         <>
//             <div className='gnwYND bFCsHr'>
//                 <div className='hfXcaM kMkmMS'>
//                     <div className='cPCYrp'>
//                         <div style={{ fontSize: '20px', color: '#b1b1b1' }}>Select a token</div>
//                         <img src={cross} style={{ height: '24px', width: '24px' }}  alt='' />
//                     </div>
//                     <div className='kdYshZ dKubqp'>
//                         <div className=' hTynGq' style={{ display: 'flex', justifyContent: 'space-between' }}>
//                             <img
//                                 src='/images/search.png'
//                                 className='ms-3'
//                                 style={{ height: '20px', width: '20px' }}
//                                 alt=''
//                             />
//                             <input
//                                 type='text'
//                                 id='token-search-input'
//                                 className='w-100 hTynGqr'
//                                 placeholder='Search name or paste address'
//                                 onChange={(e) => setSearchQuery(e.target.value)}
//                             />
//                         </div>
//                         <button className='sc-aXZVg bbWEFp lmdAXV kjpUwf'>
//                             <img src='./images/sprint.png' height='23px' width='23px' alt='' />
//                             <img src='/images/dropdown.png' height='23px' width='23px' alt='' />
//                         </button>
//                     </div>
//                     <div className='fxVDAj kdYshZ dKubqp'>
//                         <div className='crYogb'>
//                             <img src='./images/sprint.png' height="23px" width="23px" alt="" />
//                             <span>ETH</span>
//                         </div>
//                         <div className='crYogb'>
//                             <img src="https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png" height="23px" width="23px" alt="" />
//                             <span>WETH</span>
//                         </div>
//                         <div className='crYogb'>
//                             <img src="https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png" height="23px" width="23px" alt="" />
//                             <span>USDC</span>
//                         </div>
//                     </div>
//                     <div className='fxVDAj kdYshZ dKubqp'>
//                         <div className='crYogb'>
//                             <img src='https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png' height="23px" width="23px" alt="" />
//                             <span>DAI</span>
//                         </div>
//                         <div className='crYogb'>
//                             <img src="https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png" style={{ borderRadius: '50%' }} height="23px" width="23px" alt="" />
//                             <span>USDT</span>
//                         </div>
//                         <div className='crYogb'>
//                             <img src="https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png" height="23px" width="23px" alt="" />
//                             <span>WBTC</span>
//                         </div>
//                     </div>
//                 </div>
//                 <div className='hfXcaM kMkmMS scrollbar '>
//                     <div className=' text-light'>
//                         {searchQuery ? 'Searched tokens' : 'Popular tokens'}
//                     </div>
//                     {filteredTokens.length === 0 && (
//                         <div className='text-light'>No tokens found</div>
//                     )}
//                     {filteredTokens.map((token, index) => (
//                         <div className="cPCYrp dKubqp bIFEzi cFvOnL alertdiv" key={index} onClick={() => handleTokenSelect(token)}>
//                             <div className="bFCsHr"><img src={token.img} style={{ height: '24px', width: '24px', borderRadius: '50%' }} alt='' /></div>
//                             <div className="eENUcJ" >
//                                 <div className="dKubqp dKubqptext cPCYrp" >{token.name}</div>
//                                 <div className="css-1m0dqmt  jgbRhf" value={selected}>{token.ticker}</div>
//                             </div>
//                         </div>
//                     ))}
//                 </div>

//             </div>
//         </>
//     );
// };

// export default SelectToken;




import React, { useState, useEffect, useRef } from 'react';
import cross from '../Assets/images2/radix-icons_cross-2.svg';
// import tokensData from './ChainAddresses.json';

import { useContext } from 'react';
import Context from '../Context/Context';
import NetworkOverlay from './NetworkOverlay';
import { getCurrentChainIdPopularTokens, getCurrentChainIdAllTokens, getTokenAddressExists, getDetailsOfTokenAddressExists, getIsValidContractAddress } from '../ContractAction/TrendswapAction';

const SelectToken = (props, { onSelectToken }) => {


    const [searchQuery, setSearchQuery] = useState('');
    const [searchedTokenAddress, setSearchedTokenAddress] = useState('');
    const [isTokenAddressExists, setIsTokenAddressExists] = useState(false);
    const [allTokens, setAllTokens] = useState([]);
    const [selected, setSelected] = useState({});
    const [isValidContract, setIsValidContract] = useState(false);
    const inputRef = useRef(null);

    const { selectedNetwork, setSelectedNetwork, selectedToken1, setSelectedToken1, selectedToken2, setSelectedToken2, selectedToken1Address, setSelectedToken1Address, selectedToken2Address, setSelectedToken2Address, openNestedModal, currentImportedTokenAddress } = useContext(Context);
    const filteredTokens = allTokens.filter(
        (token) =>
            token.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            token.ticker.toLowerCase().includes(searchQuery.toLowerCase()) ||
            token?.address?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const searchedTokens = (
        searchedTokenAddress &&
        (searchedTokenAddress?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            searchedTokenAddress?.ticker?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            searchedTokenAddress?.address?.toLowerCase().includes(searchQuery.toLowerCase()))
    ) ? [searchedTokenAddress] : [];



    const [popularTokens, setPopularTokens] = useState([]);


    const handleSelectNetwork = (network) => {
        setSelectedNetwork(network);
    };

    const handleCrossIconClick = () => {
        props.closeModal(); // Call the closeModal function passed from the parent component
    };

    console.log('showmodel', props.showModal1)
    const handleTokenSelect = (token) => {
        console.log("tokenfunctioncalled",token);
        const selectedToken = {
            img: token.img,
            ticker: token.ticker,
            address: token.address
        };
        if (typeof props.onSelectToken === 'function') {
            setSelected(props.onSelectToken(token.img, token.ticker));
        };
        // alert(token.ticker);
        console.log('tokenticker:', token.ticker);
        if (props.showModal1 === true) {
            setSelectedToken1(selectedToken);
            setSelectedToken1Address(selectedToken.address);
            console.log('Token 1 address:', selectedToken.address);
        } else if (props.showModal2 === true) {
            setSelectedToken2(selectedToken);
            setSelectedToken2Address(selectedToken.address);
            console.log('Token 2 address:', selectedToken.address);
        }
    };


    const handlePopularTokenSelect = (index) => {
        if (popularTokens && popularTokens.length > index) {
            const popularToken = popularTokens[index];
            const alreadySelected = filteredTokens.some(token => token.name === popularToken.name);
            if (!alreadySelected) {
                setSelected(props.onSelectToken(popularToken.imgSrc, popularToken.name));
                if (props.showModal1 === true) {
                    setSelectedToken1(popularToken);
                    setSelectedToken1Address(popularToken.address);
                    console.log('Token 1 address:', popularToken.address);
                } else if (props.showModal2 === true) {
                    setSelectedToken2(popularToken);
                    setSelectedToken2Address(popularToken.address);
                    console.log('Token 2 address:', popularToken.address);
                }
                // alert(popularToken.name);
                console.log('popularTokenName:', popularToken.name);
            } else {
                console.log("Token already selected from the first section.");
            }
        }
    };

    useEffect(() => {
        const handleSearchTokenAddress = async () => {
            if (searchQuery.length > 0) {
                const tokenExits = await getTokenAddressExists(searchQuery);
                setIsTokenAddressExists(tokenExits);
                // alert(isTokenAddressExists)
                console.log("tokenExits", isTokenAddressExists);
                if (tokenExits === true) {
                    const tokenDetails = await getDetailsOfTokenAddressExists(searchQuery);
                    console.log("tokenDetails11", tokenDetails);
                    console.log("tokenDetailsimg", tokenDetails[0].img);
                    console.log("tokenDetailsname", tokenDetails[0].name);
                    console.log("tokenDetailsticker", tokenDetails[0].ticker);
                    console.log("tokenDetailsaddress", tokenDetails[0].address);
                    // alert("tokenDetailsaddress1: "+tokenDetails[0].address)
                    filteredTokens.push(tokenDetails[0]);
                    console.log("filteredTokens", filteredTokens);
                    console.log("filteredTokenslength", filteredTokens.length);
                }
                if (tokenExits === false) {
                    const tokenDetails = await getDetailsOfTokenAddressExists(searchQuery);
                    setSearchedTokenAddress(tokenDetails[0]);
                    console.log("tokenDetails", tokenDetails);
                    console.log("tokenDetailsimg", tokenDetails[0].img);
                    console.log("tokenDetailsname", tokenDetails[0].name);
                    console.log("tokenDetailsticker", tokenDetails[0].ticker);
                    console.log("tokenDetailsaddress", tokenDetails[0].address);
                    // alert("tokenDetailsaddress2: "+tokenDetails[0].address)
                    filteredTokens.push(tokenDetails[0]);
                    console.log("filteredTokens", filteredTokens);
                    console.log("filteredTokenslength", filteredTokens.length);
                    // setSelected(onSelectToken(tokenDetails[0].img, tokenDetails[0].ticker));
                }
            }
        }
        handleSearchTokenAddress()
    }, [searchQuery])

    useEffect(() => {
        const getIsValidContractAddr = async () => {
            if (!searchedTokenAddress?.address || !searchQuery) return;
            const contractAddr = searchedTokenAddress.address.toLowerCase();
            if (!contractAddr.includes(searchQuery.toLowerCase())) {
                console.log("Search query does not match the contract address.");
                return;
            }
            try {
                const isValid = await getIsValidContractAddress(contractAddr);
                console.log("isValidContractAddr:", isValid);
                setIsValidContract(isValid);
            } catch (error) {
                console.error("Error validating contract address:", error);
            }
        };
        getIsValidContractAddr();
    }, [searchedTokenAddress, searchQuery]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    useEffect(() => {
        const fetchPopularTokens = async () => {
            const tokens = await getCurrentChainIdPopularTokens();
            console.log("tokens", tokens);
            setPopularTokens(tokens);
            console.log("popularTokens", tokens);
            const allChainTokens = await getCurrentChainIdAllTokens();
            setAllTokens(allChainTokens);
            console.log("allChainTokens", allChainTokens);
        };

        fetchPopularTokens();
    }, []);

console.log("SeletTokenMounted")
    return (
        <>
            <div className='gnwYND bFCsHr'>
                <div className='hfXcaM kMkmMS'>
                    <div className='cPCYrp d-flex justify-content-between  align-items-center'>
                        <div style={{ fontSize: '20px', color: '#b1b1b1' }}>Select a token</div>
                        <img src={cross} onClick={handleCrossIconClick} style={{ height: '24px', width: '24px', cursor: 'pointer' }} alt='' />
                    </div>
                    <div className='kdYshZ dKubqp'>
                        <div className=' hTynGq' style={{ width: '72%', display: 'flex', justifyContent: 'space-between' }}>
                            <img
                                src='/images/search.png'
                                className='ms-3'
                                style={{ height: '20px', width: '20px' }}
                                alt=''
                            />
                            <input
                                type='text'
                                id='token-search-input'
                                className='w-100 hTynGqr'
                                placeholder='Search name or paste address'
                                ref={inputRef}
                                onChange={(e) => setSearchQuery(e.target.value.replace(/\s+/g, ''))}
                            />
                        </div>
                        <NetworkOverlay selectedNetwork={selectedNetwork} id="network-overlay1" onSelectNetwork={handleSelectNetwork} />
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px 20px' }}>
                        {popularTokens && popularTokens.map((token, index) => (
                            <div key={index} className='fxVDAj kdYshZ dKubqp' onClick={() => handlePopularTokenSelect(index)}>
                                <div className='crYogb'>
                                    <img src={token.imgSrc} style={{ borderRadius: "50%", height: "23px", width: "23px" }} alt="" />
                                    <span>{token.name} </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='hfXcaM kMkmMS scrollbar '>
                    <div className='text-light'>
                        {searchQuery ? 'Searched tokens' : 'Popular tokens'}
                    </div>
                    {/* {isTokenAddressExists ? (
                        <>
                            {filteredTokens.length === 0 ? (
                                <div className='text-light'>No tokens found</div>
                            ) : (
                                filteredTokens.map((token, index) => (
                                    <div className="cPCYrp dKubqp bIFEzi cFvOnL alertdiv" key={index} onClick={() => handleTokenSelect(token)}>
                                        <div className="bFCsHr"><img src={token.img} style={{ height: '24px', width: '24px', borderRadius: '50%' }} alt='' /></div>
                                        <div className="eENUcJ">
                                            <div className="dKubqp dKubqptext cPCYrp">{token.name}</div>
                                            <div className="css-1m0dqmt jgbRhf" value={selected}>{token.ticker}</div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </>
                    ) : (
                        <>
                            {searchedTokens.length === 0 ? (
                                <div className='text-light'>No tokens found</div>
                            ) : (
                                searchedTokens.map((token, index) => (
                                    <div className="cPCYrp dKubqp bIFEzi cFvOnL alertdiv" key={index} onClick={() => handleTokenSelect(token)}>
                                        <div className="bFCsHr"><img src={token.img} style={{ height: '24px', width: '24px', borderRadius: '50%' }} alt='' /></div>
                                        <div className="eENUcJ">
                                            <div className="dKubqp dKubqptext cPCYrp">{token.name}</div>
                                            <div className="css-1m0dqmt jgbRhf" value={selected}>{token.ticker}</div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </>
                    )} */}


                    {filteredTokens.length === 0 && isTokenAddressExists ? (
                        <div className='text-light'>No tokens found</div>
                    ) : (
                        filteredTokens.map((token, index) => (
                            <div className="cPCYrp dKubqp bIFEzi cFvOnL alertdiv" key={index} onClick={() => handleTokenSelect(token)}>
                                <div className="bFCsHr"><img src={token.img} style={{ height: '24px', width: '24px', borderRadius: '50%' }} alt='' /></div>
                                <div className="eENUcJ">
                                    <div className="dKubqp dKubqptext cPCYrp">{token.name}</div>
                                    <div className="css-1m0dqmt jgbRhf" value={selected}>{token.ticker}</div>
                                </div>
                            </div>
                        ))
                    )}
                    {!filteredTokens.length && !isTokenAddressExists && (
                        <>
                            {isValidContract ? (
                                searchedTokens.length > 0 ? (
                                    searchedTokens.map((token, index) => (
                                        <div className="cPCYrp dKubqp bIFEzi cFvOnL alertdiv" key={index} onClick={() => handleTokenSelect(token)}>
                                            <div className="bFCsHr">
                                                <img src={token.img} style={{ height: '24px', width: '24px', borderRadius: '50%' }} alt={token.name} />
                                            </div>
                                            <div className="eENUcJ">
                                                <div className="dKubqp dKubqptext cPCYrp">{token.name}</div>
                                                <div className="css-1m0dqmt jgbRhf">{token.ticker}</div>
                                            </div>
                                            <button className='btn btn-danger' onClick={openNestedModal}>Import</button>
                                        </div>
                                    ))
                                ) : (
                                    <div className='text-light'>No tokens found</div>
                                )
                            ) : (
                                <div className='text-light'>No tokens found</div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default SelectToken;