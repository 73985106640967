import Web3 from "web3";
import { ethers } from "ethers";
import { abiRouter } from "./ABI/RouterABI";
import { abiERC20Token } from "./ABI/ERC20TokenABI";
import { abiPair } from "./ABI/PairABI";
import { abiFactory } from "./ABI/FactoryAbi";
import { APIGETALLTRANSACTION, APISTORETRANSACTIONS, APIUPDATEPOOLDATA, APIUPDATETOKENDATA, TRNDContractAddress, getCurrentAccount, getCurrentChainId, getCurrentProvider } from "./ContractDependency";
import { isAddress } from 'web3-validator';
const chainAddresses = require('./ChainAddresses.json');

/*global BigInt*/


//Sayyed code
// export const getTokenDecimals = async (tokenAddress) => {
//     try {
//         const provider = await getCurrentProvider();
//         if (provider) {
//             const web3 = new Web3(provider);
//             const tokenContract = new web3.eth.Contract(abiERC20Token, tokenAddress);
//             const tokenDecimals = await tokenContract.methods.decimals().call();
//             return tokenDecimals;
//         }
//     } catch (error) {
//         console.error("An error occurred while fetching token decimals:", error);
//         return null;
//     }
// };

//Shreyansh code
export const getTokenDecimals = async (tokenAddress) => {
    try{
        const provider = await getCurrentProvider();
        if(provider){
            const tokenContract = new ethers.Contract(tokenAddress, abiERC20Token, provider);
            const tokenDecimals = await tokenContract.decimals();
            return tokenDecimals;
        }
    }
    catch(error){
        console.error("An error occurred while fetching token decimals:", error);
        return null;
    }
}


//Sayyed code
// export const setTokenApproveRouter = async (amount, tokenContractAddress) => {
//     console.log("setTokenApproveRouter");
//     try {
//         let provider = await getCurrentProvider();
//         if (provider) {
//             const web3 = new Web3(provider);
//             const account = await getCurrentAccount();
//             const currentChainId = await getCurrentChainId();
//             const contracts = await new web3.eth.Contract(abiERC20Token, tokenContractAddress);
//             const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
//             let routerAddress;
//             if (routerAddressObject) {
//                 routerAddress = routerAddressObject[currentChainId].RouterAddress;
//             } else {
//                 console.log("Router address not found for current chain ID");
//             }
//             const amountTokenInWei = await getFinalAmountWei(amount, tokenContractAddress);
//             const amountApproveTokenToRouter = await contracts.methods
//                 .approve(routerAddress, amountTokenInWei)
//                 .send({ from: account });
//             console.log("amountApproveTokenToRouter", amountApproveTokenToRouter);
//         }
//     } catch (error) {
//         console.error("amountApproveTokenToRouterError", error);
//     }
// };

export const setTokenApproveRouter = async (amount, tokenContractAddress) => {
    console.log("setTokenApproveRouter");
    try {
        const provider = await getCurrentProvider();
        if (provider) {
            const account = await getCurrentAccount();
            const currentChainId = await getCurrentChainId();
            const contracts = new ethers.Contract(tokenContractAddress, abiERC20Token, provider.getSigner());
            const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
            let routerAddress;
            if (routerAddressObject) {
                routerAddress = routerAddressObject[currentChainId].RouterAddress;
            } else {
                console.log("Router address not found for current chain ID");
                return;
            }
            const amountTokenInWei = await getFinalAmountWei(amount, tokenContractAddress);
            const tx = await contracts.approve(routerAddress, amountTokenInWei);
            console.log("Approve transaction sent:", tx);
            const receipt = await tx.wait();
            console.log("Transaction mined:", receipt);
            return receipt.transactionHash;
        }
    } catch (error) {
        console.error("amountApproveTokenToRouterError", error);
    }
};


// export const setCreatePairforTokenATokenB = async (TokenAContractAddress, TokenBContractAddress) => {
//     console.log("setCreatePairforTokenATokenB")
//     try {
//         let provider = await getCurrentProvider();
//         if (provider) {
//             const web3 = new Web3(provider);
//             const account = await getCurrentAccount();
//             const currentChainId = await getCurrentChainId();
//             const factoryAddressObject = chainAddresses.find(chain => chain[currentChainId]);
//             let factoryAddress;
//             if (factoryAddressObject) {
//                 factoryAddress = factoryAddressObject[currentChainId].FactoryAddress;
//             } else {
//                 console.log("Factory address not found for current chain ID");
//             }
//             const contracts = new web3.eth.Contract(abiFactory, factoryAddress);
//             console.log("account", account);
//             console.log("factoryAddress", factoryAddress);
//             const tokenAnBPair = await contracts.methods.createPair(TokenAContractAddress, TokenBContractAddress).send({ from: account });
//             console.log("setPairforTokenATokenB_tokenAnBPair", tokenAnBPair);
//             return tokenAnBPair;
//         };
//     } catch (error) {
//         console.error("setPairforTokenATokenBERROR", error);
//     }
// };
export const setCreatePairforTokenATokenB = async (TokenAContractAddress, TokenBContractAddress) => {
    console.log("setCreatePairforTokenATokenB");
    try {
        let provider = await getCurrentProvider(); 
        if (provider) {
            const account = await getCurrentAccount(); 
            const currentChainId = await getCurrentChainId(); 
            const factoryAddressObject = chainAddresses.find(chain => chain[currentChainId]);

            let factoryAddress;
            if (factoryAddressObject) {
                factoryAddress = factoryAddressObject[currentChainId].FactoryAddress;
            } else {
                console.log("Factory address not found for current chain ID");
                return;
            }
            const signer = provider.getSigner(); 
            const factoryContract = new ethers.Contract(factoryAddress, abiFactory, signer);
            console.log("Account:", account);
            console.log("Factory Address:", factoryAddress);
            const tx = await factoryContract.createPair(TokenAContractAddress, TokenBContractAddress);
            const receipt = await tx.wait();
            console.log("Pair created for Token A and Token B", receipt);
            return receipt;
        }
    } catch (error) {
        console.error("setCreatePairforTokenATokenB ERROR", error);
    }
};

// export const getFinalAmountWei = async (amount, tokenContractAddress) => {
//     try {
//         const provider = await getCurrentProvider();
//         if (provider) {
//             const web3 = new Web3(provider);
//             let finalAmountWei = web3.utils.toWei(amount.toString(), 'ether');
//             finalAmountWei /= Math.pow(10, 18);
//             console.log("finalAmountWei", finalAmountWei);
//             const tokenDecimals = await getTokenDecimals(tokenContractAddress);
//             finalAmountWei *= Math.pow(10, tokenDecimals.toString());
//             console.log("finalAmountWei after decimals", finalAmountWei);
//             return finalAmountWei;
//         }
//     } catch (error) {
//         console.error("Error in getFinalAmountWei:", error);
//     }
// };

export const getFinalAmountWei = async (amount, tokenContractAddress) => {
    try {
        const provider = await getCurrentProvider(); // Get the ethers.js provider
        if (provider) {
            // Get the token's decimals only once and use it for parsing
            const tokenDecimals = await getTokenDecimals(tokenContractAddress);
            const finalAmountWei = ethers.utils.parseUnits(amount.toString(), tokenDecimals);
            console.log("finalAmountWei (using token decimals)", finalAmountWei.toString());
            return finalAmountWei.toString();
        } else {
            console.error("No provider found. Please connect your wallet.");
            return null;
        }
    } catch (error) {
        console.error("Error in getFinalAmountWei:", error);
        return null;
    }
};


// export const getFinalAmountEth = async (amountInWei, tokenContractAddress) => {
//     try {
//         const provider = await getCurrentProvider();
//         if (provider) {
//             const web3 = new Web3(provider);
//             let finalAmountEth = web3.utils.fromWei(amountInWei.toString(), 'ether');
//             finalAmountEth *= Math.pow(10, 18);
//             console.log("finalAmountEth", finalAmountEth);
//             const tokenDecimals = await getTokenDecimals(tokenContractAddress);
//             finalAmountEth /= Math.pow(10, tokenDecimals.toString());
//             console.log("finalAmountEth after decimals", finalAmountEth);
//             return finalAmountEth;
//         }
//     } catch (error) {
//         console.error("Error in getFinalAmountEth:", error);
//     }
// };

export const getFinalAmountEth = async (amountInWei, tokenContractAddress) => {
    try {
        const provider = await getCurrentProvider(); 
        if (provider) {
            const tokenDecimals = await getTokenDecimals(tokenContractAddress);
            let finalAmountEth = ethers.utils.formatUnits(amountInWei.toString(), tokenDecimals);
            console.log("finalAmountEth (before formatting)", finalAmountEth);
            console.log("finalAmountEth (after formatting)", finalAmountEth);
            return finalAmountEth;
        } else {
            console.error("No provider found. Please connect your wallet.");
            return null;
        }
    } catch (error) {
        console.error("Error in getFinalAmountEth:", error);
        return null;
    }
};


// export const addliquidity = async (_amount0, _amount1, TokenAContractAddress, TokenBContractAddress) => {
//     console.log("addliquidity", _amount0, _amount1, TokenAContractAddress, TokenBContractAddress);
//     let provider = await getCurrentProvider();
//     if (provider) {
//         const web3 = new Web3(provider);
//         const account = await getCurrentAccount();
//         const currentChainId = await getCurrentChainId();
//         console.log("_amount0", _amount0)
//         console.log("_amount1", _amount1)
//         const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
//         let routerAddress;
//         if (routerAddressObject) {
//             routerAddress = routerAddressObject[currentChainId].RouterAddress;
//         } else {
//             console.log("Router address not found for current chain ID");
//         }
//         const contracts = new web3.eth.Contract(abiRouter, routerAddress);
//         console.log(contracts);
//         const to = account;
//         console.log("_to", to);
//         const start = Date.now();
//         let epoch = start / 1000;
//         let myepoch = epoch.toFixed(0);
//         console.log("epoch...", myepoch);
//         const deadlineEpoch = Number(myepoch) + 500;
//         console.log("deadlineEpoch", deadlineEpoch);
//         const _finalamount0Wei = await getFinalAmountWei(_amount0, TokenAContractAddress);
//         const _finalamount1Wei = await getFinalAmountWei(_amount1, TokenBContractAddress);
//         let pairAddr = await getPairforTokenATokenB(TokenAContractAddress, TokenBContractAddress);
//         console.log("pairAddr", pairAddr)
//         if (pairAddr === "0x0000000000000000000000000000000000000000") {
//             console.log("CurrentlyNoPairFound_SocreatingPairforTokenATokenB")
//         }
//         const response = await contracts.methods
//             .addLiquidity(TokenAContractAddress, TokenBContractAddress, _finalamount0Wei, _finalamount1Wei, 0, 0, to, deadlineEpoch)
//             .send({ from: account });
//         console.log("Contract response:", response);
//         console.log("AddLiquidityTxHash:", response.transactionHash);
//         return response.transactionHash;
//     }
// };

export const addliquidity = async (_amount0, _amount1, TokenAContractAddress, TokenBContractAddress) => {
    console.log("addliquidity", _amount0, _amount1, TokenAContractAddress, TokenBContractAddress);
    try {
        const provider = await getCurrentProvider(); 
        const account = await getCurrentAccount();
        const currentChainId = await getCurrentChainId(); 
        console.log("_amount0", _amount0);
        console.log("_amount1", _amount1);
        const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
        let routerAddress;
        if (routerAddressObject) {
            routerAddress = routerAddressObject[currentChainId].RouterAddress;
        } else {
            console.log("Router address not found for current chain ID");
            return;
        }
        const signer = provider.getSigner();
        const contract = new ethers.Contract(routerAddress, abiRouter, signer);
        console.log("Router contract:", contract);
        const to = account;
        console.log("_to", to);
        const start = Date.now();
        const myepoch = Math.floor(start / 1000); 
        const deadlineEpoch = myepoch + 500; 
        console.log("deadlineEpoch", deadlineEpoch);
        const _finalamount0Wei = await getFinalAmountWei(_amount0, TokenAContractAddress);
        const _finalamount1Wei = await getFinalAmountWei(_amount1, TokenBContractAddress);
        console.log("_amount0,TokenAContractAddress",_amount0, TokenAContractAddress);
        console.log("_amount1,TokenBContractAddress",_amount1, TokenBContractAddress);
        console.log("Final amount 0 in Wei:", _finalamount0Wei);
        console.log("Final amount 1 in Wei:", _finalamount1Wei);
        let pairAddr = await getPairforTokenATokenB(TokenAContractAddress, TokenBContractAddress);
        console.log("pairAddr", pairAddr);
        if (pairAddr === ethers.constants.AddressZero) {
            console.log("No existing pair found, creating a new pair for TokenA and TokenB");
        }
        const tx = await contract.addLiquidity(
            TokenAContractAddress,
            TokenBContractAddress,
            _finalamount0Wei,
            _finalamount1Wei,
            0, 
            0, 
            to, 
            deadlineEpoch 
        );
        const receipt = await tx.wait(); 
        console.log("AddLiquidityTxHash:", receipt.transactionHash);
        return receipt.transactionHash;
    } catch (error) {
        console.error("Error in addLiquidity:", error);
    }
};

// export const addLiquidityETH = async (_amount0, _amount1, TokenAContractAddress, TokenBContractAddress) => {
//     console.log("addLiquidityETH", _amount0, _amount1, TokenAContractAddress, TokenBContractAddress);
//     let provider = await getCurrentProvider();
//     if (provider) {
//         const web3 = new Web3(provider);
//         const account = await getCurrentAccount();
//         console.log("getCurrentAccountaddLiquidityETH", account);
//         const currentChainId = await getCurrentChainId();
//         const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
//         let routerAddress;
//         if (routerAddressObject) {
//             routerAddress = routerAddressObject[currentChainId].RouterAddress;
//         } else {
//             console.log("Router address not found for current chain ID");
//         }
//         const contracts = new web3.eth.Contract(abiRouter, routerAddress);
//         const to = account;
//         const start = Date.now();
//         let epoch = start / 1000;
//         let myepoch = epoch.toFixed(0);
//         const deadlineEpoch = Number(myepoch) + 500;
//         const _finalamount0Wei = await getFinalAmountWei(_amount0, TokenAContractAddress);
//         const _finalamount1Wei = await getFinalAmountWei(_amount1, TokenBContractAddress);
//         let pairAddr = await getPairforTokenATokenB(TokenAContractAddress, TokenBContractAddress);
//         console.log("pairAddr", pairAddr);
//         if (pairAddr === "0x0000000000000000000000000000000000000000") {
//             // await setCreatePairforTokenATokenB(TokenAContractAddress, TokenBContractAddress);
//             console.log("CurrentlyNoPairFound_SocreatingPairforTokenATokenBWithinAddLiquidityETH")
//         }
//         const response = await contracts.methods
//             .addLiquidityETH(TokenBContractAddress, _finalamount1Wei, 0, 0, to, deadlineEpoch)
//             .send({ from: account, value: _finalamount0Wei });
//         console.log("Contract response:", response);
//         console.log("addLiquidityETHTxHash", response.transactionHash);
//         return response.transactionHash;
//     }
// }
// addLiquidityETH("0.0002", "1", "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd", "0xB6d4E642639D5CC61267c301F2B31e96AA7Ff097");


export const addLiquidityETH = async (_amount0, _amount1, TokenAContractAddress, TokenBContractAddress) => {
    console.log("addLiquidityETH", _amount0, _amount1, TokenAContractAddress, TokenBContractAddress);
    try {
        const provider = await getCurrentProvider();
        const account = await getCurrentAccount(); 
        console.log("getCurrentAccountaddLiquidityETH", account);
        const currentChainId = await getCurrentChainId(); 
        const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
        let routerAddress;
        if (routerAddressObject) {
            routerAddress = routerAddressObject[currentChainId].RouterAddress;
        } else {
            console.log("Router address not found for current chain ID");
            return;
        }
        const signer = provider.getSigner();
        const contract = new ethers.Contract(routerAddress, abiRouter, signer);
        const to = account; 
        const start = Date.now();
        const myepoch = Math.floor(start / 1000); 
        const deadlineEpoch = myepoch + 500; 
        console.log("deadlineEpoch", deadlineEpoch);
        const _finalamount0Wei = await getFinalAmountWei(_amount0, TokenAContractAddress);
        const _finalamount1Wei = await getFinalAmountWei(_amount1, TokenBContractAddress);
        console.log("Final amount 0 in Wei:", _finalamount0Wei);
        console.log("Final amount 1 in Wei:", _finalamount1Wei);
        let pairAddr = await getPairforTokenATokenB(TokenAContractAddress, TokenBContractAddress);
        console.log("pairAddr", pairAddr);
        if (pairAddr === ethers.constants.AddressZero) {
            console.log("CurrentlyNoPairFound_SoCreatingPairForTokenATokenBWithinAddLiquidityETH");
        }
        const tx = await contract.addLiquidityETH(
            TokenBContractAddress,
            _finalamount1Wei,
            0,
            0, 
            to, 
            deadlineEpoch, 
            { value: _finalamount0Wei } 
        );
        const receipt = await tx.wait(); 
        console.log("addLiquidityETHTxHash:", receipt.transactionHash);
        return receipt.transactionHash;
    } catch (error) {
        console.error("Error in addLiquidityETH:", error);
    }
};

// export const setPairApproveRouter = async (amountAprove, TokenAContractAddress, TokenBContractAddress) => {
//     try {
//         let provider = await getCurrentProvider();
//         if (provider) {
//             const web3 = new Web3(provider);
//             const account = await getCurrentAccount();
//             const currentChainId = await getCurrentChainId();
//             const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
//             let routerAddress;
//             if (routerAddressObject) {
//                 routerAddress = routerAddressObject[currentChainId].RouterAddress;
//             } else {
//                 console.log("Router address not found for current chain ID");
//             }
//             const pairAddress = TokenAContractAddress && TokenBContractAddress ? await getTokenPairAddress(TokenAContractAddress, TokenBContractAddress) : "0x0000000000000000000000000000000000000000";
//             if (pairAddress == "0x0000000000000000000000000000000000000000") {
//                 console.log("Pair address is invalid. Reason: Tokens not found.");
//                 return 0;
//             }
//             console.log("pairAddress", pairAddress);
//             const contracts = new web3.eth.Contract(abiPair, pairAddress);
//             const approveRouter = await contracts.methods.approve(routerAddress, amountAprove).send({ from: account });
//             return approveRouter
//         };
//     } catch (error) {
//         console.error("PairApproveRouterError", error);
//     }
// };

export const setPairApproveRouter = async (amountApprove, TokenAContractAddress, TokenBContractAddress) => {
    try {
        const provider = await getCurrentProvider(); 
        const account = await getCurrentAccount(); 
        const currentChainId = await getCurrentChainId();
        const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
        let routerAddress;
        if (routerAddressObject) {
            routerAddress = routerAddressObject[currentChainId].RouterAddress;
        } else {
            console.log("Router address not found for current chain ID");
            return;
        }
        const pairAddress = TokenAContractAddress && TokenBContractAddress 
            ? await getTokenPairAddress(TokenAContractAddress, TokenBContractAddress) 
            : ethers.constants.AddressZero;
        if (pairAddress === ethers.constants.AddressZero) {
            console.log("Pair address is invalid. Reason: Tokens not found.");
            return 0;
        }
        console.log("pairAddress", pairAddress);

        const contract = new ethers.Contract(pairAddress, abiPair, provider.getSigner());
                const tx = await contract.approve(routerAddress, amountApprove);
        const receipt = await tx.wait();
        console.log("Approval transaction hash:", receipt.transactionHash);
        return receipt.transactionHash;
    } catch (error) {
        console.error("PairApproveRouterError", error);
    }
};


// export const removeliquidity = async (_amount, TokenAContractAddress, TokenBContractAddress) => {
//     console.log("removeliquidity");
//     let provider = await getCurrentProvider();
//     if (provider) {
//         const web3 = new Web3(provider);
//         const account = await getCurrentAccount();
//         const currentChainId = await getCurrentChainId();
//         const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
//         let routerAddress;
//         if (routerAddressObject) {
//             routerAddress = routerAddressObject[currentChainId].RouterAddress;
//         } else {
//             console.log("Router address not found for current chain ID");
//         }
//         const contracts = new web3.eth.Contract(abiRouter, routerAddress);
//         console.log(contracts);
//         const to = account;
//         console.log("_to", to);
//         const start = Date.now();
//         let epoch = start / 1000;
//         let myepoch = epoch.toFixed(0);
//         console.log("epoch...", myepoch);
//         const deadlineEpoch = Number(myepoch) + 500;
//         console.log("deadlineEpoch", deadlineEpoch)
//         const inputValue = _amount;
//         const _finalamountWei = web3.utils.toWei(inputValue.toString(), 'ether');
//         console.log("_finalamountWei", _finalamountWei)
//         const response = await contracts.methods
//             .removeLiquidity(TokenAContractAddress, TokenBContractAddress, _amount, 0, 0, to, deadlineEpoch)
//             .send({ from: account });
//         console.log("Contract response:", response);
//         console.log("RemoveLiquidityTxHash:", response.transactionHash);
//         return response.transactionHash;
//     }
// };

export const removeliquidity = async (_amount, TokenAContractAddress, TokenBContractAddress) => {
    console.log("removeliquidity",_amount,TokenAContractAddress, TokenBContractAddress);
    const provider = await getCurrentProvider();
    const account = await getCurrentAccount(); 
    const currentChainId = await getCurrentChainId(); 
    const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
    let routerAddress;
    if (routerAddressObject) {
        routerAddress = routerAddressObject[currentChainId].RouterAddress;
    } else {
        console.log("Router address not found for current chain ID");
        return;
    }
    const contracts = new ethers.Contract(routerAddress, abiRouter, provider.getSigner());
    console.log(contracts);
    const to = account; 
    const start = Date.now();
    const epoch = Math.floor(start / 1000);
    const deadlineEpoch = epoch + 500; 
    console.log("deadlineEpoch", deadlineEpoch);
    const _finalamountWei = ethers.utils.parseUnits(_amount.toString(), 18); // Adjust the decimals based on token
    console.log("_finalamountWei", _finalamountWei.toString());
    try {
        const tx = await contracts.removeLiquidity(
            TokenAContractAddress,
            TokenBContractAddress,
            _finalamountWei, 
            0, 
            0, 
            to,
            deadlineEpoch 
        );
        const receipt = await tx.wait();
        console.log("Contract response:", receipt);
        console.log("RemoveLiquidityTxHash:", receipt.transactionHash);
        return receipt.transactionHash;
    } catch (error) {
        console.error("Error in removeLiquidity:", error);
    }
};

// export const setSwapTokenAForTokenB = async (amountIn, TokenAContractAddress, TokenBContractAddress) => {
//     console.log("setSwapTokenAForTokenB")
//     try {
//         let provider = await getCurrentProvider();
//         if (provider) {
//             const web3 = new Web3(provider);
//             const account = await getCurrentAccount();
//             const currentChainId = await getCurrentChainId();
//             const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
//             let routerAddress;
//             if (routerAddressObject) {
//                 routerAddress = routerAddressObject[currentChainId].RouterAddress;
//             } else {
//                 console.log("Router address not found for current chain ID");
//             }
//             const contract = new web3.eth.Contract(abiRouter, routerAddress);
//             // Convert the input values to BigNumber or use string (if required)
//             const amountInWei = await getFinalAmountWei(amountIn, TokenAContractAddress);
//             console.log("amountInWei", amountInWei)
//             const amountoutmin = await calculateAmountOutMin(amountIn, TokenAContractAddress, TokenBContractAddress, 0.1);
//             const amountOutMin = amountoutmin[0];
//             console.log("swapTokenAForTokenBamountOutMin", amountOutMin)
//             const path = [
//                 TokenAContractAddress,
//                 TokenBContractAddress
//             ];
//             // const amountOutMinWei = web3.utils.toWei(amountOutMin.toString(), 'ether');
//             // console.log("amountOutMinWei", amountOutMinWei)
//             // Calculate the deadline as 10 minutes ahead of the current time
//             const currentTimestamp = Math.floor(Date.now() / 1000); // Convert to seconds
//             const deadline = currentTimestamp + 600; // 600 seconds = 10 minutes
//             // Execute the swapExactTokensForTokensSupportingFeeOnTransferTokens || swapExactTokensForTokens2 function
//             // const isTaxedPair = await getAddressApplicableForTaxed(TokenAContractAddress, TokenBContractAddress);
//             // console.log("isTaxedPair", isTaxedPair)
//             const amountValueOutMin = await getValueOutTokenAtoTokenB(amountIn, TokenAContractAddress, TokenBContractAddress);
//             const result =
//                 // isTaxedPair == false ?
//                 await contract.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(amountInWei, amountOutMin, path, account, deadline).send({ from: account })
//             // : await contract.methods.swapExactTokensForTokens2(amountInWei, amountOutMin, path, account, deadline).send({ from: account });
//             const storeTransactionDetailsData = await storeTransactionDetails(amountIn, TokenAContractAddress, TokenBContractAddress, account, amountValueOutMin, result.transactionHash);
//             console.log("storeTransactionDetailsData", storeTransactionDetailsData);
//             // window.location.reload();
//             console.log('setSwapStableForTokens_result:', result);
//             console.log('setSwapStableForTokens_transactionHash:', result.transactionHash);
//             return result.transactionHash;;
//         }
//     } catch (error) {
//         console.error('setSwapStableForTokens_error:', error);
//     }
// };

export const setSwapTokenAForTokenB = async (amountIn, TokenAContractAddress, TokenBContractAddress, slippage) => {
    console.log("setSwapTokenAForTokenB_CA", amountIn, TokenAContractAddress, TokenBContractAddress, slippage);
    
    try {
        const provider = await getCurrentProvider(); // Use the global ethersProvider
        const account = await getCurrentAccount(); // Get the current connected account
        const currentChainId = await getCurrentChainId(); // Get the current chain ID
                const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
        let routerAddress;
        if (routerAddressObject) {
            routerAddress = routerAddressObject[currentChainId].RouterAddress;
        } else {
            console.log("Router address not found for current chain ID");
            return;
        }
        const contract = new ethers.Contract(routerAddress, abiRouter, provider.getSigner());
                const amountInWei = await getFinalAmountWei(amountIn, TokenAContractAddress);
        console.log("amountInWei", amountInWei.toString());
        const amountOutMin = await calculateAmountOutMin(amountIn, TokenAContractAddress, TokenBContractAddress, slippage);
        console.log("swapTokenAForTokenB amountOutMin", amountOutMin[0].toString());
        const path = [
            TokenAContractAddress,
            TokenBContractAddress
        ];
        const currentTimestamp = Math.floor(Date.now() / 1000); // Convert to seconds
        const deadline = currentTimestamp + 600; // 600 seconds = 10 minutes
        const result = await contract.swapExactTokensForTokensSupportingFeeOnTransferTokens(
            amountInWei,
            amountOutMin[0].toString(), // Assuming the first element is the minimum amount
            path,
            account,
            deadline
        );
        const receipt = await result.wait();
        console.log("Transaction response:", receipt);
        const amountValueOutMin = await getValueOutTokenAtoTokenB(amountIn, TokenAContractAddress, TokenBContractAddress);
        console.log("amountValueOutMinSwapExactAtoB",amountValueOutMin)
        const storeTransactionDetailsData = await storeTransactionDetails(amountIn, TokenAContractAddress, TokenBContractAddress, account, amountValueOutMin, receipt.transactionHash);
        console.log("storeTransactionDetailsData", storeTransactionDetailsData);
        console.log('setSwapTokenAForTokenB transactionHash:', receipt.transactionHash);
        return receipt.transactionHash;
    } catch (error) {
        console.error('setSwapTokenAForTokens_error:', error);
    }
};

// export const setSwapExactETHForTokens = async (amountIn, TokenAContractAddress, TokenBContractAddress) => {
//     console.log("setSwapExactETHForTokens");
//     try {
//         let provider = await getCurrentProvider();
//         if (provider) {
//             const web3 = new Web3(provider);
//             const account = await getCurrentAccount();
//             const currentChainId = await getCurrentChainId();
//             const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
//             let routerAddress;
//             if (routerAddressObject) {
//                 routerAddress = routerAddressObject[currentChainId].RouterAddress;
//             } else {
//                 console.log("Router address not found for current chain ID");
//             }
//             const contract = new web3.eth.Contract(abiRouter, routerAddress);
//             const amountInWei = await getFinalAmountWei(amountIn, TokenAContractAddress);
//             const amountoutmin = await calculateAmountOutMin(amountIn, TokenAContractAddress, TokenBContractAddress, 0.1);
//             const amountOutMin = amountoutmin[0];
//             console.log("setSwapExactETHForTokensamountOutMin", amountOutMin)
//             const path = [
//                 TokenAContractAddress,
//                 TokenBContractAddress
//             ];
//             const currentTimestamp = Math.floor(Date.now() / 1000);
//             const deadline = currentTimestamp + 600; // 600 seconds = 10 minutes
//             // Execute the swapExactETHForTokensSupportingFeeOnTransferTokens || swapExactETHForTokens function
//             // const isTaxedPair = await getAddressApplicableForTaxed(TokenAContractAddress, TokenBContractAddress);
//             // console.log("isTaxedPair", isTaxedPair)
//             const amountValueOutMin = await getValueOutTokenAtoTokenB(amountIn, TokenAContractAddress, TokenBContractAddress);
//             const result =
//                 //  isTaxedPair == false ? 
//                 await contract.methods.swapExactETHForTokensSupportingFeeOnTransferTokens(amountOutMin, path, account, deadline).send({ from: account, value: amountInWei })
//             // : await contract.methods.swapExactETHForTokens(amountOutMin, path, account, deadline).send({ from: account, value: amountInWei });
//             const storeTransactionDetailsData = await storeTransactionDetails(amountIn, TokenAContractAddress, TokenBContractAddress, account, amountValueOutMin, result.transactionHash);
//             console.log("storeTransactionDetailsData", storeTransactionDetailsData);
//             // window.location.reload();
//             console.log('swapExactETHForTokens_result:', result);
//             console.log('swapExactETHForTokens_transactionHash:', result.transactionHash);
//             return result.transactionHash;
//         }
//     } catch (error) {
//         console.error('swapExactETHForTokens_error:', error);
//     }
// }

export const setSwapExactETHForTokens = async (amountIn, TokenAContractAddress, TokenBContractAddress, slippage) => {
    console.log("setSwapExactETHForTokens_CA", amountIn, TokenAContractAddress, TokenBContractAddress, slippage);
    try {
        const provider = await getCurrentProvider(); 
        const account = await getCurrentAccount(); 
        const currentChainId = await getCurrentChainId(); 
        const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
        let routerAddress;
        if (routerAddressObject) {
            routerAddress = routerAddressObject[currentChainId].RouterAddress;
        } else {
            console.log("Router address not found for current chain ID");
            return;
        }
        const contract = new ethers.Contract(routerAddress, abiRouter, provider.getSigner());
        const amountInWei = ethers.utils.parseEther(amountIn.toString()); // Safely convert to wei
        console.log("amountInWei", amountInWei.toString());
        const amountOutMin = await calculateAmountOutMin(amountInWei, TokenAContractAddress, TokenBContractAddress, slippage);
        console.log("setSwapExactETHForTokens amountOutMin", amountOutMin[0].toString());
        const path = [TokenAContractAddress, TokenBContractAddress];
        const currentTimestamp = Math.floor(Date.now() / 1000); // Convert to seconds
        const deadline = currentTimestamp + 600; // 600 seconds = 10 minutes
        const amountValueOutMin = await getValueOutTokenAtoTokenB(amountInWei, TokenAContractAddress, TokenBContractAddress);
        console.log("amountValueOutMin",amountValueOutMin);
        const result = await contract.swapExactETHForTokensSupportingFeeOnTransferTokens(
            // amountOutMin[0].toString(), // Ensure BigNumber
            0,
            path,
            account,
            deadline,
            { value: amountInWei } // Use converted `amountInWei`
        );
        const receipt = await result.wait();
        console.log("Transaction response:", receipt);
        const storeTransactionDetailsData = await storeTransactionDetails(
            amountIn, 
            TokenAContractAddress, 
            TokenBContractAddress, 
            account, 
            amountValueOutMin, 
            receipt.transactionHash
        );
        console.log("storeTransactionDetailsData", storeTransactionDetailsData);
        console.log('swapExactETHForTokens transactionHash:', receipt.transactionHash);
        return receipt.transactionHash;
    } catch (error) {
        console.error('swapExactETHForTokens_error:', error);
    }
};



// export const setSwapExactTokensForETH = async (amountIn, TokenAContractAddress, TokenBContractAddress) => {
//     console.log("setSwapExactTokensForETH");
//     try {
//         let provider = await getCurrentProvider();
//         if (provider) {
//             const web3 = new Web3(provider);
//             const account = await getCurrentAccount();
//             const currentChainId = await getCurrentChainId();
//             const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
//             let routerAddress;
//             if (routerAddressObject) {
//                 routerAddress = routerAddressObject[currentChainId].RouterAddress;
//             } else {
//                 console.log("Router address not found for current chain ID");
//             }
//             const contract = new web3.eth.Contract(abiRouter, routerAddress);
//             const amountInWei = await getFinalAmountWei(amountIn, TokenAContractAddress);
//             const amountoutmin = await calculateAmountOutMin(amountIn, TokenAContractAddress, TokenBContractAddress, 0.1);
//             const amountOutMin = amountoutmin[0];
//             console.log("setSwapExactTokensForETHamountOutMin", amountOutMin)
//             const path = [
//                 TokenAContractAddress,
//                 TokenBContractAddress
//             ];
//             const currentTimestamp = Math.floor(Date.now() / 1000);
//             const deadline = currentTimestamp + 600; // 600 seconds = 10 minutes
//             // Execute the swapExactTokensForETHSupportingFeeOnTransferTokens || swapExactTokensForETH function
//             // const isTaxedPair = await getAddressApplicableForTaxed(TokenAContractAddress, TokenBContractAddress);
//             // console.log("isTaxedPair", isTaxedPair)
//             const amountValueOutMin = await getValueOutTokenAtoTokenB(amountIn, TokenAContractAddress, TokenBContractAddress);
//             const result =
//                 // isTaxedPair == false ? 
//                 await contract.methods.swapExactTokensForETHSupportingFeeOnTransferTokens(amountInWei, amountOutMin, path, account, deadline).send({ from: account })
//             // : await contract.methods.swapExactTokensForETH(amountInWei, amountOutMin, path, account, deadline).send({ from: account });
//             const storeTransactionDetailsData = await storeTransactionDetails(amountIn, TokenAContractAddress, TokenBContractAddress, account, amountValueOutMin, result.transactionHash);
//             console.log("storeTransactionDetailsData", storeTransactionDetailsData);
//             // window.location.reload();
//             console.log('swapExactTokensForETH_result:', result);
//             console.log('swapExactTokensForETH_transactionHash:', result.transactionHash);
//             return result.transactionHash;
//         }
//     } catch (error) {
//         console.error('swapExactTokensForETH_error:', error);
//     }
// }
export const setSwapExactTokensForETH = async (amountIn, TokenAContractAddress, TokenBContractAddress, slippage) => {
    console.log("setSwapExactTokensForETH_CA", amountIn, TokenAContractAddress, TokenBContractAddress, slippage);
    try {
        const provider = await getCurrentAccount(); // Use the global ethersProvider
        const account = await getCurrentAccount(); // Get the current connected account
        const currentChainId = await getCurrentChainId(); // Get the current chain ID
        const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
        let routerAddress;
        if (routerAddressObject) {
            routerAddress = routerAddressObject[currentChainId].RouterAddress;
        } else {
            console.log("Router address not found for current chain ID");
            return;
        }
        const contract = new ethers.Contract(routerAddress, abiRouter, provider.getSigner());
       const amountInWei = await getFinalAmountWei(amountIn, TokenAContractAddress);
        console.log("amountInWei", amountInWei.toString());
        const amountOutMin = await calculateAmountOutMin(amountIn, TokenAContractAddress, TokenBContractAddress, slippage);
        console.log("setSwapExactTokensForETH amountOutMin", amountOutMin[0].toString());
        const path = [
            TokenAContractAddress,
            TokenBContractAddress
        ];
        const currentTimestamp = Math.floor(Date.now() / 1000); // Convert to seconds
        const deadline = currentTimestamp + 600; // 600 seconds = 10 minutes
        const amountValueOutMin = await getValueOutTokenAtoTokenB(amountIn, TokenAContractAddress, TokenBContractAddress);
        const result = await contract.swapExactTokensForETHSupportingFeeOnTransferTokens(
            amountInWei,
            amountOutMin[0], 
            path,
            account,
            deadline
        );
        const receipt = await result.wait();
        console.log("Transaction response:", receipt);
        const storeTransactionDetailsData = await storeTransactionDetails(amountIn, TokenAContractAddress, TokenBContractAddress, account, amountValueOutMin, receipt.transactionHash);
        console.log("storeTransactionDetailsData", storeTransactionDetailsData);
        console.log('swapExactTokensForETH transactionHash:', receipt.transactionHash);
        return receipt.transactionHash;
    } catch (error) {
        console.error('swapExactTokensForETH_error:', error);
    }
};

// export const storeTransactionDetails = async (amountIn, TokenAContractAddress, TokenBContractAddress, account, amountValueOutMin, txhash) => {
//     const currentChainId = await getCurrentChainId();
//     const symbolA = await getTokenSymbol(TokenAContractAddress);
//     const symbolB = await getTokenSymbol(TokenBContractAddress);
//     const pairAddress = await getTokenPairAddress(TokenAContractAddress, TokenBContractAddress);
//     let USDT = 'USDT';
//     const symbolA_USDT = symbolA + USDT;
//     const amountInUSD = Number(symbolA === 'USDT' ? 1 : await fetchLivePrice(symbolA_USDT)) * amountIn;
//     // const amountValueOutMin = await getValueOutTokenAtoTokenB(amountIn, TokenAContractAddress, TokenBContractAddress);
//     const amountOutUSD = Number(symbolB === 'USDT' ? 1 : await fetchLivePrice(symbolB + USDT)) * amountValueOutMin;
//     try {
//         await fetch(APISTORETRANSACTIONS, {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify({
//                 swaptokenAfortokenB: `swap ${symbolA} for ${symbolB}`,
//                 amountAUSD: amountInUSD,
//                 amounttokenA: `${amountIn} ${symbolA}`,
//                 amounttokenB: `${amountValueOutMin} ${symbolB}`,
//                 userwallet: account,
//                 chainid: currentChainId,
//                 tokenPairAddress: pairAddress,
//                 txHash: txhash,
//                 amountBUSDout: amountOutUSD,
//                 symbolIn: symbolA,
//                 symbolOut: symbolB,
//                 tokenAddressA: TokenAContractAddress,
//                 tokenAddressB: TokenBContractAddress
//             })
//         });
//     } catch (error) {
//         console.error('Error storing transaction details:', error);
//     }
// }
export const storeTransactionDetails = async (amountIn, TokenAContractAddress, TokenBContractAddress, account, amountValueOutMin, txhash) => {
    try {
        const currentChainId = await getCurrentChainId();
        const [symbolA, symbolB] = await Promise.all([
            getTokenSymbol(TokenAContractAddress),
            getTokenSymbol(TokenBContractAddress),
        ]);
        const pairAddress = await getTokenPairAddress(TokenAContractAddress, TokenBContractAddress);
        const USDT = 'USDT';
        const symbolA_USDT = `${symbolA}${USDT}`;
        const amountInUSD = Number(symbolA === 'USDT' ? 1 : await fetchLivePrice(symbolA_USDT)) * amountIn;
        const amountOutUSD = Number(symbolB === 'USDT' ? 1 : await fetchLivePrice(`${symbolB}${USDT}`)) * amountValueOutMin;
        const transactionDetails = {
            swaptokenAfortokenB: `swap ${symbolA} for ${symbolB}`,
            amountAUSD: amountInUSD,
            amounttokenA: `${amountIn} ${symbolA}`,
            amounttokenB: `${amountValueOutMin} ${symbolB}`,
            userwallet: account,
            chainid: currentChainId,
            tokenPairAddress: pairAddress,
            txHash: txhash,
            amountBUSDout: amountOutUSD,
            symbolIn: symbolA,
            symbolOut: symbolB,
            tokenAddressA: TokenAContractAddress,
            tokenAddressB: TokenBContractAddress,
        };
        await fetch(APISTORETRANSACTIONS, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(transactionDetails),
        });
        console.log("Transaction details stored successfully.");
        
    } catch (error) {
        console.error('Error storing transaction details:', error);
    }
};

// export const storeChainTokenTableListDetails = async () => {
//     try {
//         const chainId = await getCurrentChainId();
//         const resChainTokenTableListDetails = await chainTokenTableListDetails();
//         console.log("dataToStoreChainTokenTableListDetails", resChainTokenTableListDetails);
//         for (let i = 0; i < resChainTokenTableListDetails.length; i++) {
//             const { Token, Name, Symbol, Price, FDV, TokenOneDayChange, TokenOneDayVol } = resChainTokenTableListDetails[i];
//             const requestBody = {
//                 chainId: chainId.toString(),
//                 tokenAddress: Token.toString(),
//                 name: Name.toString(),
//                 symbol: Symbol.toString(),
//                 price: Price.toString(),
//                 tvl: FDV.toString(),
//                 oneDayPriceChange: TokenOneDayChange.toString(),
//                 oneDayVol: TokenOneDayVol.toString(),
//             };
//             try {
//                 const response = await fetch(APIUPDATETOKENDATA, {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify(requestBody),
//                 });
//                 if (response.ok) {
//                     console.log('storeChainTokenTableListDetailsSuccessfully');
//                 } else {
//                     console.error('storeChainTokenTableListDetailsStatusError:', response.status);
//                 }
//             } catch (error) {
//                 console.error('storeChainTokenTableListDetailsError:', error);
//             }
//         }
//     } catch (error) {
//         console.error('storeChainTokenTableListDetailsError:', error);
//     }
// }
export const storeChainTokenTableListDetails = async () => {
    try {
        const chainId = await getCurrentChainId();
        const resChainTokenTableListDetails = await chainTokenTableListDetails();
        console.log("dataToStoreChainTokenTableListDetails", resChainTokenTableListDetails);

        const promises = resChainTokenTableListDetails.map(async (tokenDetails) => {
            const { Token, Name, Symbol, Price, FDV, TokenOneDayChange, TokenOneDayVol } = tokenDetails;
            const requestBody = {
                chainId: chainId.toString(),
                tokenAddress: Token.toString(),
                name: Name.toString(),
                symbol: Symbol.toString(),
                price: Price.toString(),
                tvl: FDV.toString(),
                oneDayPriceChange: TokenOneDayChange.toString(),
                oneDayVol: TokenOneDayVol.toString(),
            };

            try {
                const response = await fetch(APIUPDATETOKENDATA, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                });
                
                if (response.ok) {
                    console.log('storeChainTokenTableListDetailsSuccessfully:', response);
                } else {
                    console.error('storeChainTokenTableListDetailsStatusError:', response.status);
                }
            } catch (error) {
                console.error('storeChainTokenTableListDetailsError:', error);
            }
        });
        await Promise.all(promises);
        
    } catch (error) {
        console.error('storeChainTokenTableListDetailsError:', error);
    }
};


// export const storeChainPairTableListDetails = async () => {
//     try {
//         const chainId = await getCurrentChainId();
//         const reschainPairTableListDetails = await chainPairTableListDetails();
//         console.log("datatostorechainPairTableListDetails", reschainPairTableListDetails);
//         for (let i = 0; i < reschainPairTableListDetails.length; i++) {
//             const { pairAddress, pairSymbol, pairTransactions, pairTVL, pairOneDayVolume, pairSevenDayVolume } = reschainPairTableListDetails[i];
//             const requestBody = {
//                 chainId: chainId.toString(),
//                 pairAddress: pairAddress.toString(),
//                 pairName: pairSymbol.toString(),
//                 transactions: pairTransactions.toString(),
//                 oneDayVol: pairOneDayVolume.toString(),
//                 sevenDayVol: pairSevenDayVolume.toString(),
//                 TVL: pairTVL.toString(),
//             };
//             try {
//                 const response = await fetch(APIUPDATEPOOLDATA, {
//                     method: 'POST',
//                     headers: {
//                         'Content-Type': 'application/json',
//                     },
//                     body: JSON.stringify(requestBody),
//                 });
//                 if (response.ok) {
//                     console.log('storeChainPairTableListDetailsSuccessfully');
//                 } else {
//                     console.error('storeChainPairTableListDetailsStatusError:', response.status);
//                 }
//             } catch (error) {
//                 console.error('storeChainPairTableListDetailsError:', error);
//             }
//         }
//     } catch (error) {
//         console.error('storeChainPairTableListDetailsError:', error);
//     }
// }

export const storeChainPairTableListDetails = async () => {
    try {
        const chainId = await getCurrentChainId();
        const resChainPairTableListDetails = await chainPairTableListDetails();
        console.log("dataToStoreChainPairTableListDetails", resChainPairTableListDetails);
        const promises = resChainPairTableListDetails.map(async (pairDetails) => {
            const { pairAddress, pairSymbol, pairTransactions, pairTVL, pairOneDayVolume, pairSevenDayVolume } = pairDetails;
            const requestBody = {
                chainId: chainId.toString(),
                pairAddress: pairAddress.toString(),
                pairName: pairSymbol.toString(),
                transactions: pairTransactions.toString(),
                oneDayVol: pairOneDayVolume.toString(),
                sevenDayVol: pairSevenDayVolume.toString(),
                TVL: pairTVL.toString(),
            };
            try {
                const response = await fetch(APIUPDATEPOOLDATA, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                });

                if (response.ok) {
                    console.log('storeChainPairTableListDetailsSuccessfully:', requestBody);
                } else {
                    console.error('storeChainPairTableListDetailsStatusError:', response.status);
                }
            } catch (error) {
                console.error('storeChainPairTableListDetailsError:', error);
            }
        });
        await Promise.all(promises);

    } catch (error) {
        console.error('storeChainPairTableListDetailsError:', error);
    }
};
// storeChainPairTableListDetails();

// const calculateAmountOutMin = async (amountIn, TokenAContractAddress, TokenBContractAddress, slippageTolerance) => {
//     try {
//         const provider = await getCurrentProvider();
//         const web3 = new Web3(provider);
//         const currentChainId = await getCurrentChainId();
//         const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
//         let routerAddress;
//         if (routerAddressObject) {
//             routerAddress = routerAddressObject[currentChainId].RouterAddress;
//         } else {
//             console.log("Router address not found for current chain ID");
//         }
//         const contract = new web3.eth.Contract(abiRouter, routerAddress);
//         const amountInWei = await getFinalAmountWei(amountIn, TokenAContractAddress);
//         const path = [
//             TokenAContractAddress,
//             TokenBContractAddress
//         ];
//         console.log("calculateAmountOutMinPath", path)
//         const amountsOut = await contract.methods.getAmountsOut(amountInWei, path).call();
//         console.log("calculateAmountOutMinamountsOut", amountsOut)
//         const amountsActualOut = amountsOut[1].toString();
//         console.log("calculateAmountOutMinamountsActualOut", amountsActualOut, "slippategeTolerance", slippageTolerance)
//         const amountSlippageOutMin = Math.floor(amountsActualOut * (1 - (slippageTolerance / 100))); // Adjusted by slippage tolerance
//         console.log("calculateAmountOutMinamountSlippageOutMin", amountSlippageOutMin);
//         const isSlippage = amountSlippageOutMin < amountsActualOut;
//         console.log("calculateAmountOutMinisSlippage", isSlippage);
//         const slippageArrayDetails = [amountSlippageOutMin, isSlippage];
//         console.log("calculateAmountOutMinslippageArrayDetails", slippageArrayDetails);
//         return slippageArrayDetails;
//     } catch (error) {
//         console.error('calculateAmountOutMin_error:', error);
//         return [0, false];
//     }
// };

const calculateAmountOutMin = async (amountIn, TokenAContractAddress, TokenBContractAddress, slippageTolerance) => {
    try {
        const provider = await getCurrentProvider();
        const signer = provider.getSigner(); // Get the signer from the provider
        const currentChainId = await getCurrentChainId();
        const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
        const routerAddress = routerAddressObject ? routerAddressObject[currentChainId].RouterAddress : null;
        if (!routerAddress) {
            console.error("Router address not found for current chain ID");
            return [0, false]; // Return default values if router address is not found
        }
        const contract = new ethers.Contract(routerAddress, abiRouter, signer);
        const amountInWei = await getFinalAmountWei(amountIn, TokenAContractAddress); // Ensure this returns a BigNumber if necessary
        const path = [TokenAContractAddress, TokenBContractAddress];
        console.log("calculateAmountOutMinPath", path);
        const amountsOut = await contract.getAmountsOut(amountInWei, path); // Using ethers.js method
        const amountsActualOut = amountsOut[1].toString();
        console.log("calculateAmountOutMinamountsOut", amountsOut);
        console.log("calculateAmountOutMinamountsActualOut", amountsActualOut, "slippageTolerance", slippageTolerance);
        const amountSlippageOutMin = Math.floor(amountsActualOut * (1 - (slippageTolerance / 100)));
        console.log("calculateAmountOutMinamountSlippageOutMin", amountSlippageOutMin);
        const isSlippage = amountSlippageOutMin < amountsActualOut;
        console.log("calculateAmountOutMinisSlippage", isSlippage);
        const slippageArrayDetails = [amountSlippageOutMin, isSlippage];
        console.log("calculateAmountOutMinslippageArrayDetails", slippageArrayDetails);
        return slippageArrayDetails;
    } catch (error) {
        console.error('calculateAmountOutMin_error:', error);
        return [0, false]; // Return default values in case of error
    }
};


// export const getAllowanceTokentoRouter = async (tokenContractAddress) => {
//     console.log("getAllowanceTokentoRouter")
//     try {
//         let provider = await getCurrentProvider();
//         if (provider) {
//             const web3 = new Web3(provider);
//             const account = await getCurrentAccount();
//             const contracts = new web3.eth.Contract(abiERC20Token, tokenContractAddress);
//             console.log("account", account);
//             console.log("tokenContractAddress", tokenContractAddress);
//             const currentChainId = await getCurrentChainId();
//             const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
//             let routerAddress;
//             if (routerAddressObject) {
//                 routerAddress = routerAddressObject[currentChainId].RouterAddress;
//             } else {
//                 console.log("Router address not found for current chain ID");
//             }
//             const response = await contracts.methods.allowance(account, routerAddress).call();
//             console.log("responseAllowance", response);
//             const tokenDecimals = await getTokenDecimals(tokenContractAddress);
//             const allowance = Number(response.toString() / (10 ** tokenDecimals.toString()));
//             console.log(`allowanceToken_${tokenContractAddress}_toRouter_${routerAddress}:`, allowance);
//             return allowance;
//         }
//     } catch (error) {
//         console.error("AllowanceTokentoRouterERROR", error);
//     }
// };

export const getAllowanceTokentoRouter = async (tokenContractAddress) => {
    console.log("getAllowanceTokentoRouter");
    try {
        const provider = await getCurrentProvider();
        if (!provider) {
            console.error("Provider is not available.");
            return 0; // Return 0 if provider is not found
        }

        const signer = provider.getSigner(); // Get the signer from the provider
        const contract = new ethers.Contract(tokenContractAddress, abiERC20Token, signer);
        const account = await getCurrentAccount(); // Make sure this returns the user's account address
        console.log("account", account);
        console.log("tokenContractAddress", tokenContractAddress);

        const currentChainId = await getCurrentChainId();
        const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
        const routerAddress = routerAddressObject ? routerAddressObject[currentChainId].RouterAddress : null;

        if (!routerAddress) {
            console.error("Router address not found for current chain ID");
            return 0; // Return 0 if router address is not found
        }

        const response = await contract.allowance(account, routerAddress); // Use ethers.js method to get allowance
        console.log("responseAllowance", response);

        const tokenDecimals = await getTokenDecimals(tokenContractAddress);
        const allowance = parseFloat(ethers.utils.formatUnits(response, tokenDecimals)); // Convert response to a human-readable number
        console.log(`allowanceToken_${tokenContractAddress}_toRouter_${routerAddress}:`, allowance);

        return allowance;
    } catch (error) {
        console.error("AllowanceTokentoRouterERROR", error);
        return 0; // Return 0 in case of an error
    }
};


// export const getPairforTokenATokenB = async (TokenAContractAddress, TokenBContractAddress) => {
//     console.log("getPairforTokenATokenB")
//     try {
//         let provider = await getCurrentProvider();
//         if (provider) {
//             const web3 = new Web3(provider);
//             const account = await getCurrentAccount();
//             let chainId = await getCurrentChainId();
//             const factoryAddressObject = chainAddresses.find(chain => chain[chainId]);
//             let factoryAddress;
//             if (factoryAddressObject) {
//                 factoryAddress = factoryAddressObject[chainId].FactoryAddress;
//             } else {
//                 console.log("Factory address not found for current chain ID");
//             }
//             const contracts = new web3.eth.Contract(abiFactory, factoryAddress);
//             console.log("account", account);
//             console.log("factoryAddress", factoryAddress);
//             const tokenAnBPair = await contracts.methods.getPair(TokenAContractAddress, TokenBContractAddress).call();
//             const pairAddress = tokenAnBPair !== undefined ? tokenAnBPair : "0x0000000000000000000000000000000000000000";
//             console.log("pairAddressgetPairforTokenATokenB", pairAddress);
//             return pairAddress;
//         }
//     } catch (error) {
//         console.error("getPairforTokenATokenBERROR", error);
//     }
// }
export const getPairforTokenATokenB = async (TokenAContractAddress, TokenBContractAddress) => {
    console.log("getPairforTokenATokenB");

    try {
        const provider = await getCurrentProvider();
        if (!provider) {
            console.error("Provider is not available.");
            return "0x0000000000000000000000000000000000000000"; // Return default address if provider is not found
        }

        const signer = provider.getSigner(); // Get the signer from the provider
        const account = await getCurrentAccount(); // Ensure this function returns the user's account address
        const chainId = await getCurrentChainId();
        const factoryAddressObject = chainAddresses.find(chain => chain[chainId]);

        if (!factoryAddressObject) {
            console.error("Factory address not found for current chain ID");
            return "0x0000000000000000000000000000000000000000"; // Return default address if factory address is not found
        }

        const factoryAddress = factoryAddressObject[chainId].FactoryAddress;
        const contract = new ethers.Contract(factoryAddress, abiFactory, signer);
        console.log("account", account);
        console.log("factoryAddress", factoryAddress);

        const pairAddress = await contract.getPair(TokenAContractAddress, TokenBContractAddress); // Use ethers.js method to get the pair address
        console.log("pairAddress getPairforTokenATokenB", pairAddress);

        return pairAddress || "0x0000000000000000000000000000000000000000"; // Default address if pair not found
    } catch (error) {
        console.error("getPairforTokenATokenBERROR", error);
        return "0x0000000000000000000000000000000000000000"; // Return default address in case of an error
    }
};



// export const getCurrentChainIdTokenAddress = async (tokenSymbol) => {
//     console.log("getCurrentChainIdTokenAddressSymbol", tokenSymbol)
//     let chainId = await getCurrentChainId();
//     let tokenAddress = null;
//     const chain = chainAddresses.find(chain => chain.hasOwnProperty(chainId));
//     console.log("chain_getCurrentChainIdTokenAddress", chain)
//     if (chain) {
//         const token = chain[chainId].tokens.find(token => token.ticker === tokenSymbol);
//         if (token) {
//             tokenAddress = token.address || token.nativeAddress;
//             console.log(`getCurrentChainIdTokenAddress on ${chainId}-${tokenSymbol}: `, tokenAddress);
//         } else {
//             console.log(`Token ${tokenSymbol} not found for chain ID ${chainId}`);
//         }
//     } else {
//         console.log(`Chain ID ${chainId} not found`);
//     }
//     return tokenAddress;
// };
export const getCurrentChainIdTokenAddress = async (tokenSymbol) => {
    console.log("getCurrentChainIdTokenAddressSymbol", tokenSymbol);
    try {
        const chainId = await getCurrentChainId();
        let tokenAddress = null;
        const chain = chainAddresses.find(chain => chain.hasOwnProperty(chainId));
        console.log("chain_getCurrentChainIdTokenAddress", chain);

        if (chain) {
            const token = chain[chainId].tokens.find(token => token.ticker === tokenSymbol);
            if (token) {
                tokenAddress = token.address || token.nativeAddress;
                console.log(`getCurrentChainIdTokenAddress on ${chainId}-${tokenSymbol}: `, tokenAddress);
            } else {
                console.log(`Token ${tokenSymbol} not found for chain ID ${chainId}`);
            }
        } else {
            console.log(`Chain ID ${chainId} not found`);
        }
        return tokenAddress;
    } catch (error) {
        console.error("getCurrentChainIdTokenAddressError:", error);
        return null; // Return null in case of an error
    }
};

// export const getCurrentChainIdPopularTokens = async () => {
//     try {
//         let chainId = await getCurrentChainId();
//         const chain = chainAddresses.find(chain => chain.hasOwnProperty(chainId));
//         if (chain) {
//             const tokens = chain[chainId].tokens;
//             const popularTokens = tokens.filter(token => token.popularToken && token.popularToken === true).map(token => ({
//                 imgSrc: token.img,
//                 name: token.ticker,
//                 address: token.address
//             }));
//             console.log("getCurrentChainIdPopularTokens:", popularTokens);
//             return popularTokens;
//         } else {
//             console.error(`Chain ID ${chainId} not found`);
//             return [];
//         }
//     } catch (error) {
//         console.error("Error fetching popular tokens:", error);
//         return [];
//     }
// };
export const getCurrentChainIdPopularTokens = async () => {
    try {
        const chainId = await getCurrentChainId();
        console.log("Got chain ID:", chainId);
        const chain = chainAddresses.find(chain => chain.hasOwnProperty(chainId));
        console.log("Got chain:", chain);
        if (chain) {
            const tokens = chain[chainId].tokens;
            console.log("Got tokens:", tokens);
            const popularTokens = tokens
                .filter(token => token.popularToken === true)
                .map(token => ({
                    imgSrc: token.img,
                    name: token.ticker,
                    address: token.address
                }));
            console.log("getCurrentChainIdPopularTokens:", popularTokens);
            return popularTokens;
        } else {
            console.error(`Chain ID ${chainId} not found`);
            return [];
        }
    } catch (error) {
        console.error("Error fetching popular tokens:", error);
        return [];
    }
};

// export const getCurrentChainIdAllTokens = async () => {
//     try {
//         let chainId = await getCurrentChainId();
//         console.log("chainId",chainId)
//         const chain = chainAddresses.find(chain => chain.hasOwnProperty(chainId));
//         if (chain) {
//             const tokens = chain[chainId].tokens;
//             const allTokens = tokens.map(token => ({
//                 ticker: token.ticker,
//                 img: token.img,
//                 name: token.name,
//                 address: token.address || token.nativeAddress
//             }));
//             console.log("getCurrentChainIdAllTokens:", allTokens);
//             return allTokens;
//         } else {
//             console.error(`Chain ID ${chainId} not found`);
//             return [];
//         }
//     } catch (error) {
//         console.error("Error fetching all tokens:", error);
//         return [];
//     }
// };
export const getCurrentChainIdAllTokens = async () => {
    try {
        const chainId = await getCurrentChainId();
        console.log("chainId", chainId);

        const chain = chainAddresses.find(chain => chain.hasOwnProperty(chainId));
        if (chain) {
            const tokens = chain[chainId].tokens;
            const allTokens = tokens.map(token => ({
                ticker: token.ticker,
                img: token.img,
                name: token.name,
                address: token.address || token.nativeAddress
            }));

            console.log("getCurrentChainIdAllTokens:", allTokens);
            return allTokens;
        } else {
            console.error(`Chain ID ${chainId} not found`);
            return [];
        }
    } catch (error) {
        console.error("Error fetching all tokens:", error);
        return [];
    }
};

// export const getCurrentChainIdNativeTokenAddress = async (nativeTokenAddress) => {
//     let chainId = await getCurrentChainId();
//     let tokenFound = false;
//     const chain = chainAddresses.find(chain => chain.hasOwnProperty(chainId));
//     console.log("chain_getCurrentChainIdNativeTokenAddress", chain)
//     if (chain) {
//         const token = chain[chainId].tokens.find(token => token.nativeAddress === nativeTokenAddress);
//         if (token) {
//             tokenFound = true;
//             console.log(`getCurrentChainIdNativeTokenAddress on ${chainId}-${nativeTokenAddress}: `, nativeTokenAddress);
//         } else {
//             console.log(`Token ${nativeTokenAddress} is not native for chain ID ${chainId}`);
//         }
//     } else {
//         console.log(`Chain ID ${chainId} not found`);
//     }
//     console.log("tokenFound", tokenFound)
//     return tokenFound;
// };

export const getCurrentChainIdNativeTokenAddress = async (nativeTokenAddress) => {
    try {
        const chainId = await getCurrentChainId(); // Get current chain ID
        let tokenFound = false;
        const chain = chainAddresses.find(chain => chain.hasOwnProperty(chainId));
        console.log("chain_getCurrentChainIdNativeTokenAddress", chain);

        if (chain) {
            const token = chain[chainId].tokens.find(token => token.nativeAddress === nativeTokenAddress);
            if (token) {
                tokenFound = true;
                console.log(`getCurrentChainIdNativeTokenAddress on ${chainId}-${nativeTokenAddress}: `, nativeTokenAddress);
            } else {
                console.log(`Token ${nativeTokenAddress} is not native for chain ID ${chainId}`);
            }
        } else {
            console.log(`Chain ID ${chainId} not found`);
        }

        console.log("tokenFound", tokenFound);
        return tokenFound;
    } catch (error) {
        console.error("Error in getCurrentChainIdNativeTokenAddress:", error);
        return false; // Return false in case of error
    }
};

// export const getDefaultNativeTokenAddress = async () => {
//     try {
//         const chainId = await getCurrentChainId();
//         const chain = chainAddresses.find((chain) => chain.hasOwnProperty(chainId));
//         if (chain) {
//             const nativeToken = chain[chainId].tokens.find(token => token.nativeAddress);
//             if (nativeToken) {
//                 const { nativeAddress, ticker, img } = nativeToken;
//                 console.log(`getDefaultNativeTokenAddress on ${chainId}: `, nativeAddress);
//                 return {
//                     nativeTokenAddress: nativeAddress,
//                     nativeTokenTicker: ticker,
//                     nativeTokenImg: img,
//                 };
//             } else {
//                 console.error(`No native token found for Chain ID ${chainId}`);
//                 return null;
//             }
//         } else {
//             console.error(`Chain ID ${chainId} not found in chainAddresses`);
//             return null;
//         }
//     } catch (error) {
//         console.error('Error getting default native token address:', error);
//         return null;
//     }
// };

export const getDefaultNativeTokenAddress = async () => {
    try {
        const chainId = await getCurrentChainId(); // Get the current chain ID
        const chain = chainAddresses.find((chain) => chain.hasOwnProperty(chainId));

        if (chain) {
            const nativeToken = chain[chainId].tokens.find(token => token.nativeAddress);
            if (nativeToken) {
                const { nativeAddress, ticker, img } = nativeToken;
                console.log(`getDefaultNativeTokenAddress on ${chainId}: `, nativeAddress);
                return {
                    nativeTokenAddress: nativeAddress,
                    nativeTokenTicker: ticker,
                    nativeTokenImg: img,
                };
            } else {
                console.error(`No native token found for Chain ID ${chainId}`);
                return null;
            }
        } else {
            console.error(`Chain ID ${chainId} not found in chainAddresses`);
            return null;
        }
    } catch (error) {
        console.error('Error getting default native token address:', error);
        return null; // Return null in case of error
    }
};

// export const getCurrentChainIdRouterAddress = async (chainId) => {
//     console.log("getCurrentChainIdRouterAddress");
//     const chainDetails = chainAddresses.find(chain => chain.hasOwnProperty(chainId));
//     if (chainDetails) {
//         const routerAddress = chainDetails[chainId]?.RouterAddress;
//         console.log(`getCurrentChainIdRouterAddress on ${chainId}: `, routerAddress);
//         return routerAddress;
//     } else {
//         console.log(`Chain ID ${chainId} not found`);
//         return null;
//     }
// };
export const getCurrentChainIdRouterAddress = async (chainId) => {
    console.log("getCurrentChainIdRouterAddress");
    const chainDetails = chainAddresses.find(chain => chain.hasOwnProperty(chainId));
    if (chainDetails) {
        const routerAddress = chainDetails[chainId]?.RouterAddress;
        console.log(`getCurrentChainIdRouterAddress on ${chainId}: `, routerAddress);
        return routerAddress; // Return the router address
    } else {
        console.log(`Chain ID ${chainId} not found`);
        return null; // Return null if the chain ID is not found
    }
};

// export const getTokenBalance = async (tokenContractAddress) => {
//     console.log("getTokenBalance", tokenContractAddress);
//     try {
//         let provider = await getCurrentProvider();
//         if (provider) {
//             const web3 = new Web3(provider);
//             const account = await getCurrentAccount();
//             const contracts = new web3.eth.Contract(abiERC20Token, tokenContractAddress);
//             console.log("contracts_methods", contracts.methods);
//             console.log("account", account);
//             const response = await contracts.methods.balanceOf(account).call();
//             const balance = await getFinalAmountEth(response, tokenContractAddress);
//             console.log(`TokenBalance of ${tokenContractAddress}`, balance);
//             return balance;
//         }
//     } catch (error) {
//         console.error("balance_error", error);
//     }
// };

export const getTokenBalance = async (tokenContractAddress) => {
    console.log("getTokenBalance", tokenContractAddress);
    try {
        const provider = await getCurrentProvider(); 
        const account = await getCurrentAccount(); 

        if (provider && account) {
            const signer = provider.getSigner(account); 
            const contract = new ethers.Contract(tokenContractAddress, abiERC20Token, signer);

            console.log("account", account);
            const response = await contract.balanceOf(account); 
            const balance = await getFinalAmountEth(response, tokenContractAddress);
            console.log(`TokenBalance of ${tokenContractAddress}:`, balance);

            return balance;
        } else {
            console.error("Provider or account not found");
        }
    } catch (error) {
        console.error("balance_error:", error);
        return 0;
    }
};


// export const getPairReservedTokenBalance = async (TokenAContractAddress, TokenBContractAddress, TokenPairReservedContractAddress) => {
//     console.log("getPairReservedTokenBalance");
//     try {
//         let provider = await getCurrentProvider();
//         if (provider) {
//             const web3 = new Web3(provider);
//             const pairAddress = TokenAContractAddress && TokenBContractAddress ? await getTokenPairAddress(TokenAContractAddress, TokenBContractAddress) : "0x0000000000000000000000000000000000000000";
//             console.log("getPairReservedTokenBalance_pairAddress", pairAddress);
//             if (pairAddress == "0x0000000000000000000000000000000000000000") {
//                 console.log("Pair address is invalid. Reason: Tokens not found.");
//                 return 0;
//             }
//             const contracts = new web3.eth.Contract(abiERC20Token, TokenPairReservedContractAddress);
//             const response = await contracts.methods.balanceOf(pairAddress).call();
//             const balance = Number(web3.utils.fromWei(response, 'ether'));
//             console.log(`PairReservedTokenBalance of ${TokenPairReservedContractAddress}`, balance);
//             return balance;
//         }
//     } catch (error) {
//         console.error("getPairReservedTokenBalanceERROR", error);
//     }
// };

export const getPairReservedTokenBalance = async (TokenAContractAddress, TokenBContractAddress, TokenPairReservedContractAddress) => {
    console.log("getPairReservedTokenBalance");
    try {
        const provider = await getCurrentProvider(); 
        if (provider) {
            const signer = provider.getSigner(); 
            const pairAddress = TokenAContractAddress && TokenBContractAddress 
                ? await getTokenPairAddress(TokenAContractAddress, TokenBContractAddress) 
                : "0x0000000000000000000000000000000000000000";
                
            console.log("getPairReservedTokenBalance_pairAddress", pairAddress);
            if (pairAddress === "0x0000000000000000000000000000000000000000") {
                console.log("Pair address is invalid. Reason: Tokens not found.");
                return 0;
            }
            
            const contract = new ethers.Contract(TokenPairReservedContractAddress, abiERC20Token, signer); 
            const response = await contract.balanceOf(pairAddress); 
            const balance = ethers.utils.formatUnits(response, 18); 
            
            console.log(`PairReservedTokenBalance of ${TokenPairReservedContractAddress}:`, balance);
            return Number(balance); 
        }
    } catch (error) {
        console.error("getPairReservedTokenBalanceERROR:", error);
    }
};

// export const getValueOutTokenAtoTokenB = async (TokenA, TokenAContractAddress, TokenBContractAddress) => {
//     if (TokenA > 0) {
//         console.log("TokenA", TokenAContractAddress, TokenBContractAddress);
//         let provider = await getCurrentProvider();
//         if (provider) {
//             const web3 = new Web3(provider);
//             const currentChainId = await getCurrentChainId();
//             const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
//             let routerAddress;
//             if (routerAddressObject) {
//                 routerAddress = routerAddressObject[currentChainId].RouterAddress;
//             } else {
//                 console.log("Router address not found for current chain ID");
//             }
//             const contracts = new web3.eth.Contract(abiRouter, routerAddress);
//             console.log(contracts);
//             const path = [
//                 TokenAContractAddress,
//                 TokenBContractAddress
//             ];
//             console.log("path", path)
//             const _finalTokenAamountWei = await getFinalAmountWei(TokenA, TokenAContractAddress);
//             console.log("_finalTokenAamountWei_getAmountsOut", _finalTokenAamountWei)
//             const pairAddress = await getTokenPairAddress(TokenAContractAddress, TokenBContractAddress);
//             console.log("pairAddress_getValueOutTokenAtoTokenB", pairAddress)
//             if (pairAddress === "0x0000000000000000000000000000000000000000") {
//                 console.log("getValueOutTokenAtoTokenB_invalidPairaddress_Tokensnotfound.");
//                 return 0;
//             }
//             const contract = new web3.eth.Contract(abiPair, pairAddress);
//             const balance = await contract.methods.totalSupply().call();
//             const balanceAsNumber = balance.toString();
//             console.log("getPairTotalSupply:", balanceAsNumber);
//             if (balanceAsNumber == 0) {
//                 console.log("getValueOutTokenAtoTokenB_pairAddress_insufficientbalance", balanceAsNumber);
//                 return 0;
//             }
//             const response = await contracts.methods.getAmountsOut(_finalTokenAamountWei, path).call();
//             console.log("response_getValueOutTokenAtoTokenB", response)
//             console.log("Contract response:", response);
//             const res = response[1];
//             let resultAmount = await getFinalAmountEth(res, TokenBContractAddress);
//             console.log("resultAmountTokenAtoTokenB", resultAmount)
//             let resultValue = parseFloat(resultAmount).toFixed(8)
//             console.log("resultValueTokenAtoTokenB", resultValue)
//             return resultValue;
//         } else {
//             console.log("enter valid amount")
//         }
//     } else {
//         return 0;
//     }
// };

export const getValueOutTokenAtoTokenB = async (TokenA, TokenAContractAddress, TokenBContractAddress) => {
    if (TokenA > 0) {
        console.log("TokenA", TokenAContractAddress, TokenBContractAddress);
        try {
            const provider = await getCurrentProvider();
            if (provider) {
                const signer = provider.getSigner();
                const currentChainId = await getCurrentChainId();
                const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
                let routerAddress;

                if (routerAddressObject) {
                    routerAddress = routerAddressObject[currentChainId].RouterAddress;
                } else {
                    console.log("Router address not found for current chain ID");
                    return 0;
                }

                const contracts = new ethers.Contract(routerAddress, abiRouter, signer);
                console.log(contracts);

                const path = [TokenAContractAddress, TokenBContractAddress];
                console.log("path", path);
                const _finalTokenAamountWei = await getFinalAmountWei(TokenA, TokenAContractAddress);
                console.log("_finalTokenAamountWei_getAmountsOut", _finalTokenAamountWei);

                const pairAddress = await getTokenPairAddress(TokenAContractAddress, TokenBContractAddress);
                console.log("pairAddress_getValueOutTokenAtoTokenB", pairAddress);

                if (pairAddress === "0x0000000000000000000000000000000000000000") {
                    console.log("getValueOutTokenAtoTokenB_invalidPairaddress_Tokensnotfound.");
                    return 0;
                }

                const pairContract = new ethers.Contract(pairAddress, abiPair, signer);
                const balance = await pairContract.totalSupply();
                const balanceAsNumber = balance.toString();
                console.log("getPairTotalSupply:", balanceAsNumber);

                if (balanceAsNumber === '0') {
                    console.log("getValueOutTokenAtoTokenB_pairAddress_insufficientbalance", balanceAsNumber);
                    return 0;
                }

                const response = await contracts.getAmountsOut(_finalTokenAamountWei, path);
                console.log("response_getValueOutTokenAtoTokenB", response);
                console.log("Contract response:", response);

                const res = response[1];
                let resultAmount = await getFinalAmountEth(res, TokenBContractAddress);
                console.log("resultAmountTokenAtoTokenB", resultAmount);
                let resultValue = parseFloat(resultAmount).toFixed(8);
                console.log("resultValueTokenAtoTokenB", resultValue);
                return resultValue;
            } else {
                console.log("Enter a valid amount");
                return 0;
            }
        } catch (error) {
            console.error("Error in getValueOutTokenAtoTokenB:", error);
            return 0;
        }
    } else {
        return 0;
    }
};

// export const getUserLiquidityDetails = async (TokenAContractAddress, TokenBContractAddress, TokenUserLiquidityAddress) => {
//     try {
//         let provider = await getCurrentProvider();
//         const web3 = new Web3(provider);
//         const account = await getCurrentAccount();
//         const currentChainId = await getCurrentChainId();
//         const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
//         let routerAddress;
//         if (routerAddressObject) {
//             routerAddress = routerAddressObject[currentChainId].RouterAddress;
//         } else {
//             console.log("Router address not found for current chain ID");
//         }
//         const contracts = new web3.eth.Contract(abiRouter, routerAddress);
//         console.log("Contracts:", contracts);
//         const path = [TokenAContractAddress, TokenBContractAddress];
//         console.log("Path:", path);
//         const pairAddress = await getTokenPairAddress(TokenAContractAddress, TokenBContractAddress);
//         console.log("Pair_Address_getUserLiquidityDetails):", pairAddress);
//         if (pairAddress === "0x0000000000000000000000000000000000000000") {
//             console.log("Invalid pair address: Tokens not found.");
//             return 0;
//         }
//         const pairContract = new web3.eth.Contract(abiPair, pairAddress);
//         console.log("pairContract:", pairContract);
//         const [token0Address, token1Address] = await Promise.all([pairContract.methods.token0().call(), pairContract.methods.token1().call()]);
//         console.log("pairContracttoken0Address:", token0Address, "token1Address:", token1Address);
//         const totalSupply = await pairContract.methods.totalSupply().call();
//         const individualLiquidity = await pairContract.methods.balanceOf(account).call();
//         console.log("individualLiquidity_totalSupply", individualLiquidity, totalSupply);
//         const reserve0_reserve1 = await pairContract.methods.getReserves().call();
//         console.log("reserve0_reserve1", reserve0_reserve1);
//         const reserve0 = Number(reserve0_reserve1[0]);
//         const reserve1 = Number(reserve0_reserve1[1]);
//         console.log("reserve0_reserve1", reserve0, reserve1);
//         const shareOfPool = (individualLiquidity.toString()) / (totalSupply.toString());
//         console.log("shareOfPool", shareOfPool);
//         const value = 1;
//         const demonimator = web3.utils.toWei(value.toString(), 'ether');
//         const token0Amount = (reserve0 * shareOfPool) / demonimator;
//         const token1Amount = (reserve1 * shareOfPool) / demonimator;
//         console.log("token0Amount_token1Amount", token0Amount, token1Amount);
//         const liquidityDetails = {
//             token0: {
//                 address: token0Address,
//                 amount: token0Amount
//             },
//             token1: {
//                 address: token1Address,
//                 amount: token1Amount
//             }
//         };
//         console.log("LiquidityDetails:", liquidityDetails);
//         if (TokenUserLiquidityAddress.toLowerCase() === token0Address.toLowerCase()) {
//             console.log("TokenUserLiquidityAtoBAddress:", liquidityDetails.token0.address);
//             console.log("TokenUserLiquidityAtoBAddressAmount:", liquidityDetails.token0.amount.toFixed(4).toString());
//             return liquidityDetails.token0.amount.toFixed(4).toString();
//         } else if (TokenUserLiquidityAddress.toLowerCase() === token1Address.toLowerCase()) {
//             console.log("TokenUserLiquidityBtoAAddressAmount:", liquidityDetails.token1.amount.toFixed(4).toString());
//             return liquidityDetails.token1.amount.toFixed(4).toString();
//         } else {
//             console.error("Invalid TokenUserLiquidityAddress:", TokenUserLiquidityAddress);
//             return "0";
//         }
//     } catch (error) {
//         console.error("Error getting user liquidity details:", error);
//     }
// };

// const getAddressApplicableForTaxed = async (tokenAAddress, tokenBAddress) => {
//     try {
//         let provider = await getCurrentProvider();
//         const web3 = new Web3(provider);
//         const currentChainId = await getCurrentChainId();
//         const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
//         let routerAddress;
//         if (routerAddressObject) {
//             routerAddress = routerAddressObject[currentChainId].RouterAddress;
//         } else {
//             console.error("Router address not found for current chain ID");
//         }
//         const contract = new web3.eth.Contract(abiRouter, routerAddress);
//         const result = await contract.methods
//             .getAddressApplicableForTax(tokenAAddress, tokenBAddress)
//             .call();
//         console.log("getAddressApplicableForTaxed_result:", result);
//         return result[0];
//     } catch (error) {
//         console.error('getAddressApplicableForTaxed_error:', error);
//     }
// };

export const getUserLiquidityDetails = async (TokenAContractAddress, TokenBContractAddress, TokenUserLiquidityAddress) => {
    try {
        const provider = await getCurrentProvider();
        const signer = provider.getSigner();
        const account = await getCurrentAccount();
        const currentChainId = await getCurrentChainId();
        const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
        let routerAddress;

        if (routerAddressObject) {
            routerAddress = routerAddressObject[currentChainId].RouterAddress;
        } else {
            console.log("Router address not found for current chain ID");
            return 0;
        }

        const contracts = new ethers.Contract(routerAddress, abiRouter, signer);
        console.log("Contracts:", contracts);

        const path = [TokenAContractAddress, TokenBContractAddress];
        console.log("Path:", path);

        const pairAddress = await getTokenPairAddress(TokenAContractAddress, TokenBContractAddress);
        console.log("Pair_Address_getUserLiquidityDetails:", pairAddress);

        if (pairAddress === "0x0000000000000000000000000000000000000000") {
            console.log("Invalid pair address: Tokens not found.");
            return 0;
        }

        const pairContract = new ethers.Contract(pairAddress, abiPair, signer);
        console.log("pairContract:", pairContract);

        const [token0Address, token1Address] = await Promise.all([
            pairContract.token0(),
            pairContract.token1(),
        ]);
        console.log("pairContracttoken0Address:", token0Address, "token1Address:", token1Address);

        const totalSupply = await pairContract.totalSupply();
        const individualLiquidity = await pairContract.balanceOf(account);
        console.log("individualLiquidity_totalSupply", individualLiquidity.toString(), totalSupply.toString());

        const reserve0_reserve1 = await pairContract.getReserves();
        console.log("reserve0_reserve1", reserve0_reserve1);
        
        const reserve0 = Number(reserve0_reserve1[0]);
        const reserve1 = Number(reserve0_reserve1[1]);
        console.log("reserve0_reserve1", reserve0, reserve1);

        const shareOfPool = parseFloat(ethers.utils.formatUnits(individualLiquidity, 18)) / parseFloat(ethers.utils.formatUnits(totalSupply, 18));
        console.log("shareOfPool", shareOfPool);

        const value = 1;
        const demonimator = ethers.utils.parseUnits(value.toString(), 'ether');
        const token0Amount = (reserve0 * shareOfPool) / demonimator;
        const token1Amount = (reserve1 * shareOfPool) / demonimator;
        console.log("token0Amount_token1Amount", token0Amount, token1Amount);

        const liquidityDetails = {
            token0: {
                address: token0Address,
                amount: token0Amount
            },
            token1: {
                address: token1Address,
                amount: token1Amount
            }
        };

        console.log("LiquidityDetails:", liquidityDetails);

        if (TokenUserLiquidityAddress.toLowerCase() === token0Address.toLowerCase()) {
            console.log("TokenUserLiquidityAtoBAddress:", liquidityDetails.token0.address);
            console.log("TokenUserLiquidityAtoBAddressAmount:", liquidityDetails.token0.amount.toFixed(4).toString());
            return liquidityDetails.token0.amount.toFixed(4).toString();
        } else if (TokenUserLiquidityAddress.toLowerCase() === token1Address.toLowerCase()) {
            console.log("TokenUserLiquidityBtoAAddressAmount:", liquidityDetails.token1.amount.toFixed(4).toString());
            return liquidityDetails.token1.amount.toFixed(4).toString();
        } else {
            console.error("Invalid TokenUserLiquidityAddress:", TokenUserLiquidityAddress);
            return "0";
        }
    } catch (error) {
        console.error("Error getting user liquidity details:", error);
        return "0";
    }
};

// export const getAllStoredTransactions = async () => {
//     const currentChainId = await getCurrentChainId();
//     console.log('getcurrentChainIdAllStoredTransactions:', currentChainId);
//     try {
//         const response = await fetch(`${APIGETALLTRANSACTION}?chainid=${currentChainId}`);
//         const data = await response.json();
//         if (data.status) {
//             console.log('getAllStoredTransactionsData:', data.data);
//             return data.data;
//         } else {
//             console.error('getAllStoredTransactionsErrordata:', data.message);
//             return null;
//         }
//     } catch (error) {
//         console.error('getAllStoredTransactionsError:', error);
//         return null;
//     }
// }

export const getAllStoredTransactions = async () => {
    try {
        const provider = await getCurrentProvider();
        const { chainId } = await provider.getNetwork();
        console.log('getcurrentChainIdAllStoredTransactions:', chainId);
        const response = await fetch(`${APIGETALLTRANSACTION}?chainid=${chainId}`);
        const data = await response.json();
        if (data.status) {
            console.log('getAllStoredTransactionsData:', data.data);
            return data.data;
        } else {
            console.error('getAllStoredTransactionsErrordata:', data.message);
            return null;
        }
    } catch (error) {
        console.error('getAllStoredTransactionsError:', error);
        return null;
    }
};

// export const chainTokenTableListDetails = async () => {
//     let provider = await getCurrentProvider();
//     const web3 = new Web3(provider);
//     const chainId = await getCurrentChainId();
//     const factoryAddressObject = chainAddresses.find(chain => chain[chainId]);
//     if (!factoryAddressObject) {
//         console.log("Factory address not found for current chain ID");
//         return;
//     }
//     const factoryAddress = factoryAddressObject[chainId].FactoryAddress;
//     const contracts = new web3.eth.Contract(abiFactory, factoryAddress);
//     const allPairslength = await contracts.methods.allPairsLength().call();
//     const alltokentablelistdetails = {};
//     for (let i = 0; i < allPairslength; i++) {
//         const pairAddress = await contracts.methods.allPairs(i).call();
//         const pairContract = new web3.eth.Contract(abiPair, pairAddress);
//         const [token0Address, token1Address] = await Promise.all([
//             pairContract.methods.token0().call(),
//             pairContract.methods.token1().call()
//         ]);
//         const [token0Contract, token1Contract] = await Promise.all([
//             new web3.eth.Contract(abiERC20Token, token0Address),
//             new web3.eth.Contract(abiERC20Token, token1Address)
//         ]);
//         const [balance0, balance1, token0Name, token1Name, token0Symbol, token1Symbol] = await Promise.all([
//             token0Contract.methods.balanceOf(pairAddress).call(),
//             token1Contract.methods.balanceOf(pairAddress).call(),
//             token0Contract.methods.name().call(),
//             token1Contract.methods.name().call(),
//             token0Contract.methods.symbol().call(),
//             token1Contract.methods.symbol().call()
//         ]);
//         const tokenAddresses = [token0Address, token1Address];
//         for (let index = 0; index < tokenAddresses.length; index++) {
//             const tokenAddress = tokenAddresses[index];
//             const existingToken = alltokentablelistdetails[tokenAddress];
//             const tokenDecimals = await getTokenDecimals(tokenAddresses[index]);
//             const FDV = Number((index === 0 ? balance0 : balance1) / Math.pow(10, tokenDecimals.toString()));
//             const symbol = index === 0 ? token0Symbol : token1Symbol;
//             const symboltopToken_USDT = symbol + 'USDT';
//             const tokenSymbolRateUSD = Number(symbol === 'USDT' ? 1 : await fetchLivePrice(symboltopToken_USDT));
//             const rateUSD = isNaN(tokenSymbolRateUSD) ? 1 : tokenSymbolRateUSD;
//             const tokenDetailPrice = rateUSD;
//             const tokenDetailFDV = rateUSD * FDV;
//             const transactions = await getAllStoredTransactions();
//             const twentyFourHoursAgo = new Date().getTime() - (24 * 60 * 60 * 1000);
//             const fortyEightHoursAgo = new Date().getTime() - (48 * 60 * 60 * 1000);
//             let tokenOneDayVol = 0;
//             let tokenTwoDayVol = 0;
//             transactions.forEach(tx => {
//                 const txTime = new Date(tx.createdAt).getTime();
//                 if ((txTime >= twentyFourHoursAgo) && (tx.tokenAddressA === tokenAddress)) {
//                     tokenOneDayVol += Number(tx.amountAUSD);
//                 }
//                 if ((txTime >= fortyEightHoursAgo) && (txTime < twentyFourHoursAgo) && (tx.tokenAddressA === tokenAddress)) {
//                     tokenTwoDayVol += Number(tx.amountAUSD);
//                 }
//             });
//             const tokenOneDayVary = tokenTwoDayVol === 0 ? 0 : ((tokenOneDayVol - tokenTwoDayVol) / tokenTwoDayVol) * 100;
//             const tokenOneDayChange = tokenOneDayVary === 0 ? '0%' : (tokenOneDayVary > 0 ? `+${tokenOneDayVary}%` : `-${tokenOneDayVary}%`);
//             if (existingToken) {
//                 existingToken.FDV += tokenDetailFDV;
//                 existingToken.Price = tokenDetailPrice;
//                 existingToken.TokenOneDayVol = tokenOneDayVol;
//                 existingToken.TokenOneDayChange = tokenOneDayChange;
//             } else {
//                 alltokentablelistdetails[tokenAddress] = {
//                     Token: tokenAddress,
//                     Name: index === 0 ? token0Name : token1Name,
//                     Symbol: symbol,
//                     Price: tokenDetailPrice,
//                     FDV: tokenDetailFDV,
//                     TokenOneDayVol: tokenOneDayVol,
//                     TokenOneDayChange: tokenOneDayChange
//                 };
//             }
//         }
//     }
//     console.log("alltokentablelistdetails", Object.values(alltokentablelistdetails));
//     return Object.values(alltokentablelistdetails);
// }

export const chainTokenTableListDetails = async () => {
    try {
        const provider = await getCurrentProvider();
        if (!provider) {
            console.error("Provider is null or undefined. Ensure getCurrentProvider returns a valid provider.");
            return null;
        }
        const { chainId } = await provider.getNetwork();
        const factoryAddressObject = chainAddresses.find(chain => chain[chainId]);
        if (!factoryAddressObject) {
            console.log("Factory address not found for current chain ID");
            return;
        }
        const factoryAddress = factoryAddressObject[chainId].FactoryAddress;
        const contracts = new ethers.Contract(factoryAddress, abiFactory, provider);
        const allPairsLength = await contracts.allPairsLength();

        const allTokenTableListDetails = {};

        for (let i = 0; i < allPairsLength; i++) {
            const pairAddress = await contracts.allPairs(i);
            const pairContract = new ethers.Contract(pairAddress, abiPair, provider);

            const [token0Address, token1Address] = await Promise.all([
                pairContract.token0(),
                pairContract.token1()
            ]);

            const [token0Contract, token1Contract] = await Promise.all([
                new ethers.Contract(token0Address, abiERC20Token, provider),
                new ethers.Contract(token1Address, abiERC20Token, provider)
            ]);

            const [balance0, balance1, token0Name, token1Name, token0Symbol, token1Symbol] = await Promise.all([
                token0Contract.balanceOf(pairAddress),
                token1Contract.balanceOf(pairAddress),
                token0Contract.name(),
                token1Contract.name(),
                token0Contract.symbol(),
                token1Contract.symbol()
            ]);

            const tokenAddresses = [token0Address, token1Address];
            for (let index = 0; index < tokenAddresses.length; index++) {
                const tokenAddress = tokenAddresses[index];
                const existingToken = allTokenTableListDetails[tokenAddress];
                const tokenDecimals = await getTokenDecimals(tokenAddress);
                const FDV = Number((index === 0 ? balance0 : balance1) / Math.pow(10, tokenDecimals));
                const symbol = index === 0 ? token0Symbol : token1Symbol;
                const symbolTopTokenUSDT = symbol + 'USDT';
                const tokenSymbolRateUSD = Number(symbol === 'USDT' ? 1 : await fetchLivePrice(symbolTopTokenUSDT));
                const rateUSD = isNaN(tokenSymbolRateUSD) ? 1 : tokenSymbolRateUSD;
                const tokenDetailPrice = rateUSD;
                const tokenDetailFDV = rateUSD * FDV;
                const transactions = await getAllStoredTransactions();

                const twentyFourHoursAgo = new Date().getTime() - (24 * 60 * 60 * 1000);
                const fortyEightHoursAgo = new Date().getTime() - (48 * 60 * 60 * 1000);
                let tokenOneDayVol = 0;
                let tokenTwoDayVol = 0;

                transactions.forEach(tx => {
                    const txTime = new Date(tx.createdAt).getTime();
                    if (txTime >= twentyFourHoursAgo && tx.tokenAddressA === tokenAddress) {
                        tokenOneDayVol += Number(tx.amountAUSD);
                    }
                    if (txTime >= fortyEightHoursAgo && txTime < twentyFourHoursAgo && tx.tokenAddressA === tokenAddress) {
                        tokenTwoDayVol += Number(tx.amountAUSD);
                    }
                });

                const tokenOneDayVary = tokenTwoDayVol === 0 ? 0 : ((tokenOneDayVol - tokenTwoDayVol) / tokenTwoDayVol) * 100;
                const tokenOneDayChange = tokenOneDayVary === 0 ? '0%' : (tokenOneDayVary > 0 ? `+${tokenOneDayVary}%` : `-${tokenOneDayVary}%`);

                if (existingToken) {
                    existingToken.FDV += tokenDetailFDV;
                    existingToken.Price = tokenDetailPrice;
                    existingToken.TokenOneDayVol = tokenOneDayVol;
                    existingToken.TokenOneDayChange = tokenOneDayChange;
                } else {
                    allTokenTableListDetails[tokenAddress] = {
                        Token: tokenAddress,
                        Name: index === 0 ? token0Name : token1Name,
                        Symbol: symbol,
                        Price: tokenDetailPrice,
                        FDV: tokenDetailFDV,
                        TokenOneDayVol: tokenOneDayVol,
                        TokenOneDayChange: tokenOneDayChange
                    };
                }
            }
        }
        console.log("allTokenTableListDetails", Object.values(allTokenTableListDetails));
        return Object.values(allTokenTableListDetails);
    } catch (error) {
        console.error("Error in chainTokenTableListDetails:", error);
        return null;
    }
};

// export const chainPairTableListDetails = async () => {
//     const provider = getCurrentProvider();
//     const web3 = new Web3(provider);
//     const chainId = await getCurrentChainId();
//     const factoryAddressObject = chainAddresses.find(chain => chain[chainId]);
//     if (!factoryAddressObject) {
//         console.log("Factory address not found for current chain ID");
//         return [];
//     }
//     const factoryAddress = factoryAddressObject[chainId].FactoryAddress;
//     const contracts = new web3.eth.Contract(abiFactory, factoryAddress);
//     const allPairsLength = await contracts.methods.allPairsLength().call();
//     const allpairtablelistdetails = [];
//     await Promise.all(
//         Array.from({ length: Number(allPairsLength) }, async (_, i) => {
//             const pairAddress = await contracts.methods.allPairs(i).call();
//             const pairContract = new web3.eth.Contract(abiPair, pairAddress);
//             const [token0Address, token1Address] = await Promise.all([
//                 pairContract.methods.token0().call(),
//                 pairContract.methods.token1().call(),
//             ]);
//             const [token0Contract, token1Contract] = await Promise.all([
//                 new web3.eth.Contract(abiERC20Token, token0Address),
//                 new web3.eth.Contract(abiERC20Token, token1Address),
//             ]);
//             const [token0Symbol, token1Symbol] = await Promise.all([
//                 token0Contract.methods.symbol().call(),
//                 token1Contract.methods.symbol().call(),
//             ]);
//             const [token0BalanceOf, token1BalanceOf] = await Promise.all([
//                 token0Contract.methods.balanceOf(pairAddress).call(),
//                 token1Contract.methods.balanceOf(pairAddress).call(),
//             ]);
//             const priceOfTokenSymbolA = await fetchLivePrice(`${token0Symbol}USDT`) || 1;
//             const priceOfTokenSymbolB = await fetchLivePrice(`${token1Symbol}USDT`) || 1;
//             const pairTransactions = await getCountOfTransactionsForPair(pairAddress);
//             const pairTVL = (parseFloat(priceOfTokenSymbolA) * (typeof token0BalanceOf === 'string' ? parseFloat(token0BalanceOf.trim()) / 1e18 : parseFloat(token0BalanceOf) / 1e18)) + (parseFloat(priceOfTokenSymbolB) * (typeof token1BalanceOf === 'string' ? parseFloat(token1BalanceOf.trim()) / 1e18 : parseFloat(token1BalanceOf) / 1e18));
//             const pairOneDayVolume = await calculatePairVolume(pairAddress, 1);
//             const pairSevenDayVolume = await calculatePairVolume(pairAddress, 7);
//             const pairDetails = {
//                 pairAddress,
//                 pairSymbol: `${token0Symbol}/${token1Symbol}`,
//                 pairTransactions,
//                 pairTVL,
//                 pairOneDayVolume,
//                 pairSevenDayVolume
//             };
//             allpairtablelistdetails.push(pairDetails);
//             console.log("pairDetails:", pairDetails);
//         })
//     );
//     console.log("allpairtablelistdetails:", allpairtablelistdetails);
//     return allpairtablelistdetails;
// };

export const chainPairTableListDetails = async () => {
    const provider = await getCurrentProvider();
    const chainId = await provider.getNetwork().then(network => network.chainId);
    console.log("chainId12345", chainId);
    const factoryAddressObject = chainAddresses.find(chain => chain[chainId]);

    if (!factoryAddressObject) {
        console.log("Factory address not found for current chain ID");
        return [];
    }

    const factoryAddress = factoryAddressObject[chainId].FactoryAddress;
    const factoryContract = new ethers.Contract(factoryAddress, abiFactory, provider);
    const allPairsLength = await factoryContract.allPairsLength();
    const allpairtablelistdetails = [];
    for (let i = 0; i < Number(allPairsLength); i++) {
        const pairAddress = await factoryContract.allPairs(i);
        const pairContract = new ethers.Contract(pairAddress, abiPair, provider);

        const [token0Address, token1Address] = await Promise.all([
            pairContract.token0(),
            pairContract.token1()
        ]);

        const token0Contract = new ethers.Contract(token0Address, abiERC20Token, provider);
        const token1Contract = new ethers.Contract(token1Address, abiERC20Token, provider);

        const [token0Symbol, token1Symbol] = await Promise.all([
            token0Contract.symbol(),
            token1Contract.symbol()
        ]);

        const [token0BalanceOf, token1BalanceOf] = await Promise.all([
            token0Contract.balanceOf(pairAddress),
            token1Contract.balanceOf(pairAddress)
        ]);

        const priceOfTokenSymbolA = await fetchLivePrice(`${token0Symbol}USDT`) || 1;
        const priceOfTokenSymbolB = await fetchLivePrice(`${token1Symbol}USDT`) || 1;
        const pairTransactions = await getCountOfTransactionsForPair(pairAddress);

        const pairTVL =
            (parseFloat(priceOfTokenSymbolA) * parseFloat(ethers.utils.formatUnits(token0BalanceOf, 18))) +
            (parseFloat(priceOfTokenSymbolB) * parseFloat(ethers.utils.formatUnits(token1BalanceOf, 18)));

        const pairOneDayVolume = await calculatePairVolume(pairAddress, 1);
        const pairSevenDayVolume = await calculatePairVolume(pairAddress, 7);

        const pairDetails = {
            pairAddress,
            pairSymbol: `${token0Symbol}/${token1Symbol}`,
            pairTransactions,
            pairTVL,
            pairOneDayVolume,
            pairSevenDayVolume
        };

        allpairtablelistdetails.push(pairDetails);
        console.log("pairDetails:", pairDetails);
    }

    console.log("allpairtablelistdetails:", [...allpairtablelistdetails]);
    return allpairtablelistdetails;
};

chainPairTableListDetails();

const calculatePairVolume = async (pairAddress, days) => {
    try {
        const allTransactions = await getAllStoredTransactions();
        if (!allTransactions) {
            console.error('Error: Unable to fetch transactions.');
            return null;
        }
        const currentTime = new Date();
        const daysAgo = currentTime.getTime() - (days * 24 * 60 * 60 * 1000);
        const filteredTransactions = allTransactions.filter(transaction => {
            const transactionTime = new Date(transaction.createdAt).getTime();
            return transaction.tokenPairAddress === pairAddress && transactionTime >= daysAgo;
        });
        const totalVolume = filteredTransactions.reduce((acc, transaction) => {
            if (transaction.amountAUSD) {
                return acc + parseFloat(transaction.amountAUSD);
            } else {
                return acc;
            }
        }, 0);
        console.log(`Total ${days}-Day Volume:`, totalVolume);
        return totalVolume;
    } catch (error) {
        console.error(`Error in calculate${days}DayVolume:`, error);
        return null;
    }
};

const getCountOfTransactionsForPair = async (pairAddress) => {
    try {
        const allTransactions = await getAllStoredTransactions();
        const filteredTransactions = allTransactions.filter(transaction => {
            return transaction.tokenPairAddress === pairAddress;
        });
        const transactionCount = filteredTransactions.length;
        console.log(`Total number of transactions for Pair ${pairAddress}:`, transactionCount);
        return transactionCount;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};

//Sayyed Code
// export const getTokenSymbol = async (tokenContractAddress) => {
//     try {
//         let provider = await getCurrentProvider();
//         if (provider) {
//             const web3 = new Web3(provider);
//             const contract = new web3.eth.Contract(abiERC20Token, tokenContractAddress);
//             const tokenSymbol = await contract.methods.symbol().call();
//             console.log(`Token Symbol of address ${tokenContractAddress} is: ${tokenSymbol}`);
//             return tokenSymbol;
//         } else {
//             console.error("MetaMask not installed or accessible");
//         }
//     } catch (error) {
//         console.error(`Error fetching token symbol for address ${tokenContractAddress}:`, error);
//     }
// };
//Shreyash Code
export const getTokenSymbol = async (tokenContractAddress) => {
    try {
        let provider = await getCurrentProvider(); 
        if (provider) {
            const contract = new ethers.Contract(tokenContractAddress, abiERC20Token, provider);
            const tokenSymbol = await contract.symbol(); 
            console.log(`Token Symbol of address ${tokenContractAddress} is: ${tokenSymbol}`);
            return tokenSymbol;
        } else {
            console.error("Ethereum provider not found. Please connect your wallet.");
        }
    } catch (error) {
        console.error(`Error fetching token symbol for address ${tokenContractAddress}:`, error);
    }
};
// export const getTokenName = async (tokenContractAddress) => {
//     try {
//         let provider = await getCurrentProvider();
//         if (provider) {
//             const web3 = new Web3(provider);
//             const contract = new web3.eth.Contract(abiERC20Token, tokenContractAddress);
//             const tokenName = await contract.methods.name().call();
//             console.log(`Token Name of address ${tokenContractAddress} is: ${tokenName}`);
//             return tokenName;
//         } else {
//             console.error("MetaMask not installed or accessible");
//         }
//     } catch (error) {
//         console.error(`Error fetching token name for address ${tokenContractAddress}:`, error);
//     }
// };

export const getTokenName = async (tokenContractAddress) => {
    try {
        const provider = await getCurrentProvider();
        if (provider) {
            const contract = new ethers.Contract(tokenContractAddress, abiERC20Token, provider);
            const tokenName = await contract.name();
            console.log(`Token Name of address ${tokenContractAddress} is: ${tokenName}`);
            return tokenName;
        } else {
            console.error("Provider not available");
        }
    } catch (error) {
        console.error(`Error fetching token name for address ${tokenContractAddress}:`, error);
    }
};

const findTokenInChain = (chainId, tokenContractAddress) => {
    const chain = chainAddresses.find(chain => chain.hasOwnProperty(chainId));
    if (chain) {
        const tokens = chain[chainId]?.tokens || [];
        return tokens.find(token =>
            token?.address?.toLowerCase() === tokenContractAddress?.toLowerCase()
        );
    }
    return null;
};

export const getTokenAddressExists = async (tokenContractAddress) => {
    try {
        let chainId = await getCurrentChainId();
        const token = findTokenInChain(chainId, tokenContractAddress);
        const tokenAddressExists = !!token;
        console.log("getTokenAddressExists", tokenAddressExists)
        return tokenAddressExists;
    } catch (error) {
        console.error("getTokenAddressExistsERROR:", error);
        return false;
    }
};

export const getDetailsOfTokenAddressExists = async (tokenContractAddress) => {
    try {
        if (await getTokenAddressExists(tokenContractAddress)) {
            let chainId = await getCurrentChainId();
            const token = findTokenInChain(chainId, tokenContractAddress);
            if (token) {
                const data = [{
                    ticker: token.ticker,
                    img: token.img,
                    name: token.name,
                    address: token.address
                }];
                console.log("getDetailsOfTokenAddressExist:", data);
                return data;
            } else {
                console.log(`getDetailsOfTokenAddressExist Token ${tokenContractAddress} not found for chain ID ${chainId}`);
                return [];
            }
        } else {
            const symbolTokenContractAddress = await getTokenSymbol(tokenContractAddress);
            console.log("symbolTokenContractAddress:", symbolTokenContractAddress);
            const nameTokenContractAddress = await getTokenName(tokenContractAddress);
            console.log("nameTokenContractAddress:", nameTokenContractAddress);
            const data = [{
                ticker: symbolTokenContractAddress,
                img: "https://cdn.iconscout.com/icon/premium/png-512-thumb/faq-2843266-2363767.png?f=webp&w=512",
                name: nameTokenContractAddress,
                address: tokenContractAddress
            }];
            console.log("getDetailsOfTokenAddressExist:", data);
            return data;
        }
    } catch (error) {
        console.error("getDetailsOfTokenAddressExistsERROR:", error);
        const data = [{
            ticker: null,
            img: null,
            name: null,
            address: null
        }];
        return data;
    }
};

// export const getTokenPairAddress = async (TokenAContractAddress, TokenBContractAddress) => {
//     let provider = await getCurrentProvider();
//     const web3 = new Web3(provider);
//     const currentChainId = await getCurrentChainId();
//     const factoryAddressObject = chainAddresses.find(chain => chain[currentChainId]);
//     let factoryAddress;
//     if (factoryAddressObject) {
//         factoryAddress = factoryAddressObject[currentChainId].FactoryAddress;
//     } else {
//         console.log("Factory address not found for current chain ID");
//     }
//     const contract = new web3.eth.Contract(abiFactory, factoryAddress);
//     console.log(contract);
//     const tokensPairAddress = await contract.methods.getPair(TokenAContractAddress, TokenBContractAddress).call();
//     console.log("tokensPairAddress:", tokensPairAddress);
//     return tokensPairAddress;
// }

export const getTokenPairAddress = async (TokenAContractAddress, TokenBContractAddress) => {
    try {
        const provider = await getCurrentProvider();
        const currentChainId = await getCurrentChainId();
        const factoryAddressObject = chainAddresses.find(chain => chain[currentChainId]);

        if (!factoryAddressObject) {
            console.log("Factory address not found for current chain ID");
            return null;
        }

        const factoryAddress = factoryAddressObject[currentChainId].FactoryAddress;
        const contract = new ethers.Contract(factoryAddress, abiFactory, provider);

        const tokensPairAddress = await contract.getPair(TokenAContractAddress, TokenBContractAddress);
        console.log("tokensPairAddress:", tokensPairAddress);

        return tokensPairAddress;
    } catch (error) {
        console.error(`Error fetching pair address for tokens: ${TokenAContractAddress} and ${TokenBContractAddress}`, error);
        return null;
    }
};


// export const getUserPairBalance = async (TokenAContractAddress, TokenBContractAddress) => {
//     let provider = await getCurrentProvider();
//     if (provider) {
//         const web3 = new Web3(provider);
//         const account = await getCurrentAccount();
//         const pairAddress = TokenAContractAddress && TokenBContractAddress ? await getTokenPairAddress(TokenAContractAddress, TokenBContractAddress) : "0x0000000000000000000000000000000000000000";
//         if (pairAddress == "0x0000000000000000000000000000000000000000") {
//             console.log("Pair address is invalid. Reason: Tokens not found.");
//             return 0;
//         }
//         const contract = new web3.eth.Contract(abiPair, pairAddress);
//         const balance = await contract.methods.balanceOf(account).call();
//         const balanceAsNumber = balance.toString();
//         console.log("getUserPairBalance:", balanceAsNumber);
//         return balanceAsNumber;
//     } else {
//         console.log("MetaMask not installed or accessible");
//     }
// }

export const getUserPairBalance = async (TokenAContractAddress, TokenBContractAddress) => {
    try {
        const provider = await getCurrentProvider();
        const account = await getCurrentAccount();
        const pairAddress = TokenAContractAddress && TokenBContractAddress 
            ? await getTokenPairAddress(TokenAContractAddress, TokenBContractAddress) 
            : ethers.constants.AddressZero;

        if (pairAddress === ethers.constants.AddressZero) {
            console.log("Pair address is invalid. Reason: Tokens not found.");
            return 0;
        }

        const contract = new ethers.Contract(pairAddress, abiPair, provider);
        const balance = await contract.balanceOf(account);
        const balanceAsNumber = ethers.utils.formatUnits(balance, 18); 

        console.log("getUserPairBalance:", balanceAsNumber);
        return balanceAsNumber;
    } catch (error) {
        console.error("Error fetching user pair balance:", error);
        return 0;
    }
};


// export const getPairTotalSupplyAndPoolSharePerc = async (TokenAContractAddress, TokenBContractAddress) => {
//     let provider = await getCurrentProvider();
//     if (provider) {
//         const web3 = new Web3(provider);
//         const pairAddress = TokenAContractAddress && TokenBContractAddress ? await getTokenPairAddress(TokenAContractAddress, TokenBContractAddress) : "0x0000000000000000000000000000000000000000";
//         console.log("getPairTotalSupplyAndPoolShare_PairAddress", pairAddress);
//         if (pairAddress == "0x0000000000000000000000000000000000000000") {
//             console.log("Pair address is invalid. Reason: Tokens not found.");
//             return 0;
//         }
//         const contract = new web3.eth.Contract(abiPair, pairAddress);
//         const balance = await contract.methods.totalSupply().call();
//         console.log("getPairTotalSupplyAndPoolShareBalance", balance);
//         const balanceAsNumber = balance.toString();
//         if (balanceAsNumber == 0) {
//             console.log("getPairTotalSupplyAndPoolShare_insufficientbalance", balanceAsNumber);
//             return [0, 0];
//         }
//         console.log("getPairTotalSupplyAndPoolShareBalanceAsNumber:", balanceAsNumber);
//         let x = await getUserPairBalance(TokenAContractAddress, TokenBContractAddress);
//         let y = balanceAsNumber
//         let percentage = (x / y) * 100;
//         console.log("percentage", percentage);
//         let result = [balanceAsNumber, percentage];
//         console.log("getPairTotalSupplyAndPoolSharePerc", result);
//         return result;
//     } else {
//         console.error("MetaMask not installed or accessible");
//     }
// }
export const getPairTotalSupplyAndPoolSharePerc = async (TokenAContractAddress, TokenBContractAddress) => {
    try {
        const provider = await getCurrentProvider();
        const pairAddress = TokenAContractAddress && TokenBContractAddress 
            ? await getTokenPairAddress(TokenAContractAddress, TokenBContractAddress) 
            : ethers.constants.AddressZero;

        console.log("getPairTotalSupplyAndPoolShare_PairAddress:", pairAddress);

        if (pairAddress === ethers.constants.AddressZero) {
            console.log("Pair address is invalid. Reason: Tokens not found.");
            return [0, 0];
        }

        const contract = new ethers.Contract(pairAddress, abiPair, provider);
        const totalSupply = await contract.totalSupply();
        console.log("getPairTotalSupplyAndPoolShareBalance:", totalSupply.toString());

        const totalSupplyAsNumber = ethers.utils.formatUnits(totalSupply, 18); // Adjust decimal places if needed

        if (totalSupplyAsNumber === '0') {
            console.log("getPairTotalSupplyAndPoolShare_insufficientbalance:", totalSupplyAsNumber);
            return [0, 0];
        }

        console.log("getPairTotalSupplyAndPoolShareBalanceAsNumber:", totalSupplyAsNumber);

        const userBalance = await getUserPairBalance(TokenAContractAddress, TokenBContractAddress);
        const percentage = (userBalance / totalSupplyAsNumber) * 100;

        console.log("percentage:", percentage);
        const result = [totalSupplyAsNumber, percentage];

        console.log("getPairTotalSupplyAndPoolSharePerc:", result);
        return result;
    } catch (error) {
        console.error("Error fetching pair total supply and pool share percentage:", error);
        return [0, 0];
    }
};

// export const getPairAllowanceTokentoRouter = async (TokenAContractAddress, TokenBContractAddress) => {
//     console.log("getPairAllowanceTokentoRouter")
//     try {
//         let provider = await getCurrentProvider();
//         if (provider) {
//             const web3 = new Web3(provider);
//             const account = await getCurrentAccount();
//             const pairAddress = TokenAContractAddress && TokenBContractAddress ? await getTokenPairAddress(TokenAContractAddress, TokenBContractAddress) : "0x0000000000000000000000000000000000000000";
//             if (pairAddress == "0x0000000000000000000000000000000000000000") {
//                 console.log("Pair address is invalid. Reason: Tokens not found.");
//                 return 0;
//             }
//             console.log("pairAddress", pairAddress);
//             const contracts = new web3.eth.Contract(abiPair, pairAddress);
//             console.log("account", account);
//             const currentChainId = await getCurrentChainId();
//             const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
//             let routerAddress;
//             if (routerAddressObject) {
//                 routerAddress = routerAddressObject[currentChainId].RouterAddress;
//             } else {
//                 console.log("Router address not found for current chain ID");
//             }
//             const response = await contracts.methods.allowance(account, routerAddress).call();
//             const allowance = response.toString()
//             console.log(`allowanceToken_${pairAddress}_toRouter_${routerAddress}:`, allowance);
//             return allowance;
//         }
//     } catch (error) {
//         console.error("AllowanceTokentoRouterERROR", error);
//     }
// };

export const getPairAllowanceTokentoRouter = async (TokenAContractAddress, TokenBContractAddress) => {
    console.log("getPairAllowanceTokentoRouter");
    try {
        const provider = await getCurrentProvider();
        const account = await getCurrentAccount();
        const pairAddress = TokenAContractAddress && TokenBContractAddress 
            ? await getTokenPairAddress(TokenAContractAddress, TokenBContractAddress) 
            : ethers.constants.AddressZero;
        if (pairAddress === ethers.constants.AddressZero) {
            console.log("Pair address is invalid. Reason: Tokens not found.");
            return 0;
        }
        console.log("pairAddress:", pairAddress);
        const contract = new ethers.Contract(pairAddress, abiPair, provider);
        console.log("account:", account);
        const currentChainId = await getCurrentChainId();
        const routerAddressObject = chainAddresses.find(chain => chain[currentChainId]);
        let routerAddress;
        if (routerAddressObject) {
            routerAddress = routerAddressObject[currentChainId].RouterAddress;
        } else {
            console.log("Router address not found for current chain ID");
            return 0; // Return early if router address is not found
        }
        const allowance = await contract.allowance(account, routerAddress);
        const allowanceAsNumber = ethers.utils.formatUnits(allowance, 18); // Adjust decimal places if needed

        console.log(`allowanceToken_${pairAddress}_toRouter_${routerAddress}:`, allowanceAsNumber);
        return allowanceAsNumber;
    } catch (error) {
        console.error("AllowanceTokentoRouterERROR:", error);
    }
};

const priceCache = new Map();
const fetchLivePrice = async (symbol) => {
    console.log("fetchLivePricesymbol", symbol);
    console.log("priceCache", priceCache);
    if (priceCache.has(symbol)) {
        console.log("Price retrieved from cache for symbol:", symbol);
        return priceCache.get(symbol);
    }
    try {
        const apiKey = 'apulinHLUjBoxw/pb0PeIg==fsKbdZ0HXEZVzRqM';
        const binanceResponse = await fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${symbol}`);
        let data;
        if (binanceResponse.ok) {
            data = await binanceResponse.json();
        } else {
            console.log('fetchLivePrice_Network_response_was_not_ok');
            const response = await fetch(`https://api.api-ninjas.com/v1/cryptoprice?symbol=${symbol}`, {
                method: 'GET',
                headers: {
                    'X-Api-Key': apiKey,
                    'Content-Type': 'application/json'
                }
            });
            console.log("responsefetchLivePrice", response);
            if (response.status == 200) {
                data = await response.json();
            }
        }
        console.log("LivePricingData:", data);
        console.log("LivePricingSymbol:", data.symbol);
        console.log("LivePricingPrice:", data.price);
        console.log("LivePricingTimestamp:", new Date(data.timestamp * 1000)); // Convert timestamp to Date object
        priceCache.set(symbol, data.price);
        return data.price;
    } catch (error) {
        console.error('FetchLivePricing_ERROR:', error);
        return null;
    }
};

export const getLivePricingValue = async (amountA, symbolA, symbolB) => {
    console.log("trump",amountA)
    let USDT = 'USDT';
    const symbolA_USDT = symbolA + USDT;
    const symbolB_USDT = symbolB + USDT;
    const priceA = symbolA === 'USDT' ? 1 : await fetchLivePrice(symbolA_USDT);
    const priceB = symbolB === 'USDT' ? 1 : await fetchLivePrice(symbolB_USDT);
    if (priceA !== null && priceB !== null) {
        const price = Number(amountA) * (priceA / priceB);
        console.log("getLivePricing_price:", price.toFixed(10).toString());
        return price;
    } else {
        console.error("getLivePricing_ERROR");
        return null;
    }
};

export const getLivePricingInUSDT = async (amount, symbol) => {
    const USDT = 'USDT';
    const symbolA_USDT = `${symbol}${USDT}`;
    const price = symbol === USDT ? 1 : await fetchLivePrice(symbolA_USDT);
    if (price != null) {
        const priceInUSDT = Number(amount) * price;
        console.log("getLivePricingInUSDT_price:", priceInUSDT?.toFixed(10));
        return priceInUSDT?.toFixed(2);
    } else {
        console.error("getLivePricingInUSDT_ERROR");
        return null;
    }
};

export const getTokenLiveInfoDetails = async () => {
    const response = await fetch("https://api.coinranking.com/v2/coins");
    if (response.status !== 200) {
        return null;
    }
    const data = await response.json();
    const resultTokenLiveInfoDetails = data.status === 'success' ? data : null;
    console.log("getResultTokenLiveInfoDetails:", resultTokenLiveInfoDetails);
    return resultTokenLiveInfoDetails;
}





getPairReservedTokenBalance()
getTokenAddressExists("0x0834557b3787A769c7dCda37581241D4C87AfF1c")
getDetailsOfTokenAddressExists("0xb64e470c66372cdf7b97f6713c36091fc1a1d25d");
getPairAllowanceTokentoRouter()
getLivePricingValue("2", "ETH", "USDT");
getCurrentChainIdAllTokens()
getTokenLiveInfoDetails();
// getUserLiquidityDetails("0x08063808B71DC6CB2210B0F595a6833e43642edD", "0x9B1980E236980a8015fa447611243760883a2bBB", "0x9B1980E236980a8015fa447611243760883a2bBB");
// calculateAmountOutMin(1, "0x48Cf9ac75Ca3E466D5298746c21FB49cFB49BB0b", "0x5B4Ac9f02d3d7DfA3EA4C5f3c51D70Cc75D8F5f8", 0.1);
// getAllStoredTransactions()
// getTokenSymbol("0x4686ff5E3DCa3f0eb60C665Cb9317c3bE9FeECa6")  //AVAX -> USDT
// getTokenPairAddress("0x0834557b3787A769c7dCda37581241D4C87AfF1c","0x9B1980E236980a8015fa447611243760883a2bBB")
// getUserPairBalance()
// checkTransfersToDeadAddress()



//Miscellaneous
// export const getTokenContractAddressExists = async (TokenAContractAddress, TokenBContractAddress) => {
//     try {
//         console.log("TokenAContractAddress, TokenBContractAddress", TokenAContractAddress, TokenBContractAddress);
//         let provider = await getCurrentProvider();
//         if (provider) {
//             const web3 = new Web3(provider);
//             const currentChainId = await getCurrentChainId();
//             const factoryAddressObject = chainAddresses.find(chain => chain[currentChainId]);
//             let factoryAddress;
//             if (factoryAddressObject) {
//                 factoryAddress = factoryAddressObject[currentChainId].FactoryAddress;
//             } else {
//                 console.log("Factory address not found for current chain ID");
//             }
//             const contracts = new web3.eth.Contract(abiFactory, factoryAddress);
//             console.log(contracts);
//             const tokensPairAddress = await contracts.methods.getPair(TokenAContractAddress, TokenBContractAddress).call();
//             console.log("tokensPairAddress:", tokensPairAddress);
//             if (tokensPairAddress !== "0x0000000000000000000000000000000000000000") {
//                 const tokenASymbol = await getTokenSymbol(TokenAContractAddress);
//                 const tokenBSymbol = await getTokenSymbol(TokenBContractAddress);
//                 console.log(`tokensSymbol of address ${TokenAContractAddress} is: `, tokenASymbol);
//                 console.log(`tokensSymbol of address ${TokenBContractAddress} is: `, tokenBSymbol);
//                 let tokenSymbols = [tokenASymbol, tokenBSymbol];
//                 console.log("tokenSymbols", tokenSymbols);
//                 return tokenSymbols;
//             } else {
//                 console.log("Pair address is invalid");
//                 return null;
//             }
//         } else {
//             console.error("MetaMask not installed or accessible");
//         }
//     } catch (error) {
//         console.error("Error:", error);
//     }
// };
export const getTokenContractAddressExists = async (TokenAContractAddress, TokenBContractAddress) => {
    try {
        console.log("TokenAContractAddress, TokenBContractAddress", TokenAContractAddress, TokenBContractAddress);
        const provider = await getCurrentProvider();
        if (!provider) {
            console.error("MetaMask not installed or accessible");
            return null; // Return null if provider is not found
        }

        const signer = provider.getSigner(); // Get the signer from the provider
        const currentChainId = await getCurrentChainId();
        const factoryAddressObject = chainAddresses.find(chain => chain[currentChainId]);
        let factoryAddress;

        if (factoryAddressObject) {
            factoryAddress = factoryAddressObject[currentChainId].FactoryAddress;
        } else {
            console.log("Factory address not found for current chain ID");
            return null; // Return null if factory address is not found
        }

        const contract = new ethers.Contract(factoryAddress, abiFactory, signer);
        console.log(contract);
        
        const tokensPairAddress = await contract.getPair(TokenAContractAddress, TokenBContractAddress);
        console.log("tokensPairAddress:", tokensPairAddress);

        if (tokensPairAddress !== "0x0000000000000000000000000000000000000000") {
            const tokenASymbol = await getTokenSymbol(TokenAContractAddress);
            const tokenBSymbol = await getTokenSymbol(TokenBContractAddress);
            console.log(`tokensSymbol of address ${TokenAContractAddress} is: `, tokenASymbol);
            console.log(`tokensSymbol of address ${TokenBContractAddress} is: `, tokenBSymbol);
            let tokenSymbols = [tokenASymbol, tokenBSymbol];
            console.log("tokenSymbols", tokenSymbols);
            return tokenSymbols;
        } else {
            console.log("Pair address is invalid");
            return null;
        }
    } catch (error) {
        console.error("Error:", error);
        return null; // Return null in case of an error
    }
};


// export const checkTransfersToDeadAddress = async () => {
//     try {
//         const evcTokenContractAddress = TRNDContractAddress;
//         const deadAddress = '0x000000000000000000000000000000000000dEaD';
//         const provider = await getCurrentProvider();
//         const web3 = new Web3(provider);
//         let evcTokenContract;
//         try {
//             evcTokenContract = new web3.eth.Contract(abiERC20Token, evcTokenContractAddress);
//         } catch (error) {
//             console.error('EVC token contract not found.');
//             return;
//         }
//         const checkForTransfers = async () => {
//             const latestBlock = await web3.eth.getBlockNumber();
//             alert(latestBlock)
//             const fromBlock = 47572500;
//             const toBlock = latestBlock;
//             const events = await evcTokenContract.getPastEvents('Transfer', {
//                 filter: { to: deadAddress },
//                 fromBlock: fromBlock,
//                 toBlock: toBlock
//             });
//             events.forEach(event => {
//                 console.log(`Alert: Transfer to dead address detected! Transaction Hash: ${event.transactionHash}`);
//             });
//         };
//         setInterval(checkForTransfers, 30000);
//     } catch (error) {
//         console.error('An error occurred:', error);
//     }
// }

export const checkTransfersToDeadAddress = async () => {
    try {
        const evcTokenContractAddress = TRNDContractAddress;
        const deadAddress = '0x000000000000000000000000000000000000dEaD';
        const provider = await getCurrentProvider();
        
        if (!provider) {
            console.error("Provider is not available.");
            return;
        }

        const signer = provider.getSigner();
        const evcTokenContract = new ethers.Contract(evcTokenContractAddress, abiERC20Token, signer);

        const checkForTransfers = async () => {
            try {
                const latestBlock = await provider.getBlockNumber();
                alert(latestBlock);
                const fromBlock = 47572500;
                const toBlock = latestBlock;

                const filter = evcTokenContract.filters.Transfer(null, deadAddress); // Filter for transfers to dead address
                const events = await evcTokenContract.queryFilter(filter, fromBlock, toBlock);

                events.forEach(event => {
                    console.log(`Alert: Transfer to dead address detected! Transaction Hash: ${event.transactionHash}`);
                });
            } catch (error) {
                console.error('Error checking for transfers:', error);
            }
        };

        // Check for transfers every 30 seconds
        setInterval(checkForTransfers, 30000);
    } catch (error) {
        console.error('An error occurred:', error);
    }
};


// export const getIsValidContractAddress = async (contractaddress) => {
//     const contractAddress = contractaddress.toLowerCase();
//     const web3 = new Web3(await getCurrentProvider());
//     if (!isAddress(contractAddress)) {
//         console.error('Invalid Ethereum address');
//     }
//     try {
//         const isValidContractAddress = (await web3.eth.getCode(contractAddress)) !== '0x' ? true : false;
//         console.log("getIsValidContractAddress", isValidContractAddress);
//         return isValidContractAddress;
//     } catch (error) {
//         console.error(`Error fetching code for address ${contractAddress}:`, error);
//     }
// };


export const getIsValidContractAddress = async (contractAddress) => {
    const normalizedAddress = contractAddress.toLowerCase();
    const provider = await getCurrentProvider();
    
    if (!provider) {
        console.error("Provider is not available.");
        return false; 
    }

    const ethersProvider = new ethers.providers.Web3Provider(provider);
    
    if (!ethers.utils.isAddress(normalizedAddress)) {
        console.error('Invalid Ethereum address');
        return false; 
    }
    try {
        const code = await ethersProvider.getCode(normalizedAddress);
        const isValidContractAddress = code !== '0x'; 
        console.log("getIsValidContractAddress", isValidContractAddress);
        return isValidContractAddress;
    } catch (error) {
        console.error(`Error fetching code for address ${normalizedAddress}:`, error);
        return false; 
    }
};





//////////////////////////////////////////////
/*
const commonNetworks = {
    Ethereum: {
        FactoryAddr: "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f",
        RouterAddr: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
        RPC: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        StableAddr: [
            "0xdac17f958d2ee523a2206206994597c13d831ec7",
            "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
            "0x6b175474e89094c44da98b954eedeac495271d0f"
        ]
    },
    BSC: {
        FactoryAddr: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
        RouterAddr: "0x10ED43C718714eb63d5aA57B78B54704E256024E",
        RPC: "https://bsc-dataseed.binance.org/",
        StableAddr: [
            "0x55d398326f99059ff775485246999027b3197955",
            "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
            "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3"
        ]
    },
    Arbitrum: {
        FactoryAddr: "0xc35DADB65012eC5796536bD9864eD8773aBc74C4",
        RouterAddr: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
        RPC: "https://arb1.arbitrum.io/rpc",
        StableAddr: [
            "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
            "0xaf88d065e77c8cc2239327c5edb3a432268e5831",
            "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1"
        ]
    },
    Polygon: {
        FactoryAddr: "0xc35DADB65012eC5796536bD9864eD8773aBc74C4",
        RouterAddr: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
        RPC: "https://polygon-rpc.com/",
        StableAddr: [
            "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
            "0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359",
            "0xdab529f40e671a1d4bf91361c21bf9f0c9712ab7",
            "0x8f3cf7ad23cd3cadbd9735aff958023239c6a063"
        ]
    },
    Avalanche: {
        FactoryAddr: "0x9Ad6C38BE94206cA50bb0d90783181662f0Cfa10",
        RouterAddr: "0x60aE616a2155Ee3d9A68541Ba4544862310933d4",
        RPC: "https://api.avax.network/ext/bc/C/rpc",
        StableAddr: [
            "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
            "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E"
        ]
    },
    Optimism: {
        FactoryAddr: "0x0c3c1c532F1e39EdF36BE9Fe0bE1410313E074Bf",
        RouterAddr: "0x4A7b5Da61326A6379179b40d00F57E5bbDC962c2",
        RPC: "https://mainnet.optimism.io",
        StableAddr: [
            "0x94b008aa00579c1307b0ef2c499ad98a8ce58e58",
            "0x0b2c639c533813f4aa9d7837caf62653d097ff85",
            "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1"
        ]
    },
    Base: {
        FactoryAddr: "0x8909Dc15e40173Ff4699343b6eB8132c65e18eC6",
        RouterAddr: "0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24",
        RPC: "https://bsc-dataseed.binance.org/",
        StableAddr: [
            "0x833589fcd6edb6e08f4c7c32d4f71b54bda02913",
            "0x50c5725949a6f0c72e6c4a641f24049a917db0cb"
        ]
    }
};

const blockchainNetworks = {
    1: commonNetworks.Ethereum,
    11155111: commonNetworks.Ethereum,
    56: commonNetworks.BSC,
    97: commonNetworks.BSC,
    42161: commonNetworks.Arbitrum,
    421614: commonNetworks.Arbitrum,
    137: commonNetworks.Polygon,
    80002: commonNetworks.Polygon,
    43114: commonNetworks.Avalanche,
    43113: commonNetworks.Avalanche,
    10: commonNetworks.Optimism,
    69: commonNetworks.Optimism,
    8453: commonNetworks.Base,
    84532: commonNetworks.Base
};

const getNetworkInfo = async () => {
    try {
        const currentChainId = await getCurrentChainId();
        const networkInfo = blockchainNetworks[currentChainId];
        if (!networkInfo) {
            console.error("Network not found for the current chain ID.");
            return null;
        }
        return networkInfo;
    } catch (error) {
        console.error("An error occurred while fetching network info:", error);
        return null;
    }
};

const getTokenDecimalsAndAmountInWei = async (amount, tokenAddress) => {
    try {
        const { RPC } = await getNetworkInfo();
        const web3 = new Web3(new Web3.providers.HttpProvider(RPC));
        const tokenContract = new web3.eth.Contract(abiERC20Token, tokenAddress);
        const tokenDecimals = await tokenContract.methods.decimals().call();
        const amountInWei = Number(amount) * (10 ** Number(tokenDecimals));
        return amountInWei.toString();
    } catch (error) {
        console.error("An error occurred while fetching token decimals and amount in Wei:", error);
        return null;
    }
};

const getPriceFromRouter = async (amountInWei, path, routerContract) => {
    try {
        const { RPC } = await getNetworkInfo();
        const web3 = new Web3(new Web3.providers.HttpProvider(RPC));
        const amountsOut = await routerContract.methods.getAmountsOut(amountInWei.toString(), path).call();
        const tokenContract = new web3.eth.Contract(abiERC20Token, path[1]);
        const tokenDecimals = await tokenContract.methods.decimals().call();
        const amountInEth = Number(amountsOut[1]) / (10 ** Number(tokenDecimals));
        return amountInEth.toString();
    } catch (error) {
        console.error("An error occurred while fetching price from the router:", error);
        return null;
    }
};

export const getLivePriceFromRouter = async (amount, tokenA, tokenB) => {
    try {
        const networkInfo = await getNetworkInfo();
        if (!networkInfo) {
            console.error("Network information could not be retrieved.");
            return null;
        }
        const { RPC, FactoryAddr, RouterAddr } = networkInfo;
        const web3 = new Web3(new Web3.providers.HttpProvider(RPC));
        const factoryContract = new web3.eth.Contract(abiFactory, FactoryAddr);
        const tokenABPair = await factoryContract.methods.getPair(tokenA, tokenB).call();
        const path = [tokenA, tokenB];
        if (tokenABPair !== "0x0000000000000000000000000000000000000000") {
            const routerContract = new web3.eth.Contract(abiRouter, RouterAddr);
            const amountInWei = await getTokenDecimalsAndAmountInWei(amount, tokenA);
            let livePriceFromRouter = await getPriceFromRouter(amountInWei, path, routerContract);
            console.log("getLivePriceFromRouter", livePriceFromRouter);
            return livePriceFromRouter;
        } else {
            console.error("Token pair does not exist. Attempting to get price using stable coins.");
            let livePriceFromRouter = await getLivePriceFromRouterByStable(amount, tokenA);
            console.log("getLivePriceFromRouter", livePriceFromRouter);
            return livePriceFromRouter;
        }
    } catch (error) {
        console.error("getLivePriceFromRouterERROR:", error);
        return null;
    }
};

export const getLivePriceFromRouterByStable = async (amount, tokenA) => {
    try {
        const networkInfo = await getNetworkInfo();
        if (!networkInfo) {
            console.error("Network information could not be retrieved.");
            return null;
        }
        const { RPC, FactoryAddr, StableAddr, RouterAddr } = networkInfo;
        const web3 = new Web3(new Web3.providers.HttpProvider(RPC));
        const factoryContract = new web3.eth.Contract(abiFactory, FactoryAddr);
        const amountInWei = await getTokenDecimalsAndAmountInWei(amount, tokenA);
        const routerContract = new web3.eth.Contract(abiRouter, RouterAddr);
        let bestResponse = null;
        let bestPrice = 0;
        for (const stableCoin of StableAddr) {
            const path = [tokenA, stableCoin];
            const tokenABPair = await factoryContract.methods.getPair(tokenA, stableCoin).call();
            if (tokenABPair !== "0x0000000000000000000000000000000000000000") {
                const response = await getPriceFromRouter(amountInWei, path, routerContract);
                const price = response[response.length - 1]; // Get the output amount for the last token in the path
                if (price > bestPrice) {
                    bestPrice = price;
                    bestResponse = response;
                }
            } else {
                console.error(`Token pair does not exist for ${tokenA} and ${stableCoin}.`);
            }
        }
        return bestResponse;
    } catch (error) {
        console.error("getLivePriceFromRouterByStableERROR:", error);
        return null;
    }
};

getLivePriceFromRouter(1, "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", "0x76A797A59Ba2C17726896976B7B3747BfD1d220f");

*/