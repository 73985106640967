import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';

const AreaChartGraph = ({ Data, setData }) => {

  const [selection, setSelection] = useState('all');
  const [options, setOptions] = useState({
    colors: ['rgba(11, 134, 104,0.01)'],
    chart: {
      id: "tokenChart",
      background: 'rgba(0, 0, 0, 0)',
      toolbar: {
        show: false
      },
      zoom: {
        autoScaleYaxis: true
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 5
      }
    },
    grid: {
      show: true,
      borderColor: 'rgba(235, 237, 240, 0.03)',
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    xaxis: {
      yAxisIndex: 1,
      type: 'datetime',
      labels: {
        show: true
      },
      axisBorder: {
        show: true,
        color: 'rgba(235, 237, 240, 0.03)'
      },
      tickPlacement: "on"
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return "$" + value.toFixed(0);
        }
      }
    },
    tooltip: {
      show: false
    },
    theme: {
      mode: "dark",
      monochrome: {
        enabled: false,
        color: '#fff',
        shadeTo: 'dark',
        shadeIntensity: 0
      },
    },
    legend: {
      show: false
    },
    stroke: {
      curve: 'smooth',
      width: 1,
      colors: ['#0B8668']
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: 'dark',
        shadeIntensity: 1,
        opacityFrom: 2,
        opacityTo: 3,
        stops: [20, 250]
      }
    }
  });

  const [series, setSeries] = useState([
    {
      name: 'Earnings',
      data: []
    }
  ]);

  useEffect(() => {
    try {
      setSeries([
        {
          name: 'Earnings',
          data: Data
        }
      ]);
    } catch (error) {
      console.error("Error setting series data:", error);
    }
  }, [Data]);

  const updateData = (timeline) => {
    console.log("timeline",timeline);
    const address = localStorage.getItem('connectedAccount');
    setSelection(timeline);
    switch (timeline) {
      case 'one_day':
        fetch(`http://trenddx.io/api/partneractivity/getusergraphtoken/${address}?useraddress=${address}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.status == true) {
              setData(data?.GraphData);
              const dates = data?.GraphData.map((item) => new Date(item[0]).getTime());
              const firstDate = new Date(Math.min(...dates));
              const lastDate = new Date(Math.max(...dates));
              ApexCharts.exec('tokenChart', 'zoomX', firstDate.getTime(), lastDate.getTime());

            }
          })
          .catch((err) => {
            console.log('error', err);
          });
        break;
      case 'one_week':
        fetch(`http://trenddx.io/api/partneractivity/getusergraphtokenweekly/${address}?useraddress=${address}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.status == true) {
              setData(data?.GraphData);
              const dates = data?.GraphData.map((item) => new Date(item[0]).getTime());
              const firstDate = new Date(Math.min(...dates));
              const lastDate = new Date(Math.max(...dates));
              ApexCharts.exec('tokenChart', 'zoomX', firstDate.getTime(), lastDate.getTime());
            }
          })
          .catch((err) => {
            console.log('error', err);
          });
        break;
      case 'one_month':
        fetch(`http://trenddx.io/api/partneractivity/getusergraphtokenmonthly/${address}?useraddress=${address}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.status == true) {
              setData(data?.GraphData);
              const dates = data?.GraphData.map((item) => new Date(item[0]).getTime());
              const firstDate = new Date(Math.min(...dates));
              const lastDate = new Date(Math.max(...dates));
              ApexCharts.exec('tokenChart', 'zoomX', firstDate.getTime(), lastDate.getTime());
            }
          })
          .catch((err) => {
            console.log('error', err);
          });
        break;
      case 'six_month':
        fetch(`http://trenddx.io/api/partneractivity/getusergraphtokensixmonths/${address}?useraddress=${address}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.status == true) {
              setData(data?.GraphData);
              const dates = data?.GraphData.map((item) => new Date(item[0]).getTime());
              const firstDate = new Date(Math.min(...dates));
              const lastDate = new Date(Math.max(...dates));
              ApexCharts.exec('tokenChart', 'zoomX', firstDate.getTime(), lastDate.getTime());
            }
          })
          .catch((err) => {
            console.log('error', err);
          });
        break;
      case 'all':
        fetch(`http://trenddx.io/api/partneractivity/getusergraphtokenoneyear/${address}?useraddress=${address}`)
          .then((response) => response.json())
          .then((data) => {
            if (data.status == true) {
              setData(data?.GraphData);
              const dates = data?.GraphData.map((item) => new Date(item[0]).getTime());
              const firstDate = new Date(Math.min(...dates));
              const lastDate = new Date(Math.max(...dates));
              ApexCharts.exec('tokenChart', 'zoomX', firstDate.getTime(), firstDate.getTime());
            }
          })
          .catch((err) => {
            console.log('error', err);
          });
        break;
      default:
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-6">
          <div className="d-flex align-items-center">
            <div className="chart-btn-group">
              <button onClick={() => updateData('one_day')} className={`chart-btn ${selection === 'one_day' ? 'active' : ''}`}> 24H </button>
              <button onClick={() => updateData('one_week')} className={`chart-btn ${selection === 'one_week' ? 'active' : ''}`}> 7D </button>
              <button onClick={() => updateData('one_month')} className={`chart-btn ${selection === 'one_month' ? 'active' : ''}`}> 1M </button>
              <button onClick={() => updateData('six_month')} className={`chart-btn ${selection === 'six_month' ? 'active' : ''}`}> 6M </button>
              <button onClick={() => updateData('all')} className={`chart-btn ${selection === 'all' ? 'active' : ''}`}> All </button>
            </div>
          </div>
        </div>
      </div>
      <div id="tokenChart">
        <ReactApexChart options={options} series={series} type="area" height={200} />
      </div>
    </>
  );

}

export default AreaChartGraph;