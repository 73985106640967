import React, { useContext, useState, useEffect,useRef  } from "react";
import swapbutton from "../Assets/images2/swapbutton1.png";
import PlusComponent from "./PlusComponent";
import { Modal } from "react-bootstrap";
import SelectToken from "./SelectToken";
import Context from "../Context/Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImportModalContent from "../Dropdown/ImportModalContent";
import cross from "../Assets/images2/radix-icons_cross-2.svg";
import pharrow from "../Assets/images2/ph_arrow-down.png";
import {
  getTokenBalance,
  getPairforTokenATokenB,
  getCurrentChainIdTokenAddress,
  getAllowanceTokentoRouter,
  getValueOutTokenAtoTokenB,
  setSwapTokenAForTokenB,
  getTokenSymbol,
  setTokenApproveRouter,
  getCurrentChainIdNativeTokenAddress,
  getDefaultNativeTokenAddress,
  setSwapExactETHForTokens,
  setSwapExactTokensForETH,
  getPairReservedTokenBalance,
  getLivePricingInUSDT,
} from "../ContractAction/TrendswapAction";
import {
  getCurrentChainId,
  getCurrentTxExplorer,
} from "../ContractAction/ContractDependency";
import { Link } from "react-router-dom";

const SwapComponent = (props) => {
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [selectedTokenImage1, setSelectedTokenImage1] = useState("");
  const [selectedTokenImage2, setSelectedTokenImage2] = useState("");
  const [selectedTokenTicker1, setSelectedTokenTicker1] = useState("");
  const [selectedTokenTicker2, setSelectedTokenTicker2] = useState("");

  const [amountinput, setamountinput] = useState(true);
  const [amountA, setAmountA] = useState("");
  const [amountB, setAmountB] = useState("");
  const [isAmountAEntered, setIsAmountAEntered] = useState(false);
  const [isAmountBEntered, setIsAmountBEntered] = useState(false);
  const [TokenABalance, setTokenABalance] = useState(0);
  const [TokenBBalance, setTokenBBalance] = useState(0);
  const [tokenAAllowance, setTokenAAllowance] = useState(0);
  const [tokenBAllowance, setTokenBAllowance] = useState(0);
  const [pairforTokenATokenB, setPairforTokenATokenB] = useState({});
  const [pairReservedTokenBalanceB, setPairReservedTokenBalanceB] = useState(0);

  const {
    selectedNetwork,
    setSelectedNetwork,
    selectedToken1Address,
    selectedToken2Address,
    setSelectedToken1Address,
    showNestedModal,
    closeNestedModal,
    currentImportedTokenAddress,
    customValue,
  } = useContext(Context);
  const [selectedToken1Addr, setSelectedToken1Addr] = useState("");
  const [selectedToken2Addr, setSelectedToken2Addr] = useState("");
  const [isToken1Above, setIsToken1Above] = useState(false);
  const [isWalletConnected, setIsWalletConnected] = useState(
    localStorage.getItem("connectedAccount") || ""
  );
  const [poolAmountBAvailable, setPoolAmountBAvailable] = useState(0);
  const [tokenALivePricingUSDT, setTokenALivePricingUSDT] = useState(0);
  const [tokenBLivePricingUSDT, setTokenBLivePricingUSDT] = useState(0);
  const [updated,setUpdated] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
      setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
      setIsDialogOpen(false);
  };

  const handleConfirmSwap = () => {
      setIsDialogOpen(true);
      handleSwapTokenAForTokenB();
      notify3(
        selectedTokenTicker1,
        selectedTokenTicker2
      );
      setIsDialogOpen(false);

      console.log("Swap confirmed!");
  };
 

 

  console.log(
    "selectedToken1Address_selectedToken2Address",
    selectedToken1Address,
    selectedToken2Address
  );
  console.log("tokenBLivePricingUSDT_tokenALivePricingUSDT",tokenALivePricingUSDT,tokenBLivePricingUSDT);
  console.log("tokenAAllowance",tokenAAllowance);
  console.log("poolAmountBAvailable",poolAmountBAvailable);
  console.log("selectedToken1Addr_selectedToken2Addr",selectedToken1Addr,selectedToken2Addr);
  console.log("selectedTokenImage1",selectedTokenImage1);
  const {wallet, connecting , connect, disconnect} = useContext(Context);

  console.log(customValue, "customValue"); 
  console.log("amountA_amountB",amountA,amountB);
  console.log("amountB",amountB);
  const notify1 = (e) => toast(`Approve ${e}`);
  const notify2 = (e) => toast(`Approve ${e}`);
  const notify3 = (e, f) => toast(`Swap ${e} with ${f}`);
  
  useEffect(()=>{
    if(!wallet){
      setTokenABalance(0);
      setTokenBBalance(0);
    }
  },[wallet]);

  useEffect(() => {
    const fetchDefaultNativeTokenAddress = async () => {
   
      console.log("fetchDefaultNativeTokenAddress gets call")
      const defaultNativeTokenData = await getDefaultNativeTokenAddress();
      console.log("defaultNativeTokenData",defaultNativeTokenData);
      if (defaultNativeTokenData?.nativeTokenAddress) {
        const { nativeTokenAddress, nativeTokenTicker, nativeTokenImg } =
          defaultNativeTokenData;
        console.log(
          "defaultNativeTokenAddress",
          nativeTokenAddress,
          nativeTokenTicker,
          nativeTokenImg
        );
        setSelectedToken1Addr(nativeTokenAddress);
        setSelectedTokenTicker1(nativeTokenTicker);
        setSelectedToken1Address(nativeTokenAddress);//Newly added as the wallet address was not storing
        setSelectedTokenImage1(nativeTokenImg);
        const balA = Math.max(await getTokenBalance(nativeTokenAddress), 0);
        console.log("SwapComponentBalA", balA);
        setTokenABalance(balA);
        console.log(
          "fetchDefaultNativeTokenAddress",
          selectedToken1Addr,
          selectedTokenTicker1,
          selectedTokenImage1,
          TokenABalance,
          balA
        );
      }
    
    };
    fetchDefaultNativeTokenAddress();
  }, [wallet]);

    useEffect(() => {
      console.log("dhfddsblkdsbfldsbvdbvjdbvj")
        setSelectedToken1Addr(selectedToken1Address);
        setSelectedToken2Addr(selectedToken2Address);
    }, [selectedToken1Address, selectedToken2Address]);

  useEffect(() => {
    const getBalance = async () => {
      console.log("getBalance gets call")
      const chainId = await getCurrentChainId();
      const tokenAContractAddr = await getCurrentChainIdTokenAddress(
        selectedTokenTicker1
      );
      const tokenBContractAddr = await getCurrentChainIdTokenAddress(
        selectedTokenTicker2
      );
      const tokenaContractAddr =
        tokenAContractAddr !== null ? tokenAContractAddr : selectedToken1Addr;
      const tokenbContractAddr =
        tokenBContractAddr !== null ? tokenBContractAddr : selectedToken2Addr;
      console.log("tokenaContractAddr_tokenBContractAddr", tokenaContractAddr, tokenbContractAddr);
      const balanceA = Number(await getTokenBalance(tokenaContractAddr));
      const balanceB = Number(await getTokenBalance(tokenbContractAddr));
      console.log("balanceA_balanceB", balanceA, balanceB);
      const balA = balanceA > 0 ? balanceA.toFixed(2) : 0;
      const balB = balanceB > 0 ? balanceB.toFixed(2) : 0;
      setTokenABalance(balA);
      setTokenBBalance(balB);
      console.log("balanceA", balA, "balanceB", balB);
    };
    const getallowanceTokentoRouter = async () => {
      const chainId = await getCurrentChainId();
      const tokenAContractAddr = await getCurrentChainIdTokenAddress(
        selectedTokenTicker1
      );
      const tokenBContractAddr = await getCurrentChainIdTokenAddress(
        selectedTokenTicker2
      );
      const tokenaContractAddr =
        tokenAContractAddr !== null ? tokenAContractAddr : selectedToken1Addr;
      const tokenbContractAddr =
        tokenBContractAddr !== null ? tokenBContractAddr : selectedToken2Addr;
      const allowanceTokenA = await getAllowanceTokentoRouter(
        tokenaContractAddr
      );
      const allowanceTokenB = await getAllowanceTokentoRouter(
        tokenbContractAddr
      );
      const allowanceA = allowanceTokenA > 0 ? allowanceTokenA : 0;
      const allowanceB = allowanceTokenB > 0 ? allowanceTokenB : 0;
      setTokenAAllowance(allowanceA);
      console.log("allowanceA", allowanceA, amountA, selectedToken1Addr);
      setTokenBAllowance(allowanceB);
    };
    const getpairforTokenATokenB = async () => {
      const tokenAContractAddr = await getCurrentChainIdTokenAddress(
        selectedTokenTicker1
      );
      const tokenBContractAddr = await getCurrentChainIdTokenAddress(
        selectedTokenTicker2
      );
      const tokenaContractAddr =
        tokenAContractAddr !== null ? tokenAContractAddr : selectedToken1Addr;
      const tokenbContractAddr =
        tokenBContractAddr !== null ? tokenBContractAddr : selectedToken2Addr;
      const pairforTokenAandB = await getPairforTokenATokenB(
        tokenaContractAddr,
        tokenbContractAddr
      );
      setPairforTokenATokenB(pairforTokenAandB);
    };
    getBalance();
    getallowanceTokentoRouter();
    getpairforTokenATokenB();
  }, [amountA, selectedTokenTicker1, selectedTokenTicker2,selectedToken1Addr,selectedToken2Addr,updated]);

  useEffect(() => {
    const calculateAmountB = async () => {
      console.log("Calculating for amountB");
      if (amountA && amountA > 0 && isAmountAEntered) {
        try {
          const chainId = await getCurrentChainId();
          const tokenAContractAddr = await getCurrentChainIdTokenAddress(
            selectedTokenTicker1
          );
          const tokenBContractAddr = await getCurrentChainIdTokenAddress(
            selectedTokenTicker2
          );
          const tokenaContractAddr =
            tokenAContractAddr !== null
              ? tokenAContractAddr
              : selectedToken1Addr;
          const tokenbContractAddr =
            tokenBContractAddr !== null
              ? tokenBContractAddr
              : selectedToken2Addr;
          const result = await getValueOutTokenAtoTokenB(
            amountA,
            selectedToken1Addr,
            selectedToken2Addr
          );
          console.log("result111", result);
          const balancePairReservedTokenB = await getPairReservedTokenBalance(
            tokenaContractAddr,
            tokenbContractAddr,
            tokenbContractAddr
          );
          const balPairReservedTokenB =
            balancePairReservedTokenB > 0 ? balancePairReservedTokenB : 0;
          setAmountB(result);
          setIsAmountBEntered(true);
          setPairReservedTokenBalanceB(balPairReservedTokenB);
        } catch (error) {
          console.log("Error calculating amountB:", error);
        }
      }
    };
    calculateAmountB();
  }, [amountA, isAmountAEntered, selectedToken1Addr, selectedToken2Addr, selectedTokenTicker1, selectedTokenTicker2]);

  useEffect(() => {
    const checkWalletConnection = () => {
      const storedAccount = localStorage.getItem("connectedAccount");
      setIsWalletConnected(storedAccount || "");
    };
    const interval = setInterval(checkWalletConnection, 2000);
    checkWalletConnection();
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log(
        "Arrowhandle",
        isToken1Above,
        amountA,
        amountB,
        selectedToken1Addr,
        selectedToken2Addr
      );
    }, 3000);
    return () => clearInterval(interval);
  }, [isToken1Above, amountA, amountB, selectedToken1Addr, selectedToken2Addr]);

  useEffect(() => {
    const poolAmountAvailable = async () => {
      console.log("poolAmountAvailable");
      if (amountA && amountA > 0 && isAmountAEntered) {
        const amountAvailable = await getPairReservedTokenBalance(
          selectedToken1Addr,
          selectedToken2Addr,
          selectedToken2Addr
        );
        console.log("poolAmountAvailable1", amountAvailable);
        setPoolAmountBAvailable(amountAvailable);
      }
    };
    poolAmountAvailable();
  }, [amountA,isAmountAEntered,selectedToken1Addr,selectedToken2Addr]);

  useEffect(() => {
    const getTokenLivePricing = async () => {
      try {
        console.log("getTokenLivePricing")
        const [amountAUSDT, amountBUSDT] = await Promise.all([
          getLivePricingInUSDT(amountA, selectedTokenTicker1),
          getLivePricingInUSDT(amountB, selectedTokenTicker2),
        ]);
        console.log("amountAUSDT_amountBUSDT", amountAUSDT, amountBUSDT);
        setTokenALivePricingUSDT(amountAUSDT ?? 0);
        setTokenBLivePricingUSDT(amountBUSDT ?? 0);
      } catch (error) {
        console.error("Error fetching live pricing:", error);
      }
    };
    getTokenLivePricing();
  }, [amountA, amountB, selectedTokenTicker1, selectedTokenTicker2]);

  const openModal = (buttonId) => {
    if (buttonId === 1) {
      setShowModal1(true);
    } else if (buttonId === 2) {
      setShowModal2(true);
    }
  };
  const closeModal = (buttonId) => {
    if (buttonId === 1) {
      setShowModal1(false);
    } else if (buttonId === 2) {
      setShowModal2(false);
    }
  };

  const handleTokenSelect = (image, ticker, buttonId) => {
    if (buttonId === 1) {
      if (ticker === selectedTokenTicker2) {
        return;
      }
      setSelectedTokenTicker1(ticker);
      // alert(ticker)
      console.log(ticker);
      setSelectedTokenImage1(image);
      closeModal(1);
    } else if (buttonId === 2) {
      if (ticker === selectedTokenTicker1) {
        return;
      }
      setSelectedTokenTicker2(ticker);
      // alert(ticker)
      console.log(ticker);
      setSelectedTokenImage2(image);
      closeModal(2);
    }
  };
  const isTokenSelectedForButton = (buttonId) => {
    if (buttonId === 1) {
      return !!selectedTokenImage1 && !!selectedTokenTicker1;
    } else if (buttonId === 2) {
      return !!selectedTokenImage2 && !!selectedTokenTicker2;
    }
  };

  const handleTokenSelectModal1 = (image, ticker) => {
    if (ticker === selectedTokenTicker2) {
      // alert("Token already selected in Modal 2");
      console.log("Token already selected in Modal 2");
      return;
    }
    setSelectedTokenTicker1(ticker);
    setSelectedTokenImage1(image);
    closeModal(1);
  };
  const handleTokenSelectModal2 = (image, ticker) => {
    if (ticker === selectedTokenTicker1) {
      // alert("Token already selected in Modal 1");
      console.log("Token already selected in Modal 1");
      return;
    }
    setSelectedTokenTicker2(ticker);
    setSelectedTokenImage2(image);
    closeModal(2);
  };

  const handleMaxClick = (token) => {
    if (token === "A") {
      setAmountA(TokenABalance?.toString());
      setIsAmountAEntered(true);
    } else if (token === "B") {
      setAmountB(TokenBBalance?.toString());
      setIsAmountBEntered(true);
    }
  };

  const handelAmountInput = async () => {
        setamountinput(!amountinput)
        console.log(amountinput)
      if (selectedTokenImage1) {
            setIsToken1Above(prevState => !prevState);
            // let tempAmount = amountA;
            // setAmountA(amountB);
            // setAmountB(tempAmount);
            let tempAddr = selectedToken1Addr;
      setSelectedToken1Addr(selectedToken2Addr);
      setSelectedToken2Addr(tempAddr);
    }
  };
    const handleInputAmounts = async (event) => {
    const { id, value } = event.target;
    const decimalRegex = /^\d*\.?\d{0,18}$/; 
  if (!decimalRegex.test(value)) {
    return; 
  }
    const numericValue = value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except decimals
    const integerPartLength = numericValue.split(".")[0].length;

  // If the integer part has more than 80 digits, return early (invalid input)
  if (integerPartLength > 80) {
    return;
  }
    if (id === "amountA") {
      setAmountA(numericValue);
      setIsAmountAEntered(true);
      try {
        const tokenAContractAddr = await getCurrentChainIdTokenAddress(
          selectedTokenTicker1
        );
        const tokenBContractAddr = await getCurrentChainIdTokenAddress(
          selectedTokenTicker2
        );
        const result = await getValueOutTokenAtoTokenB(
          numericValue,
          selectedToken1Addr,
          selectedToken2Addr
        );
        setAmountB(result);
        setIsAmountBEntered(true);
      } catch (error) {
        console.log("Error calculating amountB:", error);
      }
    } else if (id === "amountB") {
      setAmountB(numericValue);
      setIsAmountBEntered(true);
      try {
        const tokenAContractAddr = await getCurrentChainIdTokenAddress(
          selectedTokenTicker1
        );
        const tokenBContractAddr = await getCurrentChainIdTokenAddress(
          selectedTokenTicker2
        );
        const result = await getValueOutTokenAtoTokenB(
          numericValue,
          selectedToken2Addr,
          selectedToken1Addr
        );
        setAmountA(result);
        setIsAmountAEntered(true);
      } catch (error) {
        console.log("Error calculating amountA:", error);
      }
    }
  };

  const handleApproveToken = async (amount, tokenContractAddr) => {
    try {
      const tokenSymbol = await getTokenSymbol(tokenContractAddr);
      if (tokenSymbol) {
        await setTokenApproveRouter(amount, tokenContractAddr);
        setUpdated(!updated);
      } else {
        // alert("No token found");
        console.log("No token found");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleSwapTokenAForTokenB = async () => {
    const chainId = await getCurrentChainId();
    let tokenAContractAddr = await getCurrentChainIdTokenAddress(
      selectedTokenTicker1
    );
    let tokenBContractAddr = await getCurrentChainIdTokenAddress(
      selectedTokenTicker2
    );
    const isTokenANative =
      await getCurrentChainIdNativeTokenAddress(selectedToken1Addr);
      console.log("isTokenANative112",isTokenANative);
    const isTokenBNative =
      await getCurrentChainIdNativeTokenAddress(selectedToken2Addr);
    let txhash;
    if (isTokenANative === true) {
       alert(true)
      console.log("isTokenANative", isTokenANative);
      txhash = await setSwapExactETHForTokens(
        amountA,
        selectedToken1Addr,
        selectedToken2Addr,
        customValue
      );
    } else if (isTokenBNative === true) {
       alert(true)
      console.log("isTokenBNative", isTokenBNative);
      txhash = await setSwapExactTokensForETH(
        amountA,
        selectedToken1Addr,
        selectedToken2Addr,
        customValue
      );
    } else {
       alert(false)
      txhash = await setSwapTokenAForTokenB(
        amountA,
        selectedToken1Addr,
        selectedToken2Addr,
        customValue
      );
    }
    await handleTxhashShow(txhash);
    setAmountA("");
    setAmountB("");
  };

  const handleTxhashShow = async (e) => {
    const txExplorer = await getCurrentTxExplorer();
    toast.success(
      <div>
        Transaction Receipt: <br />
        <a href={`${txExplorer}${e}`} target="_blank" rel="noopener noreferrer">
          View on Block Explorer
        </a>
      </div>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  console.log("amount", amountinput);

  const buttonStyle = {
    backgroundColor: isTokenSelectedForButton(1) ? "rgba(5, 44, 76, 1)" : "",
  };

  const button2Style = {
    backgroundColor: isTokenSelectedForButton(2) ? "rgba(5, 44, 76, 1)" : "",
  };
const handleConnectWallet = async () => {
  try{
    if(!wallet){
      await connect();
    }
  }
  catch(err){
    console.log("error")
  }
}
  return (
    <>
      <ToastContainer style={{ color: "red", top: "100px" }} />
      <div>
        <div id="swap-page" className="mt-3">
          <div className="enpFfZ ">
            {/* TOPSECTION */}
            {console.log(amountA,'amountinputamountinput',amountB)}
            {amountinput ? (
              <div className="highlight iUsjHb mt-3 hhoFBL1 p-2 morphism">
                <div className="dollarValue">You pay</div>
                <div className="d-flex align-item-center justify-content-between">
                  <div style={{ width: "70%" }}>
                    <input
                      type="text"
                      className="currency-input-new"
                      placeholder="0"
                      id="amountA"
                      value={amountA}
                      onChange={handleInputAmounts}
                    />
                  </div>
                  <div>
                    <button
                      className="we"
                      type="button"
                      onClick={() => openModal(1)}
                      style={buttonStyle}
                    >
                      {selectedTokenImage1 ? (
                        <>
                          <img
                            src={selectedTokenImage1}
                            style={{
                              height: "24px",
                              width: "24px",
                              borderRadius: "50%",
                            }}
                            alt=""
                          />
                          <span className="text-light ms-2">
                            {selectedTokenTicker1}
                          </span>
                        </>
                      ) : (
                        <>
                          <img
                            src={selectedTokenImage1}
                            style={{
                              height: "24px",
                              width: "24px",
                              borderRadius: "50%",
                            }}
                            alt=""
                          />
                          <span className="text-light ms-2">
                            {selectedTokenTicker1}
                          </span>
                        </>
                      )}
                      <img
                        src="/images/dropdown.png"
                        height="23px"
                        width="23px"
                        alt=""
                      />
                    </button>
                    {/* <p className="ms-4 mt-2 mb-0 text-light ">balance: {TokenABalance.toFixed(2)}</p> */}
                  </div>
                </div>
                <div className="d-flex mb-2 justify-content-between ">
                  <div className="dollarValue ">${tokenALivePricingUSDT}</div>
                  <div className="me-4 balance ">
                    Balance:{" "}
                    <span id="balanceValue"> {TokenABalance  }</span>
                  </div>

                  <button
                    className="max-parent max-parent-swap my-0"
                    onClick={() => handleMaxClick("A")}
                  >
                    <p className="max my-0 me-2">Max</p>
                  </button>
                </div>
              </div>
            ) : (
              <div
                className=" highlight iUsjHb mt-3 hhoFBL1 p-2 morphism"
                id=""
              >
                <div className="dollarValue">You pay</div>
                <div className="d-flex align-item-center justify-content-between">
                  <div style={{ width: "65%" }}>
                    <input
                      type="text"
                      className="currency-input-new"
                      placeholder="0"
                      id="amountB"
                      value={amountB}
                      onChange={handleInputAmounts}
                    />
                  </div>

                  <div>
                    <button
                      className="ws"
                      type="button"
                      onClick={() => openModal(2)}
                      style={button2Style}
                    >
                      {selectedTokenImage2 ? (
                        <>
                          <img
                            src={selectedTokenImage2}
                            style={{
                              height: "24px",
                              width: "24px",
                              borderRadius: "50%",
                            }}
                            alt=""
                          />
                          <span className="text-light ms-2">
                            {selectedTokenTicker2}
                          </span>
                        </>
                      ) : (
                        <>Select Token</>
                      )}
                      <img
                        src="/images/dropdown.png"
                        height="23px"
                        width="23px"
                        alt=""
                        className="image"
                        id="myImage"
                      />
                    </button>
                  </div>
                </div>
                <div className="d-flex mb-2 justify-content-between ">
                  <div className="dollarValue">${tokenBLivePricingUSDT}</div>
                  <div className="me-4 balance">
                    Balance: <span>{TokenBBalance}</span>{" "}
                  </div>
                  <button
                    className="max me-2"
                    onClick={() => handleMaxClick("A")}
                  >
                    Max
                  </button>
                </div>
              </div>
            )}

            <PlusComponent
              img={swapbutton}
              handelAmountInput={() => handelAmountInput()}
            />

            {/* BOTTOMSECTION */}
            {amountinput ? (
              <div
                className=" hhoFBL1 highlight iUsjHb p-2 morphism"
                id="swapbottom"
              >
                <div className="dollarValue">You receive</div>
                <div className="d-flex align-item-center justify-content-between">
                  <div style={{ width: "65%" }}>
                    <input
                      type="text"
                      className="currency-input-new"
                      placeholder="0"
                      id="amountB"
                      value={amountB}
                      onChange={handleInputAmounts}
                    />
                  </div>
                  <div>
                    <button
                      className="ws"
                      type="button"
                      onClick={() => openModal(2)}
                      style={button2Style}
                    >
                      {selectedTokenImage2 ? (
                        <>
                          <img
                            src={selectedTokenImage2}
                            style={{
                              height: "24px",
                              width: "24px",
                              borderRadius: "50%",
                            }}
                            alt=""
                          />
                          <span className="text-light ms-2">
                            {selectedTokenTicker2}
                          </span>
                        </>
                      ) : (
                        <>Select Token</>
                      )}
                      <img
                        src="/images/dropdown.png"
                        height="23px"
                        width="23px"
                        alt=""
                        className="image"
                        id="myImage"
                      />
                    </button>
                  </div>
                </div>
                <div className="d-flex mb-2 justify-content-between  ">
                  <div className="dollarValue">${tokenBLivePricingUSDT}</div>
                  {selectedTokenImage2 ? (
                    <div className="me-4 balance">
                      Balance: <span>{TokenBBalance}</span>{" "}
                    </div>
                  ) : null}
                  <button
                    className="max px-2 "
                    onClick={() => handleMaxClick("B")}
                  >
                    {" "}
                    Max
                  </button>
                </div>
              </div>
            ) : (
              <div
                className="highlight hhoFBL1 iUsjHb p-2 morphism"
                id="swapbottom"
              >
                <div className="dollarValue">You receive</div>
                <div className="d-flex align-item-center justify-content-between">
                  <div style={{ width: "70%" }}>
                    <input
                      type="text"
                      className="currency-input-new"
                      placeholder="0"
                      id="amountA"
                      value={amountA}
                      onChange={handleInputAmounts}
                    />
                    {console.log(amountA,'amountA')}
                  </div>
                  <div className="mt-2">
                    <button
                      className="we"
                      type="button"
                      onClick={() => openModal(1)}
                      style={button2Style}
                    >
                      {selectedTokenImage1 ? (
                        <>
                          <img
                            src={selectedTokenImage1}
                            style={{
                              height: "24px",
                              width: "24px",
                              borderRadius: "50%",
                            }}
                            alt=""
                          />
                          <span className="text-light ms-2">
                            {selectedTokenTicker1}
                          </span>
                        </>
                      ) : (
                        <>
                          <img
                            src={selectedTokenImage1}
                            style={{
                              height: "24px",
                              width: "24px",
                              borderRadius: "50%",
                            }}
                            alt=""
                          />
                          <span className="text-light ms-2">
                            {selectedTokenTicker1}
                          </span>
                        </>
                      )}
                      <img
                        src="/images/dropdown.png"
                        height="23px"
                        width="23px"
                        alt=""
                      />
                    </button>
                  </div>
                </div>
                <div className="d-flex mb-2 justify-content-between ">
                  <div className="dollarValue">${tokenALivePricingUSDT}</div>
                  {selectedTokenImage1 ? (
                    <div className="me-4 balance">
                      Balance: <span>{TokenABalance  }</span>{" "}
                    </div>
                  ) : null}
                  {/* <div className="me-4 balance">Balance: <span>{TokenABalance.toFixed(2)}</span> </div> */}
                  <button
                    className="max me-2"
                    onClick={() => handleMaxClick("B")}
                  >
                    {" "}
                    Max
                  </button>
                </div>
              </div>
            )}

            {/* SUBMITBUTTON */}
            {/*{/* OLD /}*/}
            {/* {isWalletConnected.length > 0 ? (
                            
                            <div className="enpFfZ">
                                {!isAmountAEntered || !isAmountBEntered ? (
                                    <button className="enterAmountAB mt-2" style={{ width: "100%" }}>
                                        Enter amounts for selected tokens
                                    </button>
                                ) : (
                                    <>
                                        {TokenABalance >= amountA && TokenBBalance >= amountB ? (
                                            <>
                                                {tokenAAllowance < amountA && (
                                                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                        {!isToken1Above ? (
                                                            <button className="connect-button mt-2 w-100" onClick={() => { handleApproveToken(amountA, selectedToken1Addr); notify1(selectedTokenTicker1) }}>
                                                                Approve {selectedTokenTicker1}
                                                            </button>
                                                        ) : (
                                                            <button className="connect-button mt-2 w-100" onClick={() => { handleApproveToken(amountA, selectedToken2Addr); notify2(selectedTokenTicker2) }}>
                                                                Approve {selectedTokenTicker2}
                                                            </button>
                                                        )}
                                                    </div>
                                                )}
                                                {poolAmountBAvailable > amountB ? (
                                                    tokenAAllowance >= amountA && (
                                                        <button
                                                            className="connect-button mt-2"
                                                            style={{ width: '100%' }}
                                                            onClick={() => { handleSwapTokenAForTokenB(); notify3(selectedTokenTicker1, selectedTokenTicker2) }}
                                                        >
                                                            SWAP
                                                        </button>
                                                    )
                                                ) : (
                                                    <button className="enterAmountAB mt-2" style={{ width: '100%' }}>
                                                        Insufficient Pair Amount Available
                                                    </button>
                                                )}
                                            </>
                                        ) : (
                                            <button className="enterAmountAB mt-2" style={{ width: '100%' }}>
                                                Insufficient Balance
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                        ) : (
                            <button className="connect-button mt-2 w-100" >
                                Connect Wallet
                            </button>
                        )} */}

            {isWalletConnected.length > 0 ? (
              !(selectedTokenImage1 && selectedTokenImage2) ? (
                <button
                  className="enterAmountAB mt-2"
                  style={{ width: "100%" }}
                >
                  Select a Token
                </button>
              ) : !isAmountAEntered || !isAmountBEntered ? (
                <button
                  className="enterAmountAB mt-2"
                  style={{ width: "100%" }}
                >
                  Enter amounts for selected tokens
                </button>
              ) : (
                <>
                  {TokenABalance >= amountA && TokenBBalance >= amountB ? (
                    <>
                      {tokenAAllowance < amountA && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          {!isToken1Above ? (
                            <button
                              className="connect-button mt-2 w-100"
                              onClick={() => {
                                handleApproveToken(amountA, selectedToken1Addr);
                                notify1(selectedTokenTicker1);
                              }}
                            >
                              Approve {selectedTokenTicker1}
                            </button>
                          ) : (
                            <button
                              className="connect-button mt-2 w-100"
                              onClick={() => {
                                handleApproveToken(amountA, selectedToken2Addr);
                                notify2(selectedTokenTicker2);
                              }}
                            >
                              Approve {selectedTokenTicker2}
                            </button>
                          )}
                        </div>
                      )}
                      {poolAmountBAvailable > amountB ? (
                        tokenAAllowance >= amountA && (
                          <button
                            className="connect-button mt-2"
                            style={{ width: "100%" }}
                            onClick={handleOpenDialog}
                          >
                            SWAP
                          </button>
                        )
                      ) : (
                        <button
                          className="enterAmountAB mt-2"
                          style={{ width: "100%" }}
                        >
                          Insufficient Pair Amount Available
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      className="enterAmountAB mt-2"
                      style={{ width: "100%" }}
                    >
                      Insufficient Pair Amount Available
                    </button>
                  )}
                </>
              )
            ) : (
              <button className="connect-button mt-2 w-100" onClick={handleConnectWallet}>
                Connect Wallet
              </button>
            )}
          </div>
        </div>

        <Modal
          show={showModal1}
          onHide={() => closeModal(1)}
          style={{ borderRadius: "15px" }}
          dialogClassName="modal-dialog-centered"
        >
          <Modal.Body className="p-0 modal-body">
            <SelectToken
              showModal1={showModal1}
              closeModal={() => closeModal(1)}
              onSelectToken={(image, ticker) =>
                handleTokenSelectModal1(image, ticker, 1)
              }
            />
          </Modal.Body>
        </Modal>
        <Modal
          show={showModal2}
          onHide={() => closeModal(2)}
          style={{ borderRadius: "15px" }}
          dialogClassName="modal-dialog-centered"
        >
          <Modal.Body className="p-0 modal-body">
            <SelectToken
              showModal2={showModal2}
              closeModal={() => closeModal(2)}
              onSelectToken={(image, ticker) =>
                handleTokenSelectModal2(image, ticker, 2)
              }
            />
          </Modal.Body>
        </Modal>

        <Modal show={showNestedModal} onHide={closeNestedModal}>
          <div className="gnwYND bFCsHr">
            <Modal.Header style={{ borderBottom: "1px solid #585858" }}>
              <img
                src={pharrow}
                style={{ height: "20px", width: "20px" }}
                alt=""
              />
              <span className="text-light">Import Tokens</span>
              <img
                src={cross}
                style={{ height: "24px", width: "24px" }}
                onClick={closeNestedModal}
                alt=""
              />
            </Modal.Header>
            <Modal.Body className="p-0 modal-body">
              <ImportModalContent
                selectedToken1Address={selectedToken1Address}
                selectedToken2Address={selectedToken2Address}
                closeNestedModal={closeNestedModal}
                closeModal={closeModal}
              />
            </Modal.Body>
          </div>
        </Modal>
        <ConfirmationDialog 
                open={isDialogOpen} 
                onClose={handleCloseDialog} 
                onConfirm={handleConfirmSwap} 
            />
      </div>
    </>
  );
};

export default SwapComponent;
const ConfirmationDialog = ({ open, onClose, onConfirm }) => {
  if (!open) return null;

  return (
      <div className="dialog-overlay">
          <div className="dialog-box">
              <h3 style={{color: "#fff"}}>Confirm Swap</h3>
              <p style={{color: "#fff"}}>Are you sure you want to proceed with the swap?</p>
              <div className="dialog-actions">
                  <button onClick={onConfirm} className="dialog-confirm">Confirm</button>
                  <button onClick={onClose} className="dialog-cancel">Cancel</button>
              </div>
          </div>
      </div>
  );
};
