import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { debounce } from 'lodash';
import MyNavbar from '../Component/MyNavbar';
import 'react-toastify/dist/ReactToastify.css';
import swapimg from '../Assets/images2/tdesign_swap (1).png';
import cross from '../Assets/images2/radix-icons_cross-2.svg';
import phquestion from "../Assets/images2/ph_question.png";
import pharrow from "../Assets/images2/ph_arrow-down.png";
import loadingimg from '../Assets/images2/Ellipse 7.png'
import Context from '../Context/Context';
import { getUserPairBalance, getPairTotalSupplyAndPoolSharePerc, setPairApproveRouter, removeliquidity, getPairAllowanceTokentoRouter, getTokenSymbol, getCurrentChainIdTokenAddress, getValueOutTokenAtoTokenB, getPairReservedTokenBalance, getUserLiquidityDetails } from '../ContractAction/TrendswapAction';
import { getCurrentChainId, getCurrentTxExplorer } from '../ContractAction/ContractDependency';

const RemoveLiquidty = () => {

    const { selectedNetwork, setSelectedNetwork, removelqSelectedToken1Address, removelqSelectedToken2Address } = useContext(Context);
    const [inputValue, setInputValue] = useState(0);
    const [txhash, setTxhash] = useState(null);
    const [selectedToken1Addr, setSelectedToken1Addr] = useState('');
    const [selectedToken2Addr, setSelectedToken2Addr] = useState('');
    const [symbolSelectedToken1Addr, setSymbolSelectedToken1Addr] = useState('');
    const [symbolSelectedToken2Addr, setSymbolSelectedToken2Addr] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showInnerModal, setShowInnerModal] = useState(false);
    const [isApproved, setIsApproved] = useState(false);
    const [modelstatus, setmodalstatus] = useState(false)
    const [userPairBalance, setUserPairBalance] = useState(0);
    const [userPoolSharePerc, setUserPoolSharePerc] = useState(0);
    const [pairAllowanceRouter, setPairAllowanceRouter] = useState(0);
    const [valueOfInput, setValueOfInput] = useState(0);
    const [valueOutTokenAtoTokenB, setValueOutTokenAtoTokenB] = useState(0);
    const [valueOutTokenBtoTokenA, setValueOutTokenBtoTokenA] = useState(0);
    const [userTokenALiquidityAdded, setUserTokenALiquidityAdded] = useState(0);
    const [userTokenBLiquidityAdded, setUserTokenBLiquidityAdded] = useState(0);
    const [userTokenPercLiquidityRemove, setUserTokenPercLiquidityRemove] = useState(0);
    const [userTokenALiquidityRemove, setUserTokenALiquidityRemove] = useState(0);
    const [userTokenBLiquidityRemove, setUserTokenBLiquidityRemove] = useState(0);
    const [isWalletConnected, setIsWalletConnected] = useState(localStorage.getItem('connectedAccount') || "");
    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => { setShowModal(true); setmodalstatus(true) }
    const handleInnerModalClose = () => setShowInnerModal(false);
    const handleSelectNetwork = (network) => setSelectedNetwork(network);

    console.log("removelqSelectedToken1Address_removelqSelectedToken2Address", removelqSelectedToken1Address, removelqSelectedToken2Address)

    const handleInnerModalShow = () => {
        setShowInnerModal(true);
        toast.success(<div>
            Remove ${userTokenALiquidityRemove} {symbolSelectedToken1Addr} and ${userTokenBLiquidityRemove} {symbolSelectedToken2Addr} <br />
            <Link to="#"> View on Block Explorer</Link>
        </div>, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleInputChange = async (event) => {
        const newValue = parseFloat(event.target.value);
        if (isNaN(newValue) || newValue < 0 || newValue > 100) {
            console.error('Input should be a number between 0 and 100');
            return;
        }
        try {
            const userPairBalance = selectedToken1Addr && selectedToken2Addr ?
                await getUserPairBalance(selectedToken1Addr, selectedToken2Addr) :
                0;
            if (userPairBalance == null) {
                console.error('Failed to fetch user pair balance');
                return;
            }
            const newvaluePerc = Math.round((newValue * userPairBalance) / 100);
            console.log('newvaluePerc', newvaluePerc);
            setInputValue(newValue);
            setValueOfInput(newvaluePerc);
            setUserTokenPercLiquidityRemove(newValue);
        } catch (error) {
            console.error('An error occurred while handling input change:', error);
        }
    };

    const handleSliderChange = async (event) => {
        try {
            console.log("event.target.value", event.target.value)
            const newValue = parseFloat(event.target.value);
            console.log("newValue",newValue);
            if (isNaN(newValue)) {
                console.error('Invalid input: Not a number');
                return;
            }
            setInputValue(newValue);
            let userPairBalance = 0;
            if (selectedToken1Addr && selectedToken2Addr) {
                userPairBalance = await getUserPairBalance(selectedToken1Addr, selectedToken2Addr) || 0;
            }
            const newvaluePerc = Math.round((newValue * userPairBalance) / 100);
            setValueOfInput(newvaluePerc);
            setIsApproved(newvaluePerc <= pairAllowanceRouter);
            setUserTokenPercLiquidityRemove(newValue);
        } catch (error) {
            console.error('An error occurred while handling slider change:', error);
        }
    };
  console.log("inputValue",inputValue)
    const handlePercAsInputAmount = async (e) => {
        try {
            const newValue = parseFloat(e);
            console.log("newValue",newValue)
            setInputValue(newValue);
            if (isNaN(newValue) || newValue < 0 || newValue > 100) {
                console.error('Invalid input: Please enter a percentage between 0 and 100');
                return;
            }
            if (selectedToken1Addr && selectedToken2Addr) {
                const userPairBalance = await getUserPairBalance(selectedToken1Addr, selectedToken2Addr) || 0;
                const newvaluePerc = Math.round((newValue * userPairBalance) / 100);
                setValueOfInput(newvaluePerc);
                setUserTokenPercLiquidityRemove(newValue);
                setIsApproved(newvaluePerc <= pairAllowanceRouter);
            }
        } catch (error) {
            console.error('An error occurred while handling percentage input amount:', error);
        }
    };

    const handleConfirm = async () => {
        if (!selectedToken1Addr || !selectedToken2Addr) {
            console.error('Both token addresses must be selected.');
            return;
        }
        console.log("valueOfInputinhandleConfirm",valueOfInput);
        setShowModal(false);
        setShowInnerModal(true);
        try {
            const txhash = await removeliquidity(valueOfInput, selectedToken1Addr, selectedToken2Addr);
            handleTxhashShow(txhash);
            setTxhash(txhash);
            setInputValue(null);
            setUserTokenALiquidityRemove(0);
            setUserTokenALiquidityRemove(0);
        } catch (error) {
            console.error("Transaction failed:", error);
        } finally {
            setShowInnerModal(false);
        }
    };

    const handleApprove = async () => {
        if (!selectedToken1Addr || !selectedToken2Addr) {
            console.error('Both token addresses must be selected.');
            return;
        }
        try {
            await setPairApproveRouter(valueOfInput, selectedToken1Addr, selectedToken2Addr);
            setIsApproved(true);
        } catch (error) {
            console.error("Approval failed:", error);
        }
    };

    const handleTxhashShow = async (txHash) => {
        try {
            const txExplorer = await getCurrentTxExplorer();
            toast.success(
                <div>Transaction Receipt: <br />
                    <a href={`${txExplorer}${txHash}`} target="_blank" rel="noopener noreferrer">View on Block Explorer</a>
                </div>,
                {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
        } catch (error) {
            console.error("Failed to fetch transaction explorer URL:", error);
        }
    };





    const checkWalletConnection = useCallback(debounce(() => {
        try {
            const storedAccount = localStorage.getItem('connectedAccount');
            setIsWalletConnected(storedAccount || "");
        } catch (error) {
            console.error('Error checking wallet connection:', error);
        }
    }, 300), []);

    const fetchTokenData = useCallback(debounce(async () => {
        if (!removelqSelectedToken1Address || !removelqSelectedToken2Address) {
            console.warn("Invalid token addresses:", removelqSelectedToken1Address, removelqSelectedToken2Address);
            return;
        }
        try {
            setSelectedToken1Addr(removelqSelectedToken1Address);
            setSelectedToken2Addr(removelqSelectedToken2Address);
            console.log("Selected Tokens:", removelqSelectedToken1Address, removelqSelectedToken2Address);
            try {
                const [symbol1, symbol2] = await Promise.all([
                    getTokenSymbol(removelqSelectedToken1Address),
                    getTokenSymbol(removelqSelectedToken2Address)
                ]);
                setSymbolSelectedToken1Addr(symbol1 || '');
                setSymbolSelectedToken2Addr(symbol2 || '');
                console.log("Token Symbols:", symbol1, symbol2);
            } catch (error) {
                console.error("Error fetching token symbols:", error);
                setSymbolSelectedToken1Addr('');
                setSymbolSelectedToken2Addr('');
            }
            try {
                const [valueAB, valueBA] = await Promise.all([
                    getValueOutTokenAtoTokenB(1, removelqSelectedToken1Address, removelqSelectedToken2Address),
                    getValueOutTokenAtoTokenB(1, removelqSelectedToken2Address, removelqSelectedToken1Address)
                ]);
                setValueOutTokenAtoTokenB(valueAB || '');
                setValueOutTokenBtoTokenA(valueBA || '');
                console.log("Value Out Tokens:", valueAB, valueBA);
            } catch (error) {
                console.error("Error fetching value out tokens:", error);
                setValueOutTokenAtoTokenB('');
                setValueOutTokenBtoTokenA('');
            }
            try {
                const [liquidityA, liquidityB] = await Promise.all([
                    getUserLiquidityDetails(removelqSelectedToken1Address, removelqSelectedToken2Address, removelqSelectedToken1Address),
                    getUserLiquidityDetails(removelqSelectedToken1Address, removelqSelectedToken2Address, removelqSelectedToken2Address)
                ]);
                setUserTokenALiquidityAdded(liquidityA || 0);
                setUserTokenBLiquidityAdded(liquidityB || 0);
            } catch (error) {
                console.error("Error fetching user liquidity details:", error);
                setUserTokenALiquidityAdded(0);
                setUserTokenBLiquidityAdded(0);
            }
        } catch (error) {
            console.error("Unexpected error:", error);
        }
    }, 300), [removelqSelectedToken1Address, removelqSelectedToken2Address, txhash]);

    const handleInputValueChange = useCallback(debounce(() => {
        if (inputValue === '') {
            setInputValue(null);
            setIsApproved(false);
        }
    }, 300), [inputValue, txhash]);

    const fetchUserData = useCallback(debounce(async () => {
        if (selectedToken1Addr.length === 0 || selectedToken2Addr.length === 0) {
            console.warn("Invalid token addresses:", selectedToken1Addr, selectedToken2Addr);
            setUserPairBalance(0);
            setUserPoolSharePerc(0);
            setPairAllowanceRouter(0);
            return;
        }
        try {
            const [balance, perc, allowance] = await Promise.all([
                getUserPairBalance(selectedToken1Addr, selectedToken2Addr),
                getPairTotalSupplyAndPoolSharePerc(selectedToken1Addr, selectedToken2Addr),
                getPairAllowanceTokentoRouter(selectedToken1Addr, selectedToken2Addr)
            ]);
            setUserPairBalance(balance / 10 ** 18);
            setUserPoolSharePerc(perc[1] || 0);
            setPairAllowanceRouter(allowance || 0);
        } catch (error) {
            console.error("Error fetching user data:", error);
            setUserPairBalance(0);
            setUserPoolSharePerc(0);
            setPairAllowanceRouter(0);
        }
        try {
            const userTokenALiquidityRemove = (userTokenALiquidityAdded * userTokenPercLiquidityRemove * 0.01) || 0;
            const userTokenBLiquidityRemove = (userTokenBLiquidityAdded * userTokenPercLiquidityRemove * 0.01) || 0;
            setUserTokenALiquidityRemove(userTokenALiquidityRemove.toFixed(6));
            setUserTokenBLiquidityRemove(userTokenBLiquidityRemove.toFixed(6));
        } catch (error) {
            console.error("Error calculating liquidity removal:", error);
            setUserTokenALiquidityRemove(0);
            setUserTokenBLiquidityRemove(0);
        }
    }, 300), [selectedToken1Addr, selectedToken2Addr, inputValue, valueOfInput, isApproved, txhash]);


    useEffect(() => {
        try {
            checkWalletConnection();
            const interval = setInterval(checkWalletConnection, 2000);
            return () => {
                clearInterval(interval);
                checkWalletConnection.cancel();
            };
        } catch (error) {
            console.error('Error in checkWalletConnection useEffect:', error);
        }
    }, [checkWalletConnection]);
    useEffect(() => {
        try {
            fetchTokenData();
            return () => {
                fetchTokenData.cancel();
            };
        } catch (error) {
            console.error('Error in fetchTokenData useEffect:', error);
        }
    }, [fetchTokenData]);
    useEffect(() => {
        try {
            handleInputValueChange();
            return () => {
                handleInputValueChange.cancel();
            };
        } catch (error) {
            console.error('Error in handleInputValueChange useEffect:', error);
        }
    }, [handleInputValueChange]);
    useEffect(() => {
        try {
            fetchUserData();
            return () => {
                fetchUserData.cancel();
            };
        } catch (error) {
            console.error('Error in fetchUserData useEffect:', error);
        }
    }, [fetchUserData]);





    useEffect(() => {
        document.title = 'Trenddx-Remove Liquidity';
        return () => {
            document.title = 'Trenddx';
        };
    }, []);

    return (
        <>
            <MyNavbar selectedNetwork={selectedNetwork} onSelectNetwork={handleSelectNetwork} />
            <ToastContainer />
            <div className="jRlUBU mt-4">
                <div className='khwJOa'>
                    <div className="swapoption d-flex justify-content-between mt-3 align-items-center">
                        <Link to='/manage=Pool'> <img src={pharrow} style={{ height: "20px", width: "20px" }} alt="" /></Link>
                        <button className="swaptext">Remove Liquidity</button>
                        <img src={phquestion} style={{ height: "20px", width: "20px" }} alt="" />
                    </div>
                    <div className=' mt-4  glassmorphism '>
                        <div className='_883856ad '>
                            <div>Amount</div>
                            <div className='add'>Detailed</div>
                        </div>
                        <div>
                        <div style={{ position: 'relative', display: 'inline-flex', alignItems: 'center' }}>
            <input
                type="text"
                className="w-100 _883060pi my-1 px-2"
                inputMode="numeric"
                placeholder="0%"
                value={inputValue !== null ? inputValue : ''}
                onChange={handleInputChange}
                style={{ paddingRight: '20px' }} // Add padding to avoid overlap with %
            />
            <span style={{
                position: 'absolute',
               left:"50px",
                color: '#555',
                color: 'white',
                fontSize:"30px",
            }}>
                %
            </span>
        </div>                            {/* <div id="progressBar" className='mt-4 px-3' style={{
                                position: 'relative',
                                height: '1px',
                                backgroundColor: 'grey'
                            }}>
                                <div style={{
                                    width: `${inputValue}%`,
                                    height: '100%',
                                    backgroundColor: '#40fcfe',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                }} />
                                <div style={{
                                    width: '15px',
                                    height: '15px',
                                    borderRadius: '50%',
                                    backgroundColor: 'white',
                                    position: 'absolute',
                                    top: '-7px',
                                    left: `${inputValue}%`,
                                    transform: 'translateX(-50%)',
                                    zIndex: 2,
                                }} />
                            </div> */}
                            <input
                                type="range"
                                min="0"
                                max="100"
                                style={{cursor: 'pointer'}}
                                value={inputValue}
                                onChange={handleSliderChange}
                                className="w-100 mt-2"
                            />
                        </div>
                        <div className='d-flex mt-4 justify-content-evenly align-items-center'>
                            <div className="_883060pa">
                                <div className="_883060pit" style={{color: inputValue === 25 ? '#40fcfe' : 'white'}} onClick={() => handlePercAsInputAmount(25)}>25%</div>
                            </div>
                            <div className="_883060pa">
                                <div className="_883060pit" style={{color: inputValue === 50 ? '#40fcfe' : 'white'}} onClick={() => handlePercAsInputAmount(50)}>50%</div>
                            </div>
                            <div className="_883060pa">
                                <div className="_883060pit" style={{color: inputValue === 75 ? '#40fcfe' : 'white'}} onClick={() => handlePercAsInputAmount(75)}>75%</div>
                            </div>
                            <div className="_883060pa">
                                <div className="_883060pit" style={{color: inputValue === 100 ? '#40fcfe' : 'white'}} onClick={() => handlePercAsInputAmount(100)}>100%</div>
                            </div>
                        </div>
                    </div>
                    <div className='text-center my-1'>
                        <img src={swapimg} style={{ height: 36, width: 36 }} alt='' />
                    </div>
                    <div className=' glassmorphism p-0'>
                        <div className='_883856ad'>
                            <div>{userTokenALiquidityRemove > 0 ? userTokenALiquidityRemove : 0} (50%)</div>
                            <div className='d-flex align-items-center'>
                                <img src={phquestion} style={{ height: "20px", width: "20px" }} alt='' />
                                <div> {symbolSelectedToken1Addr}</div>
                            </div>
                        </div>
                        <div className='_883856ad'>
                            <div>{userTokenBLiquidityRemove > 0 ? userTokenBLiquidityRemove : 0} (50%)</div>
                            <div className='d-flex align-items-center'>
                                <img src={phquestion} style={{ height: "20px", width: "20px" }} alt='' />
                                <div>{symbolSelectedToken2Addr}</div>
                            </div>
                        </div>
                    </div>
                    <div className='_883856ad'>
                        <div>Rates</div>
                        <div> 1 {symbolSelectedToken1Addr} = {valueOutTokenBtoTokenA} {" "}{symbolSelectedToken2Addr} </div>
                    </div>
                    <div className='_883856ad'>
                        <div></div>
                        <div> 1 {symbolSelectedToken2Addr} = {valueOutTokenAtoTokenB}{" "}{symbolSelectedToken1Addr}</div>
                    </div>
                    {isWalletConnected.length > 0 ? (
                        <div className='_883856ad' style={{ gap: '0px 10px' }}>
                            {inputValue === null ? (
                                <></>
                            ) : (
                                valueOfInput > pairAllowanceRouter ? (
                                    <button className='connect-button w-100' onClick={handleApprove}>Approve</button>
                                ) : (
                                    <button className='connect-button-green w-100'>Approved</button>
                                )
                            )}
                            <div className='w-100'>
                                {inputValue === null ? (
                                    <button className="invalid-pair morphism w-100">Enter an amount</button>
                                ) : (
                                    valueOfInput <= pairAllowanceRouter ? (<div className='w-100' onClick={handleModalShow}>
                                        {modelstatus === true ? (
                                            <button className='connect-button-blue w-100'>Remove</button>
                                        ) : (
                                            <button className='connect-button w-100'>Remove</button>
                                        )}
                                    </div>
                                    ) : (
                                        <button className='invalid-pair morphism w-100'>Remove</button>
                                    )
                                )}
                            </div>
                        </div>
                    ) : (
                        <button className="connect-button mt-2 w-100">
                            Connect Wallet
                        </button>
                    )}
                </div>
                <div className="khwJOa glassmorphism mt-2 mb-2" style={{ border: "none" }}>
                    <div className="px-2">
                        <div className="_883856ad ">Your Position</div>
                        <div className=" _883856ad">
                            <div className="d-flex">
                                {/* <img src={phquestion} style={{ height: "24px", width: "24px" }} alt="" /> */}
                                <img src={phquestion} style={{ height: "24px", width: "24px" }} alt="" />
                                <div>{symbolSelectedToken1Addr}/{symbolSelectedToken2Addr}</div>
                            </div>
                            <div>{userPairBalance.toFixed(2)}</div>
                        </div>
                        <div className=" _883856ad">
                            <div>Your pool share:</div>
                            <div>{userPoolSharePerc.toFixed(2)}%</div>
                        </div>
                        <div className=" _883856ad">
                            <div>{symbolSelectedToken1Addr}:</div>
                            <div>{userTokenALiquidityAdded}</div>
                        </div>
                        <div className=" _883856ad">
                            <div>{symbolSelectedToken2Addr}:</div>
                            <div>{userTokenBLiquidityAdded}</div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Outer modal */}
            <div className="modal" style={{ display: showModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ border: 'transparent' }}>
                        <div className="modal-body p-0">
                            <div className='p-3'>
                                <div className=' _883856ad'>
                                    <div>You will receive</div>
                                    <img src={cross} onClick={handleModalClose} alt='' style={{ cursor: 'pointer' }} />
                                </div>
                                <div className=''>
                                    <div className='_883856ad'>
                                        <div>{userTokenALiquidityRemove}</div>
                                        <div className='d-flex'>
                                            <img src={phquestion} style={{ height: "24px", width: "24px" }} alt="" />
                                            <div>{symbolSelectedToken1Addr}</div>
                                        </div>
                                    </div>
                                    <div className='_883856ad'>
                                        +
                                    </div>
                                    <div className='_883856ad'>
                                        <div>{userTokenBLiquidityRemove}</div>
                                        <div className='d-flex'>
                                            <img src={phquestion} style={{ height: "24px", width: "24px" }} alt="" />
                                            <div>{symbolSelectedToken2Addr}</div>
                                        </div>
                                    </div>
                                </div>
                                <p style={{ fontSize: '9px', margin: 0, padding: 0, color: 'white', color: '#a1a1a1' }}>Output is estimated. If the price changes by more than 34% your transaction will revert.</p>
                            </div>
                            <div className='p-3' style={{ backgroundColor: 'rgba(44, 47, 54, 1)', borderBottomLeftRadius: '16px', borderBottomRightRadius: '16px' }}>
                                <div className=' _883856ad'>
                                    <div>UNI {symbolSelectedToken1Addr}/{symbolSelectedToken2Addr} Burned</div>
                                    <div className='d-flex'>
                                        <img src={phquestion} style={{ height: "24px", width: "24px" }} alt="" />
                                        <img src={phquestion} style={{ height: "24px", width: "24px" }} alt="" />
                                        <div>{symbolSelectedToken1Addr}</div>
                                    </div>
                                </div>
                                <div className='_883856ad'>
                                    <div>Price</div>
                                    <div>1 {symbolSelectedToken1Addr} = {valueOutTokenBtoTokenA} {symbolSelectedToken2Addr}</div>
                                </div>
                                <div className='_883856ad'>
                                    <div></div>
                                    <div>1 {symbolSelectedToken2Addr} = {valueOutTokenAtoTokenB} {symbolSelectedToken1Addr}</div>
                                </div>
                                <button className="connect-button mt-2 w-100" onClick={handleConfirm}>
                                    {`Confirm`}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Inner modal */}
            <div className="modal" style={{ display: showInnerModal ? 'block' : 'none' }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" style={{ border: 'transparent' }}>
                        <div className="modal-body p-0">
                            <div className=" _883856ad">
                                <div></div>
                                <div><img src={cross} onClick={handleInnerModalClose} alt="" style={{ cursor: 'pointer' }} /> </div>
                            </div>
                            <div className="text-center mt-4 mb-4">
                                {/* <img src={loadingimg} style={{ height: '80px', width: '80px', animation: 'rotate 2s linear 3' }} alt="" /> */}
                                <img src={loadingimg} style={{ height: '80px', width: '80px', animation: 'rotate 2s linear infinite' }} alt="" />

                            </div>
                            <div className="waiting-for-confirmation-parent">
                                <b className="waiting-for-confirmation">Waiting For Confirmation</b>
                                <div className="supplying-100000-token1">{`Removing ${userTokenALiquidityRemove} ${symbolSelectedToken1Addr} and ${userTokenBLiquidityRemove} ${symbolSelectedToken2Addr} `}</div>
                                <div className="confirm-this-transaction">Confirm this transaction in your wallet</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

};

export default RemoveLiquidty;